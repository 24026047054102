import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router';

export const selectedTabContext = createContext();

export default function SelectedTabProvider(props) {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location.pathname.split('/')[2] || 'dashboard'
  );
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <selectedTabContext.Provider
      value={{ selectedTab, setSelectedTab, isMenuOpen, setMenuOpen }}
    >
      {props.children}
    </selectedTabContext.Provider>
  );
}
