import React, { useState, useContext, useEffect } from 'react';
import { Grid, Stack, Button } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './styles.module.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ADD_ONS_DETAILS, ADDON_PACKS } from './RegisterConfig';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { OnlinePurchaseContext } from '../../../Context/OnlinePurchaseContext';
import { ThemeContext } from '../../../Context/ThemeContext';
import CustomFormSwitch from '../../utils/CustomComponents/CustomFormSwitch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddonVariantSelectPopup from '../../popups/AddonVariantSelectPopup';
import PurchaseAdditionalDetailsPopup from '../../popups/PurchaseAdditionalDetailsPopup';
import { ROUTES, THEME_SANOFT } from '../../../const';
import Loader from '../../utils/Loading';

function Addon() {
  const history = useHistory();
  const {
    purchaseData,
    setPurchaseData,
    loadCountryCode,
    countryConfig,
    isLoading: isLoadingPurchaseContext,
    loadSubscriptionsAndAddons,
    subscriptionsAndAddons,
  } = useContext(OnlinePurchaseContext);
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const focus = queryParams.get('focus');
  const terminal = queryParams.get('terminal');

  const [isShowAddonPackDetails, setShowAddonPackDetails] = useState(false);
  const [yearly, setYearly] = useState(purchaseData?.yearly ?? true);
  const [bundle, setBundle] = useState('');
  const [addonDetailsFormPopup, setAddonDetailsFormPopup] = useState(null);
  const [addonVariantSelectPopup, setAddonVariantSelectPopup] = useState(null);

  const toggleAddonDetailsHandler = () => {
    setShowAddonPackDetails((prevState) => !prevState);
  };

  const handleChangeValidity = (event) => {
    setYearly(event.target.checked);
  };

  const purchaseAddonHandler = (selectedAddons, pack = false) => {
    setPurchaseData((prevPurchase) => {
      let currentAddons = pack ? [] : [...prevPurchase.add_ons];
      let total = pack ? getSubscriptionAmount() : prevPurchase?.amount;

      selectedAddons.forEach((selectedAddon) => {
        const addonCloud = subscriptionsAndAddons?.add_ons.find((adn) => adn?.key === selectedAddon?.key);
        const existingIndex = currentAddons.findIndex((currentAdon) => {
          if (addonCloud?.config?.variants) {
            return currentAdon.key === selectedAddon.key && currentAdon.count === selectedAddon.count;
          } else {
            return currentAdon.key === selectedAddon.key && currentAdon?.validity === selectedAddon?.validity;
          }
        });

        if (addonCloud) {
          const price = addonCloud?.config?.variants
            ? addonCloud?.config?.variants[selectedAddon?.count]?.price
            : selectedAddon?.validity === 28
            ? addonCloud.monthly_price
            : addonCloud.yearly_price;
          if (existingIndex !== -1) {
            currentAddons.splice(existingIndex, 1);
            total -= price;
          } else {
            if (selectedAddon.key === 'ecs_terminal') {
              currentAddons.push({ ...selectedAddon, terminal: terminal ?? null });
              total += price;
            } else {
              currentAddons.push(selectedAddon);
              total += price;
            }

            if (addonCloud?.config?.fields) {
              setAddonDetailsFormPopup(addonCloud);
            }
          }
        }
      });

      return {
        ...prevPurchase,
        add_ons: currentAddons,
        amount: total,
      };
    });
  };

  const purchaseAddonPackHandler = (addonPackId, addAddons = false) => {
    const addonPack = ADDON_PACKS.find((pack) => pack.id === addonPackId);
    const selectedPackAddons = addonPack.add_ons.map((packAddon) => {
      return { key: packAddon, validity: yearly ? 365 : 28 };
    });
    purchaseAddonHandler(selectedPackAddons, addAddons);
  };

  const getSubscriptionAmount = () => {
    let total = 0;
    if (purchaseData?.subscription) {
      let subscription = (subscriptionsAndAddons?.subscriptions || []).find(
        (sub) => sub?.key === purchaseData?.subscription?.key
      );
      if (subscription) {
        total += yearly ? subscription?.yearly_price : subscription?.monthly_price;
      }
    }
    return total;
  };

  const getAddonPackPrice = (packAddons) => {
    let price = 0;
    packAddons.forEach((packAddon) => {
      const cloudAddon = subscriptionsAndAddons?.add_ons.find((addon) => addon.key === packAddon);
      price += yearly ? cloudAddon?.yearly_price : cloudAddon?.monthly_price;
    });
    return price;
  };

  const handleBuyButton = async () => {
    history.push(ROUTES.PROCESS_PAYMENT);
  };

  const handleFocus = () => {
    const addon = subscriptionsAndAddons.add_ons.find((add_on) => add_on.key === focus);
    if (addon?.confing?.variants) {
      setAddonVariantSelectPopup(addon);
    }
    if (focus) {
      window.location.hash = focus;
    }
  };

  const getAddonContainerSelectStyle = (addon) => {
    const adn = purchaseData.add_ons.find(
      (add_on) =>
        (add_on.key === addon.key && (yearly ? add_on.validity === 365 : add_on.validity === 28)) ||
        (add_on.key === addon.key && addon?.config?.variants)
    );
    return adn ? styles.addonSelectConatiner : '';
  };

  const filteredAddons = Object.values(ADD_ONS_DETAILS).filter((addonDetail) =>
    subscriptionsAndAddons.add_ons.some(
      (addon) => addon.key === addonDetail.key && (!addon.config || !addon.config.variants)
    )
  );

  const sortedPlansAddOns = subscriptionsAndAddons?.add_ons.sort((a, b) => {
    return a?.order - b?.order;
  });

  useEffect(() => {
    if (theme?.name !== THEME_SANOFT) return;
    loadCountryCode();
    //eslint-disable-next-line
  }, [loadCountryCode]);

  useEffect(() => {
    if (theme?.name !== THEME_SANOFT) return;
    if (countryConfig?.code) {
      loadSubscriptionsAndAddons(countryConfig?.code);
    }
    setPurchaseData((prev) => ({ ...prev, amount: getSubscriptionAmount() }));
    if (focus) {
      handleFocus();
    }
    //eslint-disable-next-line
  }, [countryConfig]);

  return (
    <Grid container rowSpacing={1} className={styles.container}>
      <Loader isOpen={isLoadingPurchaseContext} />
      <Grid item xs={12} style={{ padding: 0 }} className={styles.headContainer}>
        <h1 className={styles.pageHeading}>
          The Perfect Balance <br /> of Features and Affordability
        </h1>
      </Grid>
      {theme?.name === THEME_SANOFT && subscriptionsAndAddons && (
        <>
          <Grid item xs={12} className={styles.planContainer}>
            {subscriptionsAndAddons.add_ons && (
              <Grid container rowSpacing={2} columnSpacing={3} className={styles.planWrapper}>
                <Grid item xs={12} justifyContent="space-between">
                  <h2 className={styles.header2}>Add a bundle for greater savings</h2>
                </Grid>
                {ADDON_PACKS.map((pack) => {
                  return (
                    <Grid item xs={12} md={6} key={pack.id}>
                      <Grid container rowGap={2} className={styles.packageContainer}>
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                          <span className={styles.heilightBtn} style={{ backgroundColor: '#C03CE1' }}>
                            {pack.name}
                          </span>
                          <span className={styles.subtext}>Flexibility</span>
                        </Grid>
                        <Grid container item xs={12} className={styles.bundleListConatiner}>
                          <Grid container item xs={10.5} p={1}>
                            {isShowAddonPackDetails ? (
                              filteredAddons.map((filteredAddon) => {
                                const isInclude = pack.add_ons.includes(filteredAddon.key);
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    alignItems="center"
                                    pt={1}
                                    pb={1}
                                    key={filteredAddon.key}
                                    style={{
                                      color: `${!isInclude ? '#979797' : 'inherit'}`,
                                    }}
                                  >
                                    <filteredAddon.icon style={{ paddingRight: 14, fontSize: 20 }} />
                                    <span style={{ fontSize: 13 }}>{filteredAddon.name}</span>
                                  </Grid>
                                );
                              })
                            ) : (
                              <Grid item display="flex">
                                {Object.values(ADD_ONS_DETAILS).map((addonDetail) => {
                                  const isInclude = pack.add_ons.includes(addonDetail.key);
                                  return isInclude && <addonDetail.icon key={addonDetail.id} style={{ paddingRight: 10 }} />;
                                })}
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={1.5} textAlign="center" pt={1} onClick={toggleAddonDetailsHandler}>
                            {isShowAddonPackDetails ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 18,
                              color: '#808080',
                            }}
                          >
                            {countryConfig?.currency || '₹'}
                            {getAddonPackPrice(pack.add_ons)}
                          </span>
                          {bundle === pack.id ? (
                            <CheckCircleIcon
                              className={styles.activeAddon}
                              onClick={() => {
                                purchaseAddonPackHandler(pack.id);
                                setBundle('');
                              }}
                            />
                          ) : (
                            <AddCircleIcon
                              className={styles.addon}
                              onClick={() => {
                                purchaseAddonPackHandler(pack.id, true);
                                setBundle(pack.id);
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                {!purchaseData.hasOwnProperty('yearly') && (
                  <Grid item xs={12} display="flex" justifyContent="space-between">
                    <h2 className={styles.header2}>Add-ons</h2>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <span className={styles.text}>Monthly</span>
                      <CustomFormSwitch checked={yearly} onChange={handleChangeValidity} />
                      <span className={styles.text}>Yearly</span>
                    </Stack>
                  </Grid>
                )}
                {(sortedPlansAddOns || []).map((add_on) => {
                  const addon = ADD_ONS_DETAILS[add_on.key];

                  return (
                    <Grid item id={addon?.key} xs={12}>
                      <Grid
                        className={`${styles.addonConatiner} 
                          ${getAddonContainerSelectStyle(add_on)}
                          ${focus === add_on.key && styles.focusAddonContainer}`}
                      >
                        <img src={addon?.image} alt="" />
                        <Grid className={styles.addonDetails}>
                          <span className={styles.addonHeader}>{addon?.name}</span>
                          <span className={styles.addonNote}>{addon?.note}</span>
                        </Grid>
                        <Grid className={styles.addonAdd}>
                          {add_on?.config?.variants ? (
                            <KeyboardArrowRightIcon
                              className={styles.addon}
                              onClick={() => setAddonVariantSelectPopup(add_on)}
                            />
                          ) : (
                            <>
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: 18,
                                  color: '#808080',
                                }}
                              >
                                {countryConfig?.currency || '₹'} {yearly ? add_on?.yearly_price : add_on.monthly_price}
                              </span>
                              <div
                                onClick={() =>
                                  purchaseAddonHandler([
                                    {
                                      key: add_on.key,
                                      validity: yearly ? 365 : 28,
                                    },
                                  ])
                                }
                              >
                                {yearly ? (
                                  purchaseData.add_ons.find((adn) => adn.key === add_on.key && adn.validity === 365) ? (
                                    <CheckCircleIcon className={styles.activeAddon} />
                                  ) : (
                                    <AddCircleIcon className={styles.addon} />
                                  )
                                ) : purchaseData.add_ons.find(
                                    (adn) => adn.key === add_on.key && adn.validity === 28
                                  ) ? (
                                  <CheckCircleIcon className={styles.activeAddon} />
                                ) : (
                                  <AddCircleIcon className={styles.addon} />
                                )}
                              </div>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={styles.totalContainer}>
            <Grid className={styles.totalWrapper}>
              <span style={{ fontWeight: 500, fontSize: 20 }}>
                {countryConfig?.currency || '₹'} {purchaseData.amount}
              </span>
              <Button className={styles.buyBtn} variant="contained" onClick={handleBuyButton}>
                BUY
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {addonVariantSelectPopup && (
        <AddonVariantSelectPopup
          handleClose={() => {
            setAddonVariantSelectPopup(null);
          }}
          countryConfig={countryConfig}
          addonData={addonVariantSelectPopup}
          purchaseAddonHandler={purchaseAddonHandler}
          purchaseData={purchaseData}
        />
      )}
      {addonDetailsFormPopup && (
        <PurchaseAdditionalDetailsPopup
          handleClose={() => {
            setAddonDetailsFormPopup(null);
          }}
          purchaseData={purchaseData}
          setPurchaseData={setPurchaseData}
          addon={addonDetailsFormPopup}
        />
      )}
    </Grid>
  );
}

export default Addon;
