import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { Button, Grid } from '@material-ui/core';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import { ShopContext } from '../../../Context/ShopContext';
import { useHistory, useParams } from 'react-router-dom';
import ShopSettingsService from '../../../services/ShopSettingsService';
import { AlertMessagesContext } from 'react-alert-messages';
import { camelToTitleCase } from '../../../helpers/StringHelper';
import { ROUTES } from '../../../const';
import { featureIsExpired } from '../../utils/FeatureValidityExpire';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import Info from '../../utils/Alert/Info';
import DataTable from '../../utils/DataTable';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ExternalSyncFailedSaleDialog from '../../popups/ExternalSyncFailedSaleDialog';

export default function QuickbookSettings() {
  const history = useHistory();
  const { subTab } = useParams();
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [isLoading, setLoading] = useState(false);
  const [shopSettings, setShopSettings] = useState(false);
  const [unsyncSales, setUnsyncedSales] = useState([]);
  const [selectedFailedSale, setSelectedFailedSale] = useState(null);

  const isFeatureExpired = useMemo(
    () => featureIsExpired(shop?.quickbook_support_validity),
    [shop?.quickbook_support_validity]
  );

  const redirectUrl = `https://${window.location.host}/console/settings/quickbook/callback`;
  const onClickConnectQuickbook = () => {
    window.location.href =
      'https://appcenter.intuit.com/app/connect/oauth2' +
      '?client_id=ABMlD2y3D04MCyEga3vnXzQoCqafzhSvPj5XlV0mnjrOiC4FMX' +
      '&scope=com.intuit.quickbooks.accounting%20openid%20profile%20email%20phone%20address' +
      `&redirect_uri=${redirectUrl}` +
      '&response_type=code' +
      `&state=${shop.id}`;
  };

  const onClickDisconnectQuickbook = useCallback(async () => {
    setLoading(true);
    try {
      await ShopSettingsService.detachQuickbookAccount();
      setShopSettings(null);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  }, [postAlertMessage]);

  const onErrorLogSelected = (log) => {
    setSelectedFailedSale(log);
  };

  const onCloseSelectedSaleError = () => {
    setSelectedFailedSale(null);
  };

  const getSyncFailedQuickbookSales = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await ShopSettingsService.getExternalSyncFailedSales();
      setUnsyncedSales(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  }, [postAlertMessage]);

  useEffect(() => {
    if (
      isFeatureExpired ||
      shopSettings?.external_sales_sync_platform !== 'Quick Books'
    ) {
      return;
    }
    getSyncFailedQuickbookSales();
  }, [
    postAlertMessage,
    isFeatureExpired,
    shopSettings?.external_sales_sync_platform,
    getSyncFailedQuickbookSales,
  ]);

  useEffect(() => {
    if (shopSettings) {
      return;
    }
    const loadSettings = async () => {
      setLoading(true);
      try {
        const settings = await ShopSettingsService.getShopSettings();
        setShopSettings(settings);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setLoading(false);
    };
    loadSettings();
  }, [postAlertMessage, shopSettings]);

  useEffect(() => {
    if (subTab && subTab === 'callback-local') {
      const url = 'http://localhost:3000/console/settings/quickbook/callback';
      window.location.href = `${url}${window.location.search}`;
      return;
    }

    if (!subTab) {
      return;
    }

    if (subTab === 'disconnect') {
      onClickDisconnectQuickbook();
    }

    if (subTab === 'callback') {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const realmId = urlParams.get('realmId');
      if (!code || !realmId) {
        return;
      }

      const processResponse = async () => {
        setLoading(true);
        try {
          const data = {
            authorization_code: code,
            realm_id: realmId,
            redirect_uri: redirectUrl,
          };
          await ShopSettingsService.attachQuickbookAccount(data);
        } catch (error) {
          postAlertMessage({ text: error.message, type: 'failed' });
        }
        history.push(ROUTES.SETTINGS_TAB.replace(':tab', 'quickbook'));
        setLoading(false);
      };
      processResponse();
    }
  }, [
    subTab,
    postAlertMessage,
    history,
    onClickDisconnectQuickbook,
    redirectUrl,
  ]);

  const quickbookSettings = useMemo(() => {
    if (
      !shopSettings ||
      shopSettings.external_sales_sync_platform !== 'Quick Books' ||
      !shopSettings.external_sales_sync_config
    ) {
      return;
    }

    const {
      refresh_token: refreshToken,
      realm_id: realmId,
      expense_account_id: defaultExpenseAccountId,
      income_account_id: defaultIncomeAccountId,
      asset_account_id: defaultAssetAccountId,
      default_customer_id: defaultCustomerId,
    } = shopSettings.external_sales_sync_config;

    return {
      refreshToken,
      realmId,
      defaultExpenseAccountId,
      defaultIncomeAccountId,
      defaultAssetAccountId,
      defaultCustomerId,
    };
  }, [shopSettings]);

  const onClickAddQuickbookItems = async () => {
    setLoading(true);
    try {
      const resp = await ShopSettingsService.fetchQuickbookProducts();
      postAlertMessage({
        text: resp?.message,
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  };

  const onClickSyncAllFailedSales = async () => {
    setLoading(true);
    try {
      await ShopSettingsService.syncAllExternalSales();
      await getSyncFailedQuickbookSales();
      postAlertMessage({ text: 'Sales synced successfully', type: 'success' });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  };

  const onClickSyncFailedSales = async (saleId) => {
    setLoading(true);
    try {
      await ShopSettingsService.syncExternalSale(saleId);
      await getSyncFailedQuickbookSales();
      postAlertMessage({ text: 'Sales synced successfully', type: 'success' });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  };

  const headerData = [
    {
      label: 'Sale',
      id: 'sale_id',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'date',
      type: 'callback',
      viewRender: (data) => DateTimeHelpers.getDate(data.logs[0]?.created_at),
    },
    {
      label: 'Time',
      id: 'time',
      type: 'callback',
      viewRender: (data) => DateTimeHelpers.getTime(data.logs[0]?.created_at),
    },
    {
      label: 'Attempts',
      id: 'attempts',
      type: 'callback',
      viewRender: (data) => String(data.logs.length),
    },
    {
      label: 'Message',
      id: 'message',
      type: 'callback',
      viewRender: (data) => data.logs[0]?.message ?? '-',
    },
  ];

  return (
    <div className={styles.contentLayout}>
      <Loader isOpen={isLoading} />
      <div className={styles.settingsGrid}>
        <Box container spacing={2}>
          {!shopSettings ? (
            <div>Loading...</div>
          ) : isFeatureExpired ? (
            <RestrictionInfo
              title="Quichbooks support feature not available"
              content={
                'To get quickbooks support options, contact our support team.'
              }
            />
          ) : (
            <>
              <Box className={styles.subHeader}>
                <span>Quickbook Config</span>
              </Box>
              <Box p={2}>
                {quickbookSettings ? (
                  <Box>
                    <Box mb={3}>
                      <Typography color="success" variant="h5">
                        Connected
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {Object.keys(quickbookSettings).map((key) => (
                        <Grid key={key} container item spacing={1}>
                          <Grid item xs={6}>
                            <Typography sx={{ overflowWrap: 'break-word' }}>
                              {camelToTitleCase(key)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{ overflowWrap: 'break-word' }}
                              color="primary"
                            >
                              <strong>{quickbookSettings[key]}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ) : (
                  <Typography color="error" variant="h5">
                    Not connected
                  </Typography>
                )}
              </Box>
              <Box p={2}>
                {quickbookSettings ? (
                  <Box display="flex">
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.button}
                      onClick={onClickDisconnectQuickbook}
                    >
                      Disconnect Quickbook
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!unsyncSales || unsyncSales.length === 0}
                      className={styles.button}
                      onClick={onClickSyncAllFailedSales}
                    >
                      Sync Sales
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.button}
                      onClick={onClickAddQuickbookItems}
                    >
                      Fetch Products
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    onClick={onClickConnectQuickbook}
                  >
                    Connect Quickbook
                  </Button>
                )}
              </Box>
              {quickbookSettings && (
                <>
                  <Box className={styles.subHeader}>
                    <span>Sales Sync Errors</span>
                  </Box>
                  {unsyncSales && unsyncSales.length ? (
                    <DataTable
                      columns={headerData}
                      rows={unsyncSales}
                      rowClickListener={onErrorLogSelected}
                    />
                  ) : (
                    !isLoading && (
                      <Info
                        severity="success"
                        title={'All your sales are synced to Quickbook'}
                        content={
                          'You have no failed sale to sync to quickbook.'
                        }
                      />
                    )
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </div>

      <ExternalSyncFailedSaleDialog
        open={Boolean(selectedFailedSale)}
        log={selectedFailedSale}
        onClose={onCloseSelectedSaleError}
        onRetry={onClickSyncFailedSales}
      />
    </div>
  );
}
