import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import { Grid, Button } from '@mui/material';
import logo from '../../../images/Logo.png';
import Loader from '../../utils/Loading';
import OnboardingConfig from './OnboardingConfig';
import { ROUTES, REGISTER_STEPS, SHOP_SETTINGS } from '../../../const';
import CustomSteppers from '../../utils/CustomSteppers/CustomSteppers';
import ShopSettingsService from '../../../services/ShopSettingsService';
import { AlertMessagesContext } from 'react-alert-messages';

function Onboarding() {
  const history = useHistory();
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [isLoading, setIsLoading] = useState(false);
  const [shopType, setShopType] = useState('');
  const [selectedShopType, setSelectedShopType] = useState(
    OnboardingConfig[0].type
  );
  const [selectedOrderType, setSelectedOrderType] = useState('');

  const getInitialOrderType = useCallback(
    () =>
      OnboardingConfig.find((type) => type.type === shopType)?.order_type[0]
        .type,
    [shopType]
  );

  const setOnboardingHandler = async () => {
    setShopType(selectedShopType);

    if (!selectedShopType || !selectedOrderType) return;

    setIsLoading(true);
    try {
      await ShopSettingsService.updateShopSettings(
        SHOP_SETTINGS[selectedOrderType]
      );
      history.push(ROUTES.APP_SETUP);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const orderType = getInitialOrderType();
    setSelectedOrderType(orderType);
  }, [getInitialOrderType]);

  return (
    <Grid className={styles.onboardingWrapper}>
      <Loader isOpen={isLoading} />
      <Grid>
        <img src={logo} alt="logo" style={{ height: 40 }} />
      </Grid>

      <Grid maxWidth={500} width={450} mt={5}>
        {shopType ? (
          <CustomSteppers steps={REGISTER_STEPS} activeStep={2} />
        ) : (
          <CustomSteppers steps={REGISTER_STEPS} activeStep={1} />
        )}
      </Grid>

      <Grid className={styles.onboardingContainer} mt={4}>
        <h1>Welcome! First things first...</h1>
      </Grid>
      <Grid className={styles.onboardingContainer} style={{ color: '#8b8b8b' }}>
        <h3>You can always change them later.</h3>
      </Grid>

      {!shopType && !shopType.length && (
        <Grid container className={styles.onboardingContainer} gap={2} mt={4}>
          {OnboardingConfig.map((shopType) => (
            <Grid
              item
              key={shopType.type}
              className={`${styles.item} ${
                selectedShopType === shopType.type && styles.selectedItem
              }`}
              onClick={() => setSelectedShopType(shopType.type)}
            >
              <Grid container rowSpacing={1} flexDirection="column">
                <Grid item>
                  <shopType.icon
                    className={`${styles.onboardingIcon} ${
                      selectedShopType === shopType.type &&
                      styles.selectedItemIcon
                    }`}
                  />
                </Grid>
                <Grid item mt={2}>
                  <h3>{shopType.title}</h3>
                </Grid>
                <Grid item>
                  <span className={styles.text}>{shopType.description}</span>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {shopType && (
        <Grid container className={styles.onboardingContainer} gap={2} mt={4}>
          {OnboardingConfig.find(
            (type) => type.type === shopType
          )?.order_type.map((type) => (
            <Grid
              item
              key={type.type}
              className={`${styles.item} ${
                selectedOrderType === type.type && styles.selectedItem
              }`}
              onClick={() => setSelectedOrderType(type.type)}
            >
              <Grid container rowSpacing={1} flexDirection="column">
                <Grid item>
                  <type.icon
                    className={`${styles.onboardingIcon} ${
                      selectedOrderType === type.type && styles.selectedItemIcon
                    }`}
                  />
                </Grid>
                <Grid item mt={2}>
                  <h3>{type.title}</h3>
                </Grid>
                <Grid item>
                  <span className={styles.text}>{type.description}</span>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      <Grid mt={6}>
        <Button
          variant="contained"
          className={styles.btn}
          onClick={setOnboardingHandler}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
}

export default Onboarding;
