import React, { createContext, useState, useEffect } from 'react';
import UserService from '../services/UserService';
import { USER_PERMISSIONS } from '../const'; 

export const UserContext = createContext();

export default function UserProvider(props) {
  const [user, setUser] = useState();

  const loadUserProfile = async () => {
    const resUser = await UserService.getUserProfile();
    // Temporarily mocking salesperson permission to write for all
    if(!resUser) return ;
    
    resUser.shop_permissions = resUser?.shop_permissions || {};
    resUser.shop_permissions.sales_persons_permission = USER_PERMISSIONS.WRITE;

    setUser(resUser);
  };
  useEffect(() => {
    loadUserProfile();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loadUserProfile}}>
      {props.children}
    </UserContext.Provider>
  );
}
