import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeContext } from '../../../Context/ThemeContext';

function DynamicHeader() {
  const { theme } = useContext(ThemeContext);
  return (
    <Helmet>
      <title>{theme.variable.title}</title>
      <link rel="apple-touch-icon" href={theme.variable.logo512} />
      <link rel="icon" href={theme.variable.logo192} />
    </Helmet>
  );
}

export default DynamicHeader;
