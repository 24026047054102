import React from 'react';
import styles from './styles.module.css';
import { Grid, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import logo from '../../../images/Logo.png';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { ROUTES, REGISTER_STEPS } from '../../../const';
import CustomSteppers from '../../utils/CustomSteppers/CustomSteppers';

function RegisterSuccess() {
  const history = useHistory();
  const handleShopCreated = () => {
    history.push(ROUTES.DASHBOARD);
  };

  return (
    <Grid className={styles.onboardingWrapper}>
      <Grid>
        <img src={logo} alt="logo" style={{ height: 40 }} />
      </Grid>
      <Grid maxWidth={500} width={450} mt={5}>
        <CustomSteppers steps={REGISTER_STEPS} activeStep={4} />
      </Grid>
      <CheckCircleRoundedIcon className={styles.successIcon} />
      <Grid className={styles.onboardingContainer} mt={4}>
        <h2>Congratulations!</h2>
      </Grid>
      <Grid
        className={styles.onboardingContainer}
        style={{ color: '#8b8b8b' }}
        mt={1}
      >
        <h3>Shop created successfully.</h3>
      </Grid>
      <Grid mt={1}>
        <Button
          variant="contained"
          onClick={handleShopCreated}
          sx={{ mt: 4, mr: 1, padding: '7px 100px !important' }}
          className={styles.btn}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
}

export default RegisterSuccess;
