import React from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useContext } from 'react';
import { ThemeContext } from '../../Context/ThemeContext'

function FeatureNotAvailableDialog({ closeHandler, feature }) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Feature not available</h2>
          <CloseIcon
            onClick={() => {
              closeHandler(null);
            }}
          />
        </div>
        <div className={styles.changeable} style={{ margin: '10px' }}>
          <Alert severity="warning" style={{ margin: '10px 0' }}>
            <AlertTitle>
              <strong>{`${feature} support feature not available`}</strong>
            </AlertTitle>
            {` To get ${feature} support options, contact support team..`} -{' '}
            <strong>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${theme.variable.phone}&text=Hey%20${theme.variable.name},%20Upgrade%20Subscription`}
              >
                Contact Us
              </a>
            </strong>
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default FeatureNotAvailableDialog;
