export const getFilteredCSVColumns = (
  csvColumns,
  optionalColumns,
  filterId
) => {
  if (!filterId) return csvColumns;

  const columnsArray = Object.entries(csvColumns).map(([key, value]) => ({
    id: key,
    ...(typeof value === 'object' && value !== null ? value : { title: value }),
  }));
  const filteredColumns = getFilteredColumns(
    columnsArray,
    optionalColumns,
    filterId
  );

  const filteredCSV_COLUMNS = Object.fromEntries(
    filteredColumns.map(({ id, ...rest }) => [id, rest])
  );
  return filteredCSV_COLUMNS;
};

export const getFilteredColumns = (columns, optionalColumns, filterId) => {
  if (!filterId) return;
  let filteredColumns;
  const filterDataStr = localStorage.getItem(filterId);
  if (filterDataStr) {
    const filterData = JSON.parse(filterDataStr);
    filteredColumns = columns.filter(
      (col) =>
        !optionalColumns.hasOwnProperty(col.id) ||
        filterData?.[col?.id] === true ||
        (!filterData.hasOwnProperty(col.id) && optionalColumns?.[col?.id])
    );
  } else {
    filteredColumns = columns.filter(
      (col) =>
        !optionalColumns.hasOwnProperty(col.id) || optionalColumns?.[col.id]
    );
  }
  return filteredColumns;
};
