import React, { useContext, useState } from 'react';
import { Button, DialogActions, Grid, TextField } from '@mui/material';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ExpenseService from '../../services/ExpenseService';
import { AlertMessagesContext } from 'react-alert-messages';
import DateTimeHelpers from '../../helpers/DateTimeHelpers';

export default function ExpenseFormDialog({
  handleClose,
  handleSuccess,
  expense,
}) {
  const getDataToPopulate = () => {
    const data = expense || {};
    if (!data.date) {
      data.date = DateTimeHelpers.getTodayStrInYMD();
    }
    return data;
  };

  const { postAlertMessage } = useContext(AlertMessagesContext);
  const [data, setData] = useState(getDataToPopulate());

  const handleActionButton = async () => {
    try {
      data.date = `${data.date}T00:00:00.000Z`;
      if (data.id) {
        await ExpenseService.updateExpense(data.id, data);
        postAlertMessage({
          text: 'Expense updated successfully',
          type: 'success',
        });
      } else {
        await ExpenseService.createExpense(data);
        postAlertMessage({
          text: 'Expense created successfully',
          type: 'success',
        });
      }
      handleSuccess();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    handleClose();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Add a new expense</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Date</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Date"
                variant="outlined"
                size="small"
                fullWidth
                type="date"
                value={data.date}
                onChange={(e) => {
                  setData({ ...data, date: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Amount</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Amount"
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={data.amount}
                onChange={(e) => {
                  setData({ ...data, amount: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Description</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Description"
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                value={data.description}
                onChange={(e) => {
                  setData({ ...data, description: e.target.value });
                }}
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              className={styles.closeBtn}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={
                !data.date ||
                data.date === '' ||
                !data.amount ||
                data.amount === '' ||
                !data.description ||
                data.description === ''
              }
              onClick={handleActionButton}
            >
              {data.id ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
