import React, { useState } from 'react';
import { Button, DialogActions, Grid, TextField } from '@mui/material';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';

export default function AddEcsTerminalMacIdPopup({ handleClose, purchaseData, handleSetMacId }) {
  const [macId, setMacId] = useState(purchaseData?.ecs_terminal?.mac_id || null);

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Set MAC ID for terminal</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid xs={12}>
              <p className={styles.subText}>
                Set the MAC ID for your ECS device to connect to our server. To configure later, close this dialog.
              </p>
            </Grid>
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>MAC ID</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                type="text"
                value={macId}
                onChange={(e) => {
                  setMacId(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="contained" color="primary" size="small" onClick={() => handleSetMacId(macId)}>
              Submit
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
