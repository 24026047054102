import DiningRoundedIcon from '@mui/icons-material/DiningRounded';
import RestaurantMenuRoundedIcon from '@mui/icons-material/RestaurantMenuRounded';
import PaymentsIcon from '@mui/icons-material/Payments';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import StoreIcon from '@mui/icons-material/Store';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import InventoryIcon from '@mui/icons-material/Inventory';

const OnboardingConfig = [
  {
    type: 'restaurent',
    title: 'Restaurent',
    description: 'Lorem ipsum is simply dummy text',
    icon: DiningRoundedIcon,
    order_type: [
      {
        type: 'fine_dining',
        title: 'Fine Dining',
        description: 'Lorem ipsum is simply dummy text',
        icon: RestaurantMenuRoundedIcon,
      },
      {
        type: 'pre_paid',
        title: 'Pre-paid',
        description: 'Lorem ipsum is simply dummy text',
        icon: PaymentsIcon,
      },
      {
        type: 'other',
        title: 'Other',
        description: 'Lorem ipsum is simply dummy text',
        icon: DashboardRoundedIcon,
      },
    ],
  },
  {
    type: 'supermarket',
    title: 'Supermarket',
    description: 'Lorem ipsum is simply dummy text',
    icon: ShoppingBasketIcon,
    order_type: [
      {
        type: 'mini_mart',
        title: 'Mini mart',
        description: 'Lorem ipsum is simply dummy text',
        icon: StoreIcon,
      },
      {
        type: 'delivery',
        title: 'Delivery',
        description: 'Lorem ipsum is simply dummy text',
        icon: DeliveryDiningIcon,
      },
      {
        type: 'other',
        title: 'Other',
        description: 'Lorem ipsum is simply dummy text',
        icon: DashboardRoundedIcon,
      },
    ],
  },
  {
    type: 'other',
    title: 'Other',
    description: 'Lorem ipsum is simply dummy text',
    icon: DashboardRoundedIcon,
    order_type: [
      {
        type: 'take_away',
        title: 'Take away',
        description: 'Lorem ipsum is simply dummy text',
        icon: InventoryIcon,
      },
      {
        type: 'delivery',
        title: 'Delivery',
        description: 'Lorem ipsum is simply dummy text',
        icon: DeliveryDiningIcon,
      },
      {
        type: 'other',
        title: 'Other',
        description: 'Lorem ipsum is simply dummy text',
        icon: DashboardRoundedIcon,
      },
    ],
  },
];

export default OnboardingConfig;
