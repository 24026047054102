import React, { useState } from 'react';
import { Button, DialogActions, Grid, TextField } from '@mui/material';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import AlertHelper from '../../helpers/AlertHelper';
import TableService from '../../services/TableService';

export default function TableFormDialog({
  handleClose,
  handleSuccess,
  setErrorMsg,
  setSuccessMsg,
  selectedTable,
}) {
  const [data, setData] = useState(selectedTable || {});

  const handleActionButton = async () => {
    try {
      const newTable = await TableService.createTables(data);
      AlertHelper.setShortMessage(setSuccessMsg, 'Table created successfully');
      handleSuccess(newTable);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    handleClose();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Add Table</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Table Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Table Name"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={data.name || ''}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Grid>
          </Grid>{' '}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ backgroundColor: '#00a65a' }}
              disabled={!data.name || data.name === ''}
              onClick={handleActionButton}
            >
              Create
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
