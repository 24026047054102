import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import CustomerService from '../../services/CustomerService';
import { DialogActions } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';
import { ThemeContext } from '../../Context/ThemeContext';

export default function NewCustomerDialog(props) {
  const { toggleAddTab } = props;
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { theme } = useContext(ThemeContext);

  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [location, setLocation] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [creditLimit, setCreditLimit] = useState(-500);
  const [trn, setTrn] = useState('');
  const [code, setCode] = useState('');

  const createNewCustomer = async () => {
    const data = {
      name,
      mobile,
      location,
      credit_limit: creditLimit,
      trn_number: trn,
    };
    setLoadingIndicator(true);
    try {
      await CustomerService.createCustomer(data);
      postAlertMessage({
        text: 'Customer created successfully',
        type: 'success',
      });
      toggleAddTab('save');
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
      toggleAddTab();
    }
    setLoadingIndicator(false);
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create New Customer</h2>
          <CloseIcon onClick={toggleAddTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}> Customer Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Customer Name"
                variant="outlined"
                size="small"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Mobile</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Mobile"
                variant="outlined"
                size="small"
                type="number"
                className={styles.numberInput}
                name="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Place</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Place"
                variant="outlined"
                size="small"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Credit Limit</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Credit Limit"
                variant="outlined"
                type="number"
                size="small"
                value={creditLimit}
                onChange={(e) => setCreditLimit(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>{theme?.variable?.trn_label}</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label={theme?.variable?.trn_label}
                variant="outlined"
                size="small"
                value={trn}
                onChange={(e) => setTrn(e.target.value.toUpperCase())}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Code</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="code"
                variant="outlined"
                size="small"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="contained" color="secondary" size="small" onClick={toggleAddTab}>
              close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={name === '' || mobile === '' || location === '' || loadingIndicator}
              onClick={createNewCustomer}
            >
              Create Customer
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
