import BackendService from './BackendService';

async function checkSupport(flavor) {
  return BackendService.getData('/shops/support/', {}, { flavor });
}
async function changeSupport(data) {
  return BackendService.put('/shops/support/', data);
}

const SupportService = {
  checkSupport,
  changeSupport,
};

export default SupportService;
