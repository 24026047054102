import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import styles from './styles.module.css';
import { TextField, Grid, Box, Paper } from '@material-ui/core';
import Loader from '../../utils/Loader';
import AuthService from '../../../services/AuthService';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function ForgetPassword(props) {
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setEmail] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();

  const resetBtnPressed = async (e) => {
    if (!username || !phone || !mail) {
      setErrorMessage('Username and mobile or email  should not be empty..');
      setTimeout(() => {
        setErrorMessage(null);
      }, 4000);
      return;
    }
    setLoadingIndicator(true);
    const error = await AuthService.login(username, phone || mail);
    setLoadingIndicator(false);
    if (error) {
      setErrorMessage('Failed to reset Password !');
    } else {
      history.push('/login');
    }
  };

  return (
    <Grid className={styles.mainDiv}>
      <Box container className={styles.outerBoxFrameWithoutAd}>
        <Grid style={{ boxShadow: 3 }} item xs={12} sm={12}>
          <Grid className={styles.formBoxFrame}>
            <h2 className={styles.titleText}>Reset Password</h2>
            <div className={styles.textMarg}>
              <TextField
                name="username"
                size="small"
                label="Username"
                type="text"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </div>

            <div className={styles.textMarg}>
              <label className={styles.label}>Enter your registered mobile number or email id</label>
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="phone"
                size="small"
                label="Mobile Number"
                type="phone"
                autoComplete="current-phone"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="mail"
                size="small"
                label="Email"
                type="text"
                autoComplete="email"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
            {errorMessage && (
              <Paper className={styles.errorMsg}>
                <span>{errorMessage}</span>
              </Paper>
            )}
            <div className={styles.loginLoader}>{loadingIndicator && <Loader />}</div>
            <Grid className={styles.textCenter}>
              <Button
                className={styles.loginButton}
                style={{ backgroundColor: '#272C86' }}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={resetBtnPressed}
                disabled={loadingIndicator}
              >
                Reset Password
              </Button>
            </Grid>
            <Grid className={styles.forgotPasswordWrapper}>
              <Link to="/login" className={styles.forgotPassword}>
                Goto login?
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
