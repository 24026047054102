import BackendService from './BackendService';

async function initiatePayment(data = {}) {
  return BackendService.postData('/payments/', data);
}

async function paymentStatus(id) {
  return BackendService.getData(`/payments/${id}/status/`);
}

const PaymentService = {
  initiatePayment,
  paymentStatus,
};

export default PaymentService;
