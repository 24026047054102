import React from 'react';
import { Menu, Fade, Divider } from '@mui/material';
import styles from './styles.module.css';
import { Switch, Grid } from '@material-ui/core';

const CustomMenu = ({
  isOpen,
  anchorEl,
  handleClose,
  filteredColumns,
  optionalColumns,
  columns,
  handleTableFilter,
}) => (
  <Menu
    id="fade-menu"
    style={{ maxHeight: 400 }}
    MenuListProps={{
      'aria-labelledby': 'fade-button',
    }}
    anchorEl={anchorEl}
    open={isOpen}
    onClose={handleClose}
    TransitionComponent={Fade}
  >
    <Grid container className={styles.listItemContainer}>
      {columns.map((col) => {
        if (!optionalColumns.hasOwnProperty(col.id)) return null;
        const value = filteredColumns.find(
          (filterCol) => filterCol.id === col.id
        )
          ? true
          : false;

        return (
          <>
            <Grid item xs={12} className={styles.listItem}>
              <p>{col?.label}</p>{' '}
              <Switch
                color="primary"
                checked={value}
                onClick={() => {
                  handleTableFilter(col, !value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        );
      })}
    </Grid>
  </Menu>
);

export default CustomMenu;
