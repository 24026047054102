import { Button, Grid, TextField, Checkbox } from '@material-ui/core';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import VendorServices from '../../services/VendorServices';
import AlertHelper from '../../helpers/AlertHelper';
import { DialogActions } from '@mui/material';

export default function EditCustomerDialog(props) {
  const { toggleEditTab, editData, setSuccessMsg, getVendors } = props;
  const [name, setName] = useState(editData.name);
  const [mobile, setMobile] = useState(editData.mobile);
  const [code, setCode] = useState(editData.code);
  const [isActive, setIsActive] = useState(editData.is_active);
  const [balance, setBalance] = useState(editData.balance);
  const [address, setAddress] = useState(editData.address);
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const handleUpdateVendor = async () => {
    setLoadingIndicator(true);
    const data = {
      ...editData,
      name,
      mobile,
      code,
      is_active: isActive,
      address,
    };
    await VendorServices.updateVendor(editData.id, data);
    AlertHelper.setShortMessage(setSuccessMsg, 'Vendor updated successfully');
    setLoadingIndicator(false);
    toggleEditTab();
    getVendors();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit Vendor</h2>
          <CloseIcon onClick={toggleEditTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Vendor ID</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Vendor Id"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={editData.id}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Mobile</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Mobile"
                name="Mobile"
                size="small"
                variant="outlined"
                fullWidth
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Code</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Code"
                name="code"
                size="small"
                variant="outlined"
                fullWidth
                value={code}
                onChange={(e) => {
                  setCode(e.target.value.toUpperCase());
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Balance</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Balance"
                variant="outlined"
                size="small"
                disabled
                type="number"
                className={styles.numberInput}
                name="balance"
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Code</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Address"
                variant="outlined"
                size="small"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                multiline
                maxRows={4}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Active</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Checkbox
                color="primary"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={toggleEditTab}
            >
              close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={
                name === '' || mobile === '' || code === '' || loadingIndicator
              }
              onClick={handleUpdateVendor}
            >
              Update Vendor
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
