function setShortMessage(alertFunction, message, delay = 2000) {
    alertFunction(message);
    setTimeout(() => {
        alertFunction("")
    }, delay);
}

const AlertHelper = {
    setShortMessage
}

export default AlertHelper