import styles from './styles.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import BillFormatService from '../../../services/BillFormatService';
import Loader from '../../utils/Loading';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { SUBSCRIPTION_TYPE, USER_PERMISSIONS } from '../../../const';
import TerminalService from '../../../services/TerminalService';
import { AlertMessagesContext } from 'react-alert-messages';

export default function BillFormat() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const subscriptionType = shop?.subscription?.type;
  const [billFormat, setBillFormat] = useState(null);
  const [terminals, setTerminals] = useState(null);
  const [selectedTerminal, setSelectedTerminal] = useState(null);

  const userHasShopWritePermission =
    user?.shop_permissions?.shop_permission === USER_PERMISSIONS.WRITE;

  const saveBtnPressed = async (e) => {
    setLoadingIndicator(true);
    if (!selectedTerminal) {
      return;
    }
    try {
      await BillFormatService.updateBillFormat(billFormat, selectedTerminal.id);
      postAlertMessage({ text: 'Successfully updated', type: 'success' });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  useEffect(() => {
    getTerminals();
    // eslint-disable-next-line
  }, []);

  const loadBillFormat = async (selectedTerminal) => {
    setLoadingIndicator(true);
    if (!selectedTerminal) {
      return;
    }
    const data = await BillFormatService.getBillFormat(selectedTerminal.id);
    setBillFormat(data);
    setLoadingIndicator(false);
  };

  const getTerminals = async (e) => {
    setLoadingIndicator(true);
    const terminals = await TerminalService.getTerminals();
    setTerminals(terminals);
    setLoadingIndicator(false);
    if (terminals.length > 0) {
      setSelectedTerminal(terminals[0]);
      await loadBillFormat(terminals[0]);
    }
  };

  return (
    <>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get bill format options upgrade subscription to premium'}
        />
      )}
      <div className={styles.contentLayout}>
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <>
            <Loader isOpen={loadingIndicator} />
            <div className={styles.Bill}>
              <div className={styles.machineDiv}>
                <label className={styles.machineSelectTitle}>
                  Counter Name
                </label>
                <TextField
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                  select
                  name="selectedMachine"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={selectedTerminal}
                  onChange={(event) => {
                    setSelectedTerminal(event.target.value);
                    loadBillFormat(event.target.value);
                  }}
                >
                  {(terminals || []).map((terminal) => (
                    <MenuItem key={terminal.id} value={terminal}>
                      {terminal.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className={styles.BillHeaderDiv}>
                <span className={styles.pageHead}>Invoice header details</span>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Shop Name</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="shopName"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          shop_name: e.target.value,
                        });
                      }}
                      value={billFormat?.shop_name || ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 1</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address1"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          address_1: e.target.value,
                        });
                      }}
                      value={billFormat?.address_1 || '' }
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 2</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address2"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          address_2: e.target.value,
                        });
                      }}
                      value={billFormat?.address_2 || ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 3</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address3"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          address_3: e.target.value,
                        });
                      }}
                      value={billFormat?.address_3 || ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 4</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address4"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          address_4: e.target.value,
                        });
                      }}
                      value={billFormat?.address_4 || ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Mobile</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="mobile"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          tel_no: e.target.value,
                        });
                      }}
                      value={billFormat?.tel_no || ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Tax Number</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="gstNumber"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          tin: e.target.value,
                        });
                      }}
                      value={billFormat?.tin || ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Footer 1</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="footer1"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          footer_1: e.target.value,
                        });
                      }}
                      value={billFormat?.footer_1 || ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Footer 2</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="footer2"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        setBillFormat({
                          ...billFormat,
                          footer_2: e.target.value,
                        });
                      }}
                      value={billFormat?.footer_2 || ''}
                    />
                  </div>
                </Grid>
                {userHasShopWritePermission && (
                  <Grid item xs={12}>
                    <div className={styles.buttonArea}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={saveBtnPressed}
                        style={{ backgroundColor: '#00a65a' }}
                        disabled={loadingIndicator}
                      >
                        Update Invoice format
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
            <div className={styles.Bill}>
              <div className={styles.billPreview}>
                <div className={styles.billSample}>
                  <div style={{ textAlign: 'center' }}>
                    <h3>{billFormat?.shop_name || ''}</h3>
                    <h6>{billFormat?.address_1 || ''}</h6>
                    <h6>{billFormat?.address_2 || ''}</h6>
                    <h6>{billFormat?.address_3 || ''}</h6>
                    <h6>{billFormat?.address_4 || ''}</h6>
                    <h6>{billFormat?.tel_no || ''}</h6>
                    <h6>{billFormat?.tin || ''}</h6>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                      CASH/BILL
                    </span>
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: 12,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={styles.borderBottomDotted}
                    >
                      <span>NO 00011012</span>
                      <span>22-02-2016</span>
                    </div>
                    <div
                      style={{ fontSize: 12, display: 'flex', padding: 2 }}
                      className={styles.borderBottomDotted}
                    >
                      <span style={{ width: '40%' }}>DESCRIPTION</span>
                      <span style={{ width: '20%' }}>QTY</span>
                      <span style={{ width: '20%' }}>RATE</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        AMOUNT
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Amul</span>
                      <span style={{ width: '20%' }}>2.00</span>
                      <span style={{ width: '20%' }}>5.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        10.00
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Tea</span>
                      <span style={{ width: '20%' }}>4.00</span>
                      <span style={{ width: '20%' }}>15.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        60.00
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Black Forest</span>
                      <span style={{ width: '20%' }}>5.00</span>
                      <span style={{ width: '20%' }}>8.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        40.00
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Kaju Draksha</span>
                      <span style={{ width: '20%' }}>7.00</span>
                      <span style={{ width: '20%' }}>60.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        420.00
                      </span>
                    </div>

                    <div
                      style={{
                        fontSize: 14,
                        display: 'flex',
                        padding: 2,
                        fontWeight: 'bold',
                      }}
                      className={styles.borderTOpDotted}
                    >
                      <span style={{ width: '50%' }}>CASH</span>
                      <span style={{ width: '50%', textAlign: 'right' }}>
                        530.00
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ width: '100%' }}>
                        {billFormat?.footer_1 || ''}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ width: '100%' }}>
                        {billFormat?.footer_2 || ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
