import React, { useState } from 'react';
import { Button, DialogActions, Grid, TextField } from '@mui/material';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import AlertHelper from '../../helpers/AlertHelper';
import SalesPersonService from '../../services/SalesPersonService';

export default function SalesPersonFormDialog({ handleClose, handleSuccess, setErrorMsg, setSuccessMsg, salesPerson }) {
  const [data, setData] = useState(salesPerson || {});

  const handleActionButton = async () => {
    try {
      const salespersonData = {
        ...data,
        access_code: data.password,
      };
      if (data.id) {
        await SalesPersonService.updateSalesPersons(data.id, data);
        AlertHelper.setShortMessage(setSuccessMsg, 'Salesperson updated successfully');
      } else {
        await SalesPersonService.createSalesPersons(salespersonData);
        AlertHelper.setShortMessage(setSuccessMsg, 'Salesperson created successfully');
      }
      handleSuccess();
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    handleClose();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Add Salesperson</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                type="text"
                value={data.name || ''}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Username</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Username"
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                type="text"
                value={data.username || ''}
                onChange={(e) => {
                  setData({ ...data, username: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Password</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                className={styles.numberInput}
                value={data.password || ''}
                onChange={(e) => {
                  setData({
                    ...data,
                    password: e.target.value.substring(0, 4),
                  });
                }}
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ backgroundColor: '#00a65a' }}
              disabled={!data.name || data.name === '' || !data.password || data.password === ''}
              onClick={handleActionButton}
            >
              {data.id ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
