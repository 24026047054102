import BackendService from './BackendService';
import CacheHelper from '../helpers/CacheHelper';
import { HEADERS, STORAGE_KEYS } from '../const';

const cache = {};

async function getUserProfile(
  shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID)
) {
  if (!shopId) return;

  const cacheKey = `shop-${shopId}-user-profile`;
  const cachedUserProfile = CacheHelper.get(cache, cacheKey);
  if (cachedUserProfile) {
    return cachedUserProfile;
  }

  const userProfile = await BackendService.getData('/user_profile/', {
    [HEADERS.SHOP_ID]: shopId,
  });
  CacheHelper.store(cache, cacheKey, userProfile);
  return userProfile;
}
async function getShopUsers(data = {}) {
  return BackendService.getData('/shop_users/', data);
}
async function createNewUser(data) {
  return BackendService.postData('/shop_users/', data);
}
async function getShopUserDetailsAndPermissions(id) {
  return BackendService.getData(`/shop_users/${id}/`);
}
async function deleteUser(id) {
  return BackendService.destroy(`/shop_users/${id}/`);
}
async function UpdateUserPermissions(data, id) {
  return BackendService.postData(`/shop_users/${id}/`, data);
}

const UserService = {
  getUserProfile,
  getShopUsers,
  createNewUser,
  getShopUserDetailsAndPermissions,
  UpdateUserPermissions,
  deleteUser,
};

export default UserService;
