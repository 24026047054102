import BackendService from './BackendService';

async function getMachines() {
  return BackendService.getData('/machines/');
}
async function getBillFormat(terminalId) {
  return BackendService.getData(
    `/terminals/${terminalId}/invoice_format/`,
  );
}
async function updateBillFormat(data, terminalId) {
  return BackendService.postData(`/terminals/${terminalId}/invoice_format/`, data);
}

const BillFormatService = {
  getMachines,
  getBillFormat,
  updateBillFormat,
};

export default BillFormatService;
