import BackendService from './BackendService';

async function getDevices(data = {}) {
  return BackendService.getData('/machines/', data);
}
async function deleteDevice(id) {
  return BackendService.destroy(`/machines/${id}/`, '');
}
async function addDevice(data = '') {
  return BackendService.postData('/machines/', data);
}
async function getDeviceShortKey(id) {
  return BackendService.getData(`/machine/shortkeys/?machine_number=${id}`);
}
async function updateShortKey(data) {
  return BackendService.postData('/machine/shortkeys/', data);
}
const DeviceService = {
  getDevices,
  deleteDevice,
  addDevice,
  getDeviceShortKey,
  updateShortKey,
};

export default DeviceService;
