import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import EditCategoryDialog from '../../popups/EditCategoryDialog';
import AddCategoryDialog from '../../popups/AddCategoryDialog';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ItemService from '../../../services/ItemService';
import ListDialog from '../../popups/ListDialog';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import Info from '../../utils/Alert/Info';
import Success from '../../utils/Alert/Success';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import Error from '../../utils/Alert/Error';
import { UserContext } from '../../../Context/UserContext';
import { ShopContext } from '../../../Context/ShopContext';
import { USER_PERMISSIONS } from '../../../const';

function ItemsCategories() {
  const { user } = useContext(UserContext);
  const { shop } = useContext(ShopContext);
  const [categories, setCategories] = useState();
  const [categoriesFiltered, setCategoriesFiltered] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isShowCreateFormDialog, setShowCreateFormDialog] = useState(false);
  const [itemsData, setItemsData] = useState('');
  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  // TODO: Replace usage with react-alert-messages
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const handleEditFormDialogClose = () => {
    setSelectedCategory(null);
  };

  const handleCreateFormDialogClose = () => {
    setShowCreateFormDialog(false);
  };

  const onCategoryUpdateSuccess = () => {
    getCategories();
  };

  const onCategorySelected = (category) => {
    setSelectedCategory(category);
  };

  const toggleItemsTab = (rowData) => {
    if (!rowData) {
      setItemsData('');
    } else {
      setItemsData(items.filter((item) => item.category === Number(rowData.id)));
    }
  };

  const handleOpenCreateFormDialog = () => {
    setShowCreateFormDialog(true);
  };

  const getCategories = async () => {
    setLoadingIndicator(true);
    try {
      const res = await ItemService.getItemCategories();
      setCategories(res);
      setCategoriesFiltered(res);
    } catch (err) {
      setErrorMsg(err.message);
    }
    setDelay(setLoadingIndicator);
  };

  const handleCategorySearch = (value) => {
    setSearchText(value);
    setCategoriesFiltered(categories.filter((category) => category.name.toLowerCase().includes(value.toLowerCase())));
  };
  const getAllItems = async () => {
    const items = await ItemService.getItems();
    setItems(items);
  };
  const headerData = [
    {
      label: 'Category Name',
      id: 'name',
      type: 'text',
    },
  ];

  if (shop && shop.image_support_validity != null) {
    headerData.splice(1, 0, {
      label: 'Category Image',
      id: 'image',
      type: 'image',
    });
  }
  if (shop && (shop.kot_validity != null || shop.kot_lite_validity != null)) {
    headerData.push({
      label: 'Kitchen Ip',
      id: 'kitchen_ip',
      type: 'text',
    });
  }
  headerData.push({
    label: 'View Products',
    id: 'viewItems',
    type: 'button',
    title: 'View Products',
    clickHandler: toggleItemsTab,
  });
  if (((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.WRITE) {
    headerData.push({
      label: 'Edit Category',
      id: 'updateCategory',
      type: 'button',
      title: 'Edit Category',
      clickHandler: onCategorySelected,
    });
  }
  const listHeader = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Price',
      id: 'price',
      type: 'text',
    },
  ];
  useEffect(() => {
    getAllItems();
    getCategories();
  }, []);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Items<span className={styles.menuTitle}>Category</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Categories</h2>
          </div>
          <div className={styles.filerInputSec}>
            {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.READ ? (
              ''
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={handleOpenCreateFormDialog}
              >
                <AddShoppingCartIcon className={styles.actionBtnIcon} />
                New category
              </Button>
            )}
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                placeholder="search category"
                onChange={(e) => {
                  handleCategorySearch(e.target.value);
                }}
                className={styles.searchInput}
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {categories &&
        (categoriesFiltered && categoriesFiltered.length ? (
          <DataTable columns={headerData} rows={categoriesFiltered ? categoriesFiltered : categories} />
        ) : (
          <Info title={'You have no categories to list'} content={"You haven't categories with the given properties"} />
        ))}

      {selectedCategory && (
        <EditCategoryDialog
          category={selectedCategory}
          handleClose={handleEditFormDialogClose}
          onSuccess={onCategoryUpdateSuccess}
          setErr={setErrorMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}

      {isShowCreateFormDialog && (
        <AddCategoryDialog
          handleClose={handleCreateFormDialogClose}
          onSuccess={onCategoryUpdateSuccess}
          setErr={setErrorMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}

      {itemsData && (
        <ListDialog
          toggleItemsTab={toggleItemsTab}
          rowData={itemsData}
          tableHeader={listHeader}
          title={'Category Products'}
          emptyDataTitle="You have an empty list of items for this category!"
          emptyDataContent="Start adding your items for this category by editing this category"
        />
      )}
    </div>
  );
}

export default withConsoleBase(ItemsCategories);
