import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import TerminalService from '../../../services/TerminalServices';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import AlertHelper from '../../../helpers/AlertHelper';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ROUTES } from '../../../const';
import DevicesIcon from '@mui/icons-material/Devices';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { Chip } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import { Grid, Typography } from '@mui/material';
import shortDateDiff from 'short-date-diff';

function Terminals() {
  const [terminals, setTerminals] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const history = useHistory();

  const getTerminals = async () => {
    setLoadingIndicator(true);
    try {
      const res = await TerminalService.getTerminals();
      setTerminals(res);
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
    setDelay(setLoadingIndicator);
  };

  const moveToTerminalSettings = (row) => {
    history.push(ROUTES.TERMINAL_SETTINGS.replace(':terminalId', row.id));
  };

  const handleDeleteClick = (data) => {
    setDeleteData(data);
  };
  const handleTerminalDeletion = async (row) => {
    try {
      await TerminalService.updateTerminals(row.id, { is_active: false });
      getTerminals();
      AlertHelper.setShortMessage(
        setSuccessMsg,
        'Terminal deactivation successfully'
      );
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
  };

  useEffect(() => {
    getTerminals();
  }, []);

  const headerData = [
    {
      label: 'Id',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Mac ID',
      id: 'mac_id',
      type: 'text',
    },
    {
      label: 'Type',
      id: 'type',
      type: 'callback',
      viewRender: (data) => getDeviceType(data),
    },
    {
      label: 'Location',
      id: 'location',
      type: 'text',
    },
    {
      label: 'Status',
      id: 'status',
      type: 'callback',
      viewRender: (data) => getDeviceStatus(data),
    },
  ];

  const getDateDiff = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    const nowUTC = new Date(now.getTime() - now.getTimezoneOffset() * 60000);

    return shortDateDiff(start, nowUTC);
  };

  const getDeviceStatus = (row) => {
    if (row.type === 'pos') return;
    if (row.connected_host === null) {
      return (
        <Grid display="flex" flexDirection="column" alignItems="center">
          <Chip
            label="offline"
            icon={<WifiOffIcon fontSize="small" />}
            color="error"
            variant="outlined"
          />
          {row.disconnected_at && (
            <Typography variant="caption" color="gray">
              {`disconnected at ${getDateDiff(row.disconnected_at)} ago`}
            </Typography>
          )}
        </Grid>
      );
    } else {
      return (
        <Grid display="flex" flexDirection="column" alignItems="center">
          <Chip
            label="online"
            icon={<WifiIcon fontSize="small" />}
            color="success"
            variant="outlined"
          />
        </Grid>
      );
    }
  };

  const getDeviceType = (row) => {
    if (row.type === 'pos') {
      return <DevicesIcon style={{ color: '#07396d' }} />;
    } else {
      return <PointOfSaleIcon style={{ color: '#07396d' }} />;
    }
  };

  const getRowStyle = (row) => {
    if (row.is_active === false) {
      return { backgroundColor: 'rgba(245, 39, 39, 0.2)' };
    } else return;
  };

  const getRowBlackMessage = (row) => {
    return row?.sales_hold_reason;
  };

  const handleRefresh = () => {
    getTerminals();
  };

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <ConfirmPopup
        data={deleteData}
        handleClose={handleDeleteClick}
        handleOkay={handleTerminalDeletion}
        alertTitle={'Confirm Deactivate'}
        successBtnName={'Deactivate'}
        alertContent={'Do you want to deactivate the terminal?'}
      />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Terminal <span className={styles.menuTitle}>list</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Terminals</h2>
          </div>
          <div className={styles.actionButtons}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.actionBtn}
              style={{ backgroundColor: '#00a65a' }}
              onClick={handleRefresh}
            >
              <RefreshIcon />
              Refresh terminals status
            </Button>
          </div>
        </div>
      </div>
      {/* <DataTable
        columns={headerData}
        rows={terminals}
        handleDeviceDeletion={handleDeviceDeletion}
      /> */}
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {!loadingIndicator &&
        (terminals[0] ? (
          <DataTable
            columns={headerData}
            rows={terminals}
            handleDeviceDeletion={handleTerminalDeletion}
            rowClickListener={moveToTerminalSettings}
            rowStyle={getRowStyle}
            rowBlockMessage={getRowBlackMessage}
          />
        ) : (
          <Info
            title={'You have no terminal to list'}
            content={"You haven't any terminal in this shop"}
          />
        ))}
    </div>
  );
}

export default withConsoleBase(Terminals);
