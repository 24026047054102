import BackendService from './BackendService';

async function getPurchaseItemSummary(data = {}) {
  return BackendService.postData('/reports/purchase-item-summary/', data);
}
async function getVendorPurchaseSummary(data = {}) {
  return BackendService.postData('/reports/vendor-purchase-summary/', data);
}

const PurchaseSummaryReportServices = {
  getPurchaseItemSummary,
  getVendorPurchaseSummary,
};

export default PurchaseSummaryReportServices;
