import styles from './styles.module.css';
import React, { Component } from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../utils/Footer/Footer';
import ShopSettingsService from '../../../services/ShopSettingsService';

export default class Subscription extends Component {
  constructor(props) {
    super(props);
    this.paymentFormRef = React.createRef();
    this.state = {
      loadingIndicator: false,
      plans: [],
    };
  }

  async componentDidMount() {
    this.loadSubscriptionPlans().then();
  }

  successDialog = async () => {
    this.setState({
      message: 'Subscription successfully purchased to subscription list.',
    });
    setTimeout(() => {
      this.setState({ message: undefined });
    }, 2500);
  };

  failureDialog = async (error) => {
    const errorMessage =
      error?.message ||
      'Oops! Something went wrong! Make sure you have entered all the details correctly!';
    this.setState({ errorMessage });
    setTimeout(() => {
      this.setState({ errorMessage: undefined });
    }, 2000);
  };

  planBtnPressed = async (plan) => {
    const data = { subscription_id: plan.id };
    try {
      const resp = await ShopSettingsService.initiatePayment(data);
      if (resp.external_payment_token) {
        this.redirectToPaymentsPage(resp);
      } else {
        this.successDialog();
      }
    } catch (error) {
      this.failureDialog(error);
    }
  };

  redirectToPaymentsPage = (resp) => {
    this.setState({
      paymentDetails: resp,
    });
    // this.paymentFormRef.current?.submit();
  };

  loadSubscriptionPlans = async (e) => {
    this.setState({ loadingIndicator: true });
    const plans = await ShopSettingsService.getSubscriptionPlans();
    this.setState({ loadingIndicator: false, plans });
  };

  render() {
    const paymentDetails = this.state.paymentDetails || {};
    return (
      <React.Fragment>
        <div className={styles.contentLayout}>
          <div className={styles.contentsArea}>
            <div className={styles.headingDiv}>
              <span className={styles.pageHead}>Subscription Plans</span>
              <div
                style={{
                  height: '40px',
                  paddingRight: 20,
                  paddingLeft: 20,
                  position: 'fixed',
                }}
              >
                {this.state.message && (
                  <Alert severity="success">{this.state.message}</Alert>
                )}
                {this.state.errorMessage && (
                  <Alert severity="error">{this.state.errorMessage}</Alert>
                )}
              </div>
            </div>

            <Grid container className={styles.mainGrid}>
              {this.state.plans.map((plan) => {
                return (
                  <Grid item xs={12} sm={4} key={plan.id}>
                    <Paper
                      style={{
                        padding: 10,
                        margin: 10,
                        height: 'calc(100% - 40px)',
                      }}
                    >
                      <div style={{ marginBottom: 16, marginTop: 16 }}>
                        <span className={styles.planTitle}>{plan.name}</span>
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <span>{plan.description}</span>
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <span className={styles.rupeeSymbol}>
                          <FontAwesomeIcon icon={faRupeeSign} />
                        </span>
                        <span className={styles.price}> {plan.rate}/</span>
                        <span className={styles.perYear}>{plan.days} Days</span>
                      </div>
                      <div className={styles.alignBox}>
                        <div style={{ marginBottom: 8 }}>
                          -{' '}
                          {plan.max_products === -1
                            ? 'Unlimited PLU Products'
                            : `${plan.max_products} PLU Products`}
                        </div>
                        <div style={{ marginBottom: 8 }}>
                          - Maximum{' '}
                          {plan.max_machines === -1
                            ? ' 5 Machines in an account'
                            : `${plan.max_machines} Machine in an account`}
                        </div>
                        <div style={{ marginBottom: 8 }}>
                          -{' '}
                          {plan.sales_sync_interval === -1
                            ? ' Real time Sales Updates'
                            : ` Sales Update in ${plan.sales_sync_interval} Hours`}
                        </div>
                        <div style={{ marginBottom: 8 }}>
                          -{' '}
                          {plan.auto_sync_product_save === true
                            ? ' Real time Product Sync href Machine '
                            : ` Product sync only 3 times a
                            day`}
                        </div>
                        <div style={{ marginBottom: 8 }}>
                          - {plan.days} Days Subscription Period
                        </div>
                        <div style={{ marginTop: 26, marginBottom: 16 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              this.planBtnPressed(plan);
                            }}
                            disabled={this.state.loadingIndicator}
                          >
                            {plan.name}
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        <Footer />
        <form
          ref={this.paymentFormRef}
          method="post"
          action={`${
            paymentDetails.redirection_base_url
          }/theia/api/v1/showPaymentPage?mid=${
            paymentDetails.merchant_id || ''
          }&orderId=${paymentDetails.order_id || ''}`}
          name="paytm"
        >
          <input
            type="hidden"
            name="mid"
            value={paymentDetails.merchant_id || ''}
          />
          <input
            type="hidden"
            name="orderId"
            value={paymentDetails.order_id || ''}
          />
          <input
            type="hidden"
            name="txnToken"
            value={paymentDetails.external_payment_token || ''}
          />
        </form>
      </React.Fragment>
    );
  }
}
