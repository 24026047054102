import BackendService from './BackendService';

async function getTerminals(params = {}) {
  return BackendService.getData('/terminals/', {}, params);
}

const TerminalService = {
  getTerminals,
};

export default TerminalService;
