import BackendService from './BackendService';

async function getSubscriptionsAndAdons(country) {
  return BackendService.getData(
    `/subscription-and-addons/?country_code=${country}`
  );
}
async function verifyRegistrationKey(secret) {
  return BackendService.postData('/registration-keys/verify/', { secret });
}
async function renewSubscriptionWithKey(data={}) {
  return BackendService.postData('/registration-keys/renew/', data);
}

const SubscriptionService = {
  getSubscriptionsAndAdons,
  verifyRegistrationKey,
  renewSubscriptionWithKey,
};

export default SubscriptionService;
