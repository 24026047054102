import React from 'react';

import { Backdrop, CircularProgress, Typography } from '@mui/material';

export default function Loader({ isOpen, size, backdropColor, message }) {
  if (!isOpen) {
    return <></>;
  }

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
        backgroundColor: backdropColor || 'rgba(0,0,0,0.5)',
      }}
      open={true}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress
          size={size || 36}
          sx={{
            top: -6,
            left: -6,
            zIndex: 1,
            color: '#FF851B',
          }}
        />
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: '#fff',
            paddingTop: '10px'
          }}
        >
          {message}
        </Typography>
      </div>
    </Backdrop>
  );
}
