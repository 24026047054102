import SanoftLogo192 from './images/sanoft_logo_192.png';
import SanoftLogo512 from './images/sanoft_logo_512.png';
import ScangleLogo192 from './images/scangle_logo_192.png';
import ScangleLogo512 from './images/scangle_logo_512.png';
import WeighvoxLogo192 from './images/weighvox_log_192.png';
import WeighvoxLogo512 from './images/weighvox_logo_512.png';
import YescloudLogo192 from './images/yesweigh_logo_192.png';
import YescloudLogo512 from './images/yesweigh_logo_512.png';
import DsonicLogo192 from './images/d_sonic_logo_512.png';

export const BACKEND_URL = process.env.REACT_APP_API_BASE_URL || 'https://api-pos-legacy.sanoft.com/api';
// export const BACKEND_URL = 'https://yscloud.in/backend/api';
// export const BACKEND_URL = 'http://192.168.1.37:8000/api';
// export const BACKEND_URL = process.env.REACT_APP_API_BASE_URL || 'https://api-pos-legacy-uat.sanoft.com/api';

export const STORAGE_KEYS = {
  TOKEN: 'x-auth-token',
  USER_ID: 'x-auth-user-id',
  SHOP_ID: 'x-selected-shop-id',
  FIRST_NAME: 'x-first-name',
  LAST_NAME: 'x-last-name',
  USERNAME: 'x-username',
  BILL_PRINTER_IP: 'x-bill-printer-ip',
  USER_TYPE: 'x-user-type',
  SHOP_NAME: 'x-shop-name',
  ACTIVE_TAB: 'x-active-tab',
  DONT_SHOW_OTHER_SHOPS_ON_ITEM_EDIT: 'x-dont-show-other-shops-on-item-edit',
  SIGNUP_SECRET: 'x-signup-key',
  MAC_ID: 'x-mac-id',
  SALES_REPORT_COLUMN_FILTER: 'x-sales-summary-filter-column',
  REGISTRSATION_KEY: 'x-registration-key',
};

export const HEADERS = {
  AUTH: 'Authorization',
  SHOP_ID: 'shop-id',
};

export const GST_PREFERENCES = {
  0: 'No Tax',
  1: 'Exclude Tax',
  2: 'Include Tax',
};

export const USER_PERMISSIONS = {
  DENY: 'deny',
  READ: 'read',
  WRITE: 'write',
};

export const SUBSCRIPTION_TYPE = {
  BASIC: 'basic',
  STANDARD: 'standard',
  PREMIUM: 'premium',
};

export const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  SIGNUP: '/signup',
  PLANS: '/plans',
  PRICING: '/pricing',
  ADD_ONS: '/add-ons',
  PROCESS_PAYMENT: '/payment-processing',
  PRICING_COMPARISON: '/pricing-comparison',
  ANY: '/',
  CONSOLE: '/console',
  ONBOARDING: '/onboarding',
  APP_SETUP: '/app-setup',
  SHOP_CREATED: '/shop-created',
  PAYMENT_STATUS: '/payment-status',

  DASHBOARD: '/console/dashboard',
  ITEMS: '/console/items',
  CATEGORIES: '/console/categories',
  DEVICES: '/console/devices',
  TERMINALS: '/console/terminals',
  TERMINAL_SETTINGS: '/terminals/:terminalId',
  CUSTOMERS: '/console/customers',
  CUSTOMER_DETAILS: '/console/customers/:customerId',
  SALES_PERSON: '/console/sales-person',
  TABLES: '/console/tables',
  PURCHASE_HISTORY: '/console/purchase-history',
  NEW_PURCHASE: '/console/new-purchase',
  EDIT_PURCHASE: '/console/edit-purchase/:id',
  DAMAGE_HISTORY: '/console/damage-history',
  NEW_DAMAGE: '/console/new-damage',
  ITEM_SUMMARY: '/console/report/item-summary',
  PROFIT_ITEM_SUMMARY: '/console/report/profit/item-summary',
  ITEMISED_BILL: '/console/report/sale-items',
  PROFIT_ITEMISED_BILL: '/console/report/profit/sale-items',
  BILL_SUMMARY: '/console/report/sale-summary',
  ORDER_TYPE_SALES_REPORT: '/console/report/order-type-summary',
  RETURNED_BILL_SUMMARY: '/console/report/returned-sale-summary',
  RETURNED_ITEM_SUMMARY: '/console/report/returned-item-summary',
  RETURNED_SALE_ITEM_SUMMARY: '/console/report/returned-sale-item',
  PROFIT_BILL_SUMMARY: '/console/report/profit/sale-summary',
  DAY_WISE_REPORT: '/console/report/day-wise',
  STAFF_SALES_REPORT: '/console/report/staff-sales',
  CATEGORY_SALES_REPORT: '/console/report/category-sales',
  PROFIT_DAY_WISE_REPORT: '/console/report/profit/day-wise',
  STOCK_REPORT: '/console/stock-report',
  PAYMENT_MODE_REPORT: '/console/report/payment-mode',
  PURCHASE_SUMMARY: '/console/report/purchase-summary',
  PURCHASE_ITEM_SUMMARY: '/console/report/payment-item-summary',
  VENDON_PURCHASE_SUMMARY: '/console/report/vendor-summary',
  SALES_TAX_SUMMARY: '/console/report/sales-tax-summary',
  PURCHASE_TAX_SUMMARY: '/console/report/purchase-tax-summary',
  HSN_SUMMARY: '/console/report/hsn-summary',
  SETTINGS: '/console/settings',
  SETTINGS_TAB: '/console/settings/:tab',
  SETTINGS_SUB_TAB: '/console/settings/:tab/:subTab',
  SUBSCRIPTION_PLANS: '/subscription-plans',
  PERMISSION_DETAIL: '/settings/shop-users/:id',
  PAYMENT_CALLBACK: '/subscription-plans/payment-callback',
  PAGE_404: '/page-404',
  EXPENSES: '/console/expenses',
  VENDORS: '/console/vendors',
  VENDOR_DETAILS: '/console/vendors/:vendorId',
};

export const CONFIGS = {
  MAX_IMAGE_SIZE: 101000,
  LOGO_MAX_SIZE: 202000,
};

export const plans = {
  standard: {
    monthly: {
      IN: {
        price: 320,
        plan_id: 'SANOFT_ANDROID_POS_STANDARD-INR-Monthly',
      },
      US: {
        price: 8,
        plan_id: 'SANOFT_ANDROID_POS_STANDARD-USD-Monthly',
      },
    },
    yearly: {
      IN: {
        price: 3200,
        plan_id: 'SANOFT_ANDROID_POS_STANDARD-INR-Yearly',
      },
      US: {
        price: 80,
        plan_id: 'SANOFT_ANDROID_POS_STANDARD-USD-Yearly',
      },
    },
  },
  premium: {
    monthly: {
      IN: {
        price: 500,
        plan_id: 'SANOFT_ANDROID_POS_PREMIUM-INR-Monthly',
      },
      US: {
        price: 12,
        plan_id: 'SANOFT_ANDROID_POS_PREMIUM-INR-Monthly',
      },
    },
    yearly: {
      IN: {
        price: 5000,
        plan_id: 'SANOFT_ANDROID_POS_PREMIUM-INR-Yearly',
      },
      US: {
        price: 120,
        plan_id: 'SANOFT_ANDROID_POS_PREMIUM-USD-Monthly',
      },
    },
  },
};

export const CONTACT_NUMBER = '919746693296';

export const PRINTER_TYPES = ['USB', 'Network', 'Sunmi', 'Bluetooth', 'PDF', 'Whatsapp'];

export const INVOICE_FORMATS = [
  '2 Inch',
  '2 Inch Sunmi',
  '2 Inch Image - English',
  '2 Inch image - Multi language',
  '3 Inch',
  '3 Inch Image - English',
  '3 Inch Image - Multi languate',
];

export const KOT_FORMATS = [
  '2 Inch',
  '2 Inch Sunmi',
  '2 Inch Invoice',
  '3 Inch',
  '3 Inch Invoice',
  '3 Inch Image Invoice',
  '3 Inch Image - English',
];

export const BARCODE_PRINTER_TYPES = ['USB', 'Network'];
export const BARCODE_PRINTER_FORMATS = ['CUSTOM PRN', '38 X 25', '50 X 25', '38 X 25 ONE COLUMN', '50 X 25 ONE COLUMN'];

export const SUFFIX_OPTIONS = ['FID', 'SID'];

export const ORDER_TYPES = [
  { 'Dine In': 'DINE IN' },
  { 'Take Away': 'TAKE AWAY' },
  { Delivery: 'DELIVERY' },
  { Online: 'ONLINE' },
];

export const TAX_PREFERENCES = [
  { 'No Tax': 'No Tax' },
  { 'Excluding Tax': 'Excluding Tax' },
  { 'Including Tax': 'Including Tax' },
];

export const TAX_REGIONS = [{ India: 'India' }, { 'Saudi Arabia': 'Saudi' }, { UAE: 'UAE' }, { Oman: 'Oman' }];

export const THEME_CONFIG = {
  default: {
    name: 'sanoft',
    color: {
      primary: '#272C86',
      secondary: '#1F236B',
      tertiary: '#9DA2FF',
      header: '#383FC1',
      sub_header: '#1F236B',
      bg_color: '#272C86',
      active: '#1F236B',
      active_bg: '#383FC1',
    },
    variable: {
      name: 'SANOFT',
      title:
        'Sanoft POS - Secure and Reliable Point of Sale System. Experience trustworthy and secure transactions with Sanoft POS. Perfect for retail, restaurants, and more. Start your free trial today!',
      logo512: SanoftLogo512,
      logo192: SanoftLogo192,
      phone: '919567453182',
      trn_label: 'GSTIN',
    },
  },
  'spos.scangle.in': {
    name: 'scangle',
    color: {
      primary: '#1a120b',
      secondary: '#1a120b',
      tertiary: '#3c2a21',
      header: '#ff6d00',
      sub_header: '#ffffff',
      bg_color: '#444444',
      active: '#ec9132',
      active_bg: '#c87300',
    },
    variable: {
      name: 'SCANGLE',
      title: 'Scangle POS Cloud',
      logo512: ScangleLogo512,
      logo192: ScangleLogo192,
      phone: '919139000888',
      trn_label: 'GSTIN',
    },
  },
  'pos.weighvox.com': {
    name: 'weighvox',
    color: {
      primary: '#07396d',
      secondary: '#03204c',
      tertiary: '#72c7ec',
      header: '#272C86',
      sub_header: '#03204c',
      bg_color: '#444444',
      active: '#03204c',
      active_bg: '#065C96',
    },
    variable: {
      name: 'VOX',
      title: 'VOX POS Cloud',
      logo512: WeighvoxLogo512,
      logo192: WeighvoxLogo192,
      phone: '971524295311',
      trn_label: 'VAT NO',
    },
  },
  'pos.yesweigh.in': {
    name: 'yesweigh',
    color: {
      primary: '#062e4b',
      secondary: '#162937',
      tertiary: '#a8d8fb',
      header: '#ed4f22',
      sub_header: '#0B253A',
      bg_color: '#062e4b',
      active: '#374d6e',
      active_bg: '#065C96',
    },
    variable: {
      name: 'YES',
      title: 'YES POS Cloud',
      logo512: YescloudLogo192,
      logo192: YescloudLogo512,
      phone: '918803333444',
      trn_label: 'GSTIN',
    },
  },
  'www.dsoniccloud.com': {
    name: 'dsonic',
    color: {
      primary: '#07396d',
      secondary: '#03204c',
      tertiary: '#72c7ec',
      header: '#272C86',
      sub_header: '#03204c',
      bg_color: '#ececec',
      active: '#03204c',
      active_bg: '#065C96',
    },
    variable: {
      name: 'DSONIC',
      title: 'Dsonic POS Cloud',
      logo512: DsonicLogo192,
      logo192: DsonicLogo192,
      phone: '919567453182',
      trn_label: 'GSTIN',
    },
  },
};

export const COUNTRY_CONFIGRATION = {
  IN: { name: 'India', currency: '₹', code: 'IN' },
  SA: { name: 'Saudi Arabia', currency: 'SAR', code: 'SA' },
  OM: { name: 'Oman', currency: 'OMR', code: 'OM' },
  AE: { name: 'UAE', currency: 'AED', code: 'AE' },
  US: { name: 'US', currency: '$', code: 'US' },
};

export const MEASURMENT_UNITS = [
  { unit: 'Kilogram', symbol: 'Kg' },
  { unit: 'Gram', symbol: 'g' },
  { unit: 'Liter', symbol: 'Ltr' },
  { unit: 'Milliliter', symbol: 'Ml' },
  { unit: 'Meter', symbol: 'm' },
  { unit: 'Centimeter', symbol: 'cm' },
  { unit: 'Foot', symbol: 'ft' },
  { unit: 'Inch', symbol: 'Inch' },
  { unit: 'Piece', symbol: 'Pcs' },
  { unit: 'Unit', symbol: 'Unit' },
  { unit: 'Box', symbol: 'Box' },
  { unit: 'Square meter', symbol: 'm3' },
  { unit: 'Square foot', symbol: 'ft2' },
  { unit: 'Carat', symbol: 'Carat' },
  { unit: 'Dozen', symbol: 'Dozen' },
];

export const REGISTER_STEPS = ['signup', 'shop type', 'counter type', 'basic setup'];

const DEFAULT_SHOP_SETTINGS = {
  advanced_quantity_control: false,
  amount_spent_for_loyalty_point: 100,
  amount_with_three_fraction: false,
  barcode_printer_format: '38 X 25',
  barcode_printer_note: null,
  barcode_printer_type: 'USB',
  consolidated_kot_format: '3 Inch',
  consolidated_kot_printer_ip: null,
  counter_foil_with_invoice: false,
  currency_fraction_name: null,
  currency_name: null,
  enable_table_selection_pos: false,
  invoice_format: '3 Inch Image',
  invoice_no_next: 1,
  invoice_no_prefix: '2024/T1/',
  kitchen_kot_format: '3 Inch',
  kot_with_invoice_printer_ip: null,
  loyalty_point_conversion_rate: 1,
  order_types: null,
  order_with_full_payment_only: true,
  price_with_tax_in_invoice: true,
  print_invoice_on_payment_update: false,
  print_total_save_on_invoice: false,
  printer_type: 'USB',
  sales_sync_platform: null,
  sales_sync_token: null,
  sales_webhook_url: null,
  salesperson_login: false,
  shop_logo: null,
  show_mrp_column_on_invoice: false,
  show_payment_mode_on_invoice: true,
  show_payment_page: true,
  skip_payment_page_on_invoice: false,
  stock_availability_check_for_sale: false,
  tax_preference: 'Excluding Tax',
  tax_region: 'India',
  token_no_next: null,
  token_no_prefix: null,
};

export const SHOP_SETTINGS = {
  other: {
    ...DEFAULT_SHOP_SETTINGS,
  },
  fine_dining: {
    ...DEFAULT_SHOP_SETTINGS,
    order_with_full_payment_only: false,
    enable_table_selection_pos: true,
  },
  pre_paid: {
    ...DEFAULT_SHOP_SETTINGS,
  },
  mini_mart: {
    ...DEFAULT_SHOP_SETTINGS,
    print_total_save_on_invoice: true,
    show_payment_mode_on_invoice: true,
    show_mrp_column_on_invoice: true,
  },
  delivery: {
    ...DEFAULT_SHOP_SETTINGS,
    order_with_full_payment_only: false,
  },
  take_away: {
    ...DEFAULT_SHOP_SETTINGS,
  },
};

export const SHOP_CATEGORIES = [{ restaurent: 'Restaurent' }, { supermarket: 'Supermarket' }, { other: 'Other' }];

export const SHOP_SUB_CATEGORIES = {
  restaurent: [{ 'fine-dining': 'Fine Dining' }, { 'pre-paid': 'Pre-paid' }, { other: 'Other' }],
  supermarket: [{ 'mini mart': 'Mini Mart' }, { delivery: 'Delivery' }, { other: 'Other' }],
  other: [{ 'take-away': 'Take Away' }, { delivery: 'Delivery' }, { other: 'Other' }],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const SelectFilterStyle = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SANOFT_WEB_URLS = {
  PRIVECY_POLICY: 'https://www.sanoft.com/privacy-policy',
  TERMS: 'https://www.sanoft.com/terms-and-conditions',
  REFUND_POLICY: 'https://www.sanoft.com/refund-policy',
  CONTACT_US: 'https://www.sanoft.com/#contact-us',
};

export const DEFAULT_COUNTRY_CODE = 'IN';

export const THEME_SANOFT = 'sanoft';

export const ITEM_TYPES = {
  INVENTORY: 'INVENTORY',
  COMBO: 'COMBO',
};

export const REGISTRATION_TYPE = {
  NEW: 'new-shop',
  TRIAL: 'trial-shop',
  RENEWAL: 'renew-shop',
};

export const REGISTRATION_CHANNEL = {
  ONLINE: 'online',
  KEY: 'key',
  ADMIN: 'admin',
};
