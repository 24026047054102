import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { AlertMessagesContext } from 'react-alert-messages';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import VendorServices from '../../services/VendorServices';
import { DialogActions } from '@mui/material';

export default function NewVendorDialog({ onSuccess, onClose }) {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState('');
  const [balance, setBalance] = useState();
  const [address, setAddress] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const createNewVendor = async () => {
    const data = {
      name,
      mobile,
      code,
      balance,
      address,
    };
    setLoadingIndicator(true);
    try {
      await VendorServices.createVendor(data);
      postAlertMessage({
        text: 'Vendor created successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({
        text: err.message,
        type: 'failed',
      });
    }
    setLoadingIndicator(false);
    onSuccess();
  };

  return (
    <div className={styles.popUp} style={{ zIndex: 99999999 }}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create New Vendor</h2>
          <CloseIcon onClick={onClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}> Vendor Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Mobile</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Mobile"
                variant="outlined"
                size="small"
                type="number"
                className={styles.numberInput}
                name="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Code</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Code"
                variant="outlined"
                size="small"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Balance</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Balance"
                variant="outlined"
                size="small"
                type="number"
                className={styles.numberInput}
                name="balance"
                value={balance}
                onChange={(e) => setBalance(Number(e.target.value))}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Address</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Address"
                variant="outlined"
                size="small"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                multiline
                maxRows={4}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={onClose}
            >
              close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={
                name === '' || mobile === '' || code === '' || loadingIndicator
              }
              onClick={createNewVendor}
            >
              Create Vendor
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
