import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import React, { useState, useContext, useEffect } from 'react';
import { AlertMessagesContext } from 'react-alert-messages';
import { ShopContext } from '../../../Context/ShopContext';
import { MEASURMENT_UNITS } from '../../../const';
import DataTable from '../../utils/DataTable/index';
import ShopSettingsService from '../../../services/ShopSettingsService';
import { Button } from '@mui/material';

function Units() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop, setShop } = useContext(ShopContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [, setShopUnits] = useState('');
  const [units, setUnits] = useState(null);

  const updateUnits = (symbol) => {
    setShopUnits((prevShopUnits) => {
      let unitsArray = prevShopUnits?.split(',') || [];

      if (unitsArray.includes(symbol)) {
        unitsArray = unitsArray.filter((item) => item !== symbol);
      } else {
        unitsArray.push(symbol);
      }

      const updatedUnits = unitsArray.join(',');

      updateShopUnitsHandler({ units: updatedUnits });

      return updatedUnits;
    });
  };

  const updateShopUnitsHandler = async (data) => {
    setLoadingIndicator(true);
    try {
      const resp = await ShopSettingsService.updateShopSettings(data);
      setShop(prevShop => ({
        ...prevShop,
        ...resp,
      }));
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'fail' });
    }
    setLoadingIndicator(false);
  };

  const headerData = [
    {
      label: 'Unit',
      id: 'unit',
      type: 'text',
    },
    {
      label: 'Symbol',
      id: 'symbol',
      type: 'text',
    },
    {
      label: 'Status',
      id: 'status',
      type: 'text',
    },
    {
      id: 'deleteBtn',
      type: 'callback',
      align: 'right',
      viewRender: (obj) => (
        <Button
          onClick={(e) => {
            updateUnits(obj.symbol);
            e.stopPropagation();
          }}
          variant="contained"
          size="small"
          color="primary"
        >
          {obj.status === 'Active' ? 'Deactivate' : 'Activate'}
        </Button>
      ),
    },
  ];

  const updatedMeasurementUnits = (updatedUnits) => {
    const statusUpdatedUnits = MEASURMENT_UNITS.map((unit) => ({
      ...unit,
      status: (updatedUnits.split(',') || []).includes(unit.symbol) ? 'Active' : 'Deactive',
    }));
    setUnits(statusUpdatedUnits);
    setShopUnits(updatedUnits);
  };

  useEffect(() => {
    if (!shop) return;
    updatedMeasurementUnits(shop?.units);
  }, [shop]);

  return (
    <div className={styles.contentLayout}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.settingsGrid}>
        <div className={styles.BillHeaderDiv}>
          <span className={styles.pageHead}>Manage Units</span>
        </div>
        {shop && units && <DataTable columns={headerData} rows={units} />}
      </div>
    </div>
  );
}

export default Units;
