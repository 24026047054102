import BackendService from './BackendService';

async function getPaymentModeSummaryReport(data = {}) {
  return BackendService.postData('/reports/payment-mode-summary/', data);
}

const PaymentModeSummaryReportService = {
  getPaymentModeSummaryReport,
};

export default PaymentModeSummaryReportService;
