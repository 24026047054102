import React, { useState } from 'react';
import { Button, DialogActions, Grid, TextField } from '@mui/material';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';

export default function PurchaseAdditionalDetailsPopup({ handleClose, setPurchaseData, purchaseData, addon }) {
  const [data, setData] = useState(purchaseData?.add_ons.find((add_on) => add_on.key === addon.key) || {});

  const handleAddDetails = () => {
    setPurchaseData((prevPurchase) => {
      const existingIndex = prevPurchase.add_ons.findIndex((add_on) => add_on.key === addon.key);

      if (existingIndex !== -1) {
        const updatedAddOns = [...prevPurchase.add_ons];
        updatedAddOns[existingIndex] = {
          ...updatedAddOns[existingIndex],
          ...data,
        };
        return { ...prevPurchase, add_ons: updatedAddOns };
      } else {
        return { ...prevPurchase };
      }
    });
    handleClose();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Set additional details for {addon.name.toUpperCase()}</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center" rowSpacing={1.5}>
            <Grid xs={12}>
              <p className={styles.subText}>{addon?.config?.note}</p>
            </Grid>
            {(addon?.config?.fields || []).map((field) => (
              <>
                <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
                  <h3 className={styles.inputLabel}>{field?.label}</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={styles.numberInput}
                    type="text"
                    value={data[field.field]}
                    onChange={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        [field.field]: e.target.value,
                      }));
                    }}
                  />
                </Grid>
              </>
            ))}
          </Grid>
          <DialogActions>
            <Button variant="contained" color="primary" size="small" onClick={handleAddDetails}>
              submit
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
