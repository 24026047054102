import { Alert, AlertTitle } from '@material-ui/lab';
import { useContext } from 'react';
import { ThemeContext } from '../../../Context/ThemeContext';
// import { Link } from 'react-router-dom';
import React from 'react';
export default function RestrictionInfo(props) {
  const { title, content } = props;
  const { theme } = useContext(ThemeContext);
  return (
    <Alert severity="warning" style={{ margin: '10px 0' }}>
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
      {content} -{' '}
      <strong>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://api.whatsapp.com/send?phone=${theme.variable.phone}&text=Hey%20${theme.variable.name},%20Upgrade%20Subscription`}
        >
          Contact Us
        </a>
      </strong>
    </Alert>
  );
}
