import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import ShopService from '../../services/ShopService';
import { STORAGE_KEYS } from '../../const';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';

export default function SelectShopsDialog(props) {
  const { onClose, onShopsSelected, message } = props;
  // TODO - Filter shops having permission only

  const [shops, setShops] = useState([]);
  const [isCustomiseShop, setIsCustomiseShop] = useState(false);
  const [selectedShopIds, setSelectedShopIds] = useState([]);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    async function getShops() {
      const shops = await ShopService.getShops();
      setShops(shops);
      setSelectedShopIds(shops.map((shop) => shop.id));
    }
    getShops().then();
  }, []);

  const handleShopSelection = (event) => {
    const shopId = Number(event.target.value);
    if (event.target.checked) {
      const shopIds = [...selectedShopIds, shopId];
      setSelectedShopIds(shopIds);
      return;
    }

    if (selectedShopIds.includes(shopId)) {
      const shopIds = selectedShopIds.filter((sId) => sId !== shopId);
      setSelectedShopIds(shopIds);
    }
  };

  const handleCustomiseSelection = (event) => {
    const checked = event.target.checked;
    setIsCustomiseShop(checked);
    setSelectedShopIds(checked ? [] : shops.map((shop) => shop.id));
  };

  const handleYes = () => {
    handleConfirmation(selectedShopIds);
  };
  const handleNo = () => {
    handleConfirmation();
  };

  const handleConfirmation = (shopIds) => {
    if (dontShowAgain) {
      localStorage.setItem(
        STORAGE_KEYS.DONT_SHOW_OTHER_SHOPS_ON_ITEM_EDIT,
        String(true)
      );
    }
    onShopsSelected(shopIds);
  };

  return (
    <Dialog open={true}>
      <div className={styles.headSec}>
        <h2 className={styles.editTitle}>Edit category</h2>
        <CloseIcon onClick={onClose} />
      </div>
      <DialogContent>
        <Typography sx={{ fontSize: 15 }}>{message}</Typography>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={handleCustomiseSelection}
              color="primary"
            />
          }
          label={
            <Typography sx={{ fontSize: 14 }}>
              Customise shops to update
            </Typography>
          }
        />
        {isCustomiseShop && (
          <div className={styles.inputSecAllEdit}>
            <Grid container alignItems="center">
              {shops.map((shop) => (
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedShopIds.includes(shop.id)}
                        value={shop.id}
                        onChange={handleShopSelection}
                        color="primary"
                      />
                    }
                    label={shop.shop_name}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={(event) => setDontShowAgain(event.target.checked)}
                />
              }
              label={
                <Typography sx={{ fontSize: 12 }}>
                  Don't show this again
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <Grid container>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className={styles.closeBtn}
                onClick={handleNo}
              >
                No, current shop only
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{ backgroundColor: '#00a65a' }}
                disabled={selectedShopIds.length < 1}
                onClick={handleYes}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
