import React from 'react';
import styles from './styles.module.css';
import { Backdrop } from '@material-ui/core';

function Grayout({open}) {
  return (
    <Backdrop
      open={open === true}
      className={styles.backdrop}
    ></Backdrop>
  );
}

export default Grayout;
