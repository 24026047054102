import BackendService from './BackendService';

async function getStockReport(data = {}, params) {
  return BackendService.post('/stock_reports/', data, {}, params);
}

const StockReportService = {
  getStockReport,
};

export default StockReportService;
