import BackendService from './BackendService';

async function getStockDamageHistory(params) {
  return BackendService.getData('/items/stock/damage/', {}, params);
}

async function getStockDamageHistoryExcel(params) {
  return BackendService.get(
    '/items/stock/damage/export_excel/',
    {},
    params,
    'arraybuffer'
  );
}

async function createStockDamage(data) {
  return BackendService.postData('/items/stock/damage/', data);
}
async function deleteStockDamageHistory(id) {
  return BackendService.destroy(`/items/stock/damage/${id}/`, '');
}
const StockDamageService = {
  getStockDamageHistory,
  getStockDamageHistoryExcel,
  createStockDamage,
  deleteStockDamageHistory,
};

export default StockDamageService;
