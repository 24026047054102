import React, { createContext, useState, useMemo } from 'react';
import { THEME_CONFIG } from '../const';

export const ThemeContext = createContext();

export default function ThemeProvider(props) {
  const themeConfig = useMemo(() => THEME_CONFIG[window.location.host] ?? THEME_CONFIG['default'], []);

  const [theme] = useState(themeConfig);
  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
