import { MenuItem } from '@material-ui/core';
import { styled } from '@mui/material/styles';

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: '#56a2e2',
  color: 'white',
  border: 'none',
  padding: '8px',
  fontSize: '17px',
  fontWeight: '500',
  transition: '0.5s ease-in-out',
  '&:hover': {
    backgroundColor: '#539ddbcc',
  }
}));

export default CustomMenuItem;
