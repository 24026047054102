import React, { createContext, useEffect, useState } from 'react';
import ShopSettingsService from '../services/ShopSettingsService';
import NotificationsService from '../services/Notifications';
import NotificationsDialog from '../components/popups/NotificationsDialog';

export const ShopContext = createContext();

function ShopProvider(props) {
  const [shop, setShop] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const loadShopProfile = async () => {
    const res = await ShopSettingsService.loadShopProfile();
    setShop(res);
    if (res?.shop_name) {
      window.__insp.push(
        ['identify', res.shop_name],
        [
          'tagSession',
          {
            shop_id: res.id,
            shop: res.shop_name,
            phone: res.mobile_no,
            subscriptions: res.subscription.name,
            dealer_id: res.dealer_id,
          },
        ]
      );
    }
  };

  const loadNotifications = async () => {
    const res = await NotificationsService.getNotifications();
    setNotifications(res);
  };

  const handleCloseNotification = async (notification = selectedNotification) => {
    setSelectedNotification(null);
    setNotifications((prevNotifications) =>
      prevNotifications.map((notif) => (notif.id === notification.id ? { ...notif, read: true } : notif))
    );
    await NotificationsService.markAsReadNotification(notification?.id);
  };

  useEffect(() => {
    loadShopProfile();
    loadNotifications();
  }, []);
  return (
    <ShopContext.Provider
      value={{
        shop,
        notifications,
        setShop,
        loadShopProfile,
        handleCloseNotification,
        setSelectedNotification,
      }}
    >
      {props.children}
      <ShopManagement selectedNotification={selectedNotification} handleCloseNotification={handleCloseNotification} />
    </ShopContext.Provider>
  );
}

function ShopManagement({ selectedNotification, handleCloseNotification }) {
  return (
    <>
      {selectedNotification && (
        <NotificationsDialog handleCloseNotification={handleCloseNotification} notification={selectedNotification} />
      )}
    </>
  );
}

export default ShopProvider;
