import React, { useState } from 'react';
import styles from './styles.module.css';
import { Button, Grid, TextField, DialogActions } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function EditPaymentModeDialog(props) {
  const { handleClose, paymentMode, updatePaymentModes } = props;
  const [name, setName] = useState(paymentMode.name);

  const updatePaymentModeName = () => {
    updatePaymentModes(paymentMode, { name });
    handleClose()
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit payment mode</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} style={{ paddingTop: 5 }} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Payment mode"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose} className={styles.closeBtn}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={name === ''} onClick={updatePaymentModeName}>
              Update
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}

export default EditPaymentModeDialog;
