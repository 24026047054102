import React from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Typography } from '@mui/material';
import shortDateDiff from 'short-date-diff';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../const';

function NotificationsDialog({ handleCloseNotification, notification }) {
  const history = useHistory();

  const getNotificationRedirectionLink = (notification) => {
    if (notification?.notification_type?.startsWith('SALES_LIMIT_REACHED')) {
      return (
        <>
          Click here to{' '}
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleCloseNotification(notification);
              history.push(ROUTES.ADD_ONS);
            }}
            className={styles.link}
          >
            purchase more sales.
          </span>
        </>
      );
    }
    return null;
  };

  return (
    <div
      className={styles.popUp}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={styles.contentWrapper}
        style={{ minWidth: 380, maxWidth: 700 }}
      >
        <div className={styles.headSec} style={{ height: 'fit-content' }}>
          <span className={styles.messageHead}>
            {notification.title ?? notification.message}
            <Typography variant="caption" color="gray" pl={1}>
              {`${shortDateDiff(
                new Date(notification.created_at),
                new Date()
              )} ago`}
            </Typography>
          </span>
          <CloseIcon
            onClick={(e) => {
              e.stopPropagation();
              handleCloseNotification(notification);
            }}
          />
        </div>
        <div className={styles.inputSec}>
          <Grid container p={1} rowSpacing={1.5}>
            <Grid item xs={12}>
              <span className={styles.message}>
                {notification.message}{' '}
                {getNotificationRedirectionLink(notification)}
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default NotificationsDialog;
