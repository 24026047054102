const styles = {
  root: {
    background: '#0008',
    color: '#FFF',
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: 20,
    cursor: 'pointer',
  },
};

export default function UpgradeToPremiumMessage(props) {
  return (
    <div style={styles.root}>
      <span
      // onClick={() => {
      //   window.open('/subscription-plans', '_blank');
      // }}
      >
        Profit / Loss calculation is not available
      </span>
    </div>
  );
}
