import logo from './logo.png';
import styles from './styles.module.css';
import React from 'react';
import { Container, Grid, Hidden } from '@material-ui/core';
export default function Footer() {
  return (
    <Grid className={styles.footerMainDiv}>
      <Container>
        <Grid container className={styles.upperDiv}>
          <Grid item className={styles.companyDetailDiv}>
            <Grid>
              <img alt="" src={logo} width="95" height="100" />
            </Grid>

            <Hidden xsDown>
              <Grid style={{ marginLeft: 20 }}>
                <div className={styles.footerItem}>
                  <h4>Sanoft Technologies Pvt Ltd.</h4>
                  <p>
                    2nd Floor, City Center,
                    <br />
                    Near CSB Bank, Areekode,
                    <br />
                    Kerala, India - 673639.
                    <br />
                    Phone : +91 9746693296
                    <br />
                  </p>
                </div>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item className={styles.companyDetailDiv}>
            <Grid>
              <div className={styles.footerItem}>
                <h5 style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.sanoft.com/', '_blank')}>
                  About us
                </h5>
              </div>
              <div className={styles.footerItem}>
                <h5 style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.sanoft.com/', '_blank')}>
                  Contact us
                </h5>
              </div>
              <div className={styles.footerItem}>
                <h5
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://drive.google.com/file/d/1ZCa7_afvQS6iFFDTyHIvB4h8Gqq9AzBN/view', '_blank')
                  }
                >
                  Privacy policy
                </h5>
              </div>
              <div className={styles.footerItem}>
                <h5
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://drive.google.com/file/d/1ZCa7_afvQS6iFFDTyHIvB4h8Gqq9AzBN/view', '_blank')
                  }
                >
                  Return policy
                </h5>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.lowerDiv}>
          <h6 className={styles.copyRight} style={{ color: '#FFF', textAlign: 'center' }}>
            Copyright © 2021{' '}
            <span
              style={{ color: '#272C86', cursor: 'pointer' }}
              onClick={() => window.open('https://www.sanoft.com/', '_blank')}
            >
              Sanoft{' '}
            </span>
            | All Rights Reserved. | Powered By{' '}
            <span
              style={{ color: '#272C86', cursor: 'pointer' }}
              onClick={() => window.open('https://www.sanoft.com/', '_blank')}
            >
              Sanoft Technologies Pvt Ltd.
            </span>
          </h6>
        </Grid>
      </Container>
    </Grid>
  );
}
