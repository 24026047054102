import styles from './styles.module.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { STORAGE_KEYS, ROUTES, THEME_SANOFT } from '../../../const';
import { Link } from 'react-router-dom';
import ShopService from '../../../services/ShopService';
import AccountSwitchDialog from '../../popups/AccountSwitchDialog';
import AddShopDialog from '../../popups/AddShopDialog';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import ShopsHelper from '../../../helpers/ShopsHelper';
import SubscriptionEndingSoonAlert from '../SubscriptionEndingSoonAlert';
import MenuIcon from '@mui/icons-material/Menu';
import { selectedTabContext } from '../../../Context/SelectedTabContext';
import { ThemeContext } from '../../../Context/ThemeContext';
import { Badge, Tooltip, ClickAwayListener, Paper, Grow, Popper, Grid, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import shortDateDiff from 'short-date-diff';
import RedirectToWhatsapp from '../RedirectToWhatsapp';

export default function TitleBar() {
  const { user, loadUserProfile } = useContext(UserContext);
  const { shop, loadShopProfile, notifications, handleCloseNotification, setNotificationDetails } =
    useContext(ShopContext);
  const history = useHistory();
  const { theme } = useContext(ThemeContext);
  const { isMenuOpen, setMenuOpen } = useContext(selectedTabContext);
  const [shops, setShops] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEI, setAnchorEI] = useState(null);
  const [addShopTab, setAddShopTab] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const date = DateTimeHelpers.convertDateToDMY(new Date());
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setShowNotification((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setShowNotification(false);
  };

  const paperProps = {
    elevation: 0,
    sx: {
      position: 'relative',
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      maxWidth: '550px',
      maxHeight: '600px',
      overflowY: 'auto',
    },
  };

  useEffect(() => {
    async function setSelectedShop() {
      const shops = await ShopService.getShops();
      setShops(shops);

      const selectedShopIdStr = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
      const selectedShopId = parseInt(selectedShopIdStr);
      const shopFromSelectedId = isNaN(selectedShopId) ? shops[0] : shops.find((shop) => shop.id === selectedShopId);
      const selectedShop = shopFromSelectedId || shops[0] || { id: -1, shop_name: 'unknown' };
      ShopsHelper.saveSelectedShopDetails(selectedShop);
      loadUserProfile();
      loadShopProfile();
    }
    setSelectedShop();
  }, [loadShopProfile, loadUserProfile]);

  const handleAccountView = (e) => {
    setAnchorEI(e.currentTarget);
    return setIsOpen(!isOpen);
  };
  const updateShops = async () => {
    const res = await ShopService.getShops();
    setShops(res);
  };
  const toggleAddShopDialog = () => setAddShopTab(!addShopTab);

  const getNotificationRedirectionLink = (notification) => {
    if (theme.name === THEME_SANOFT) {
      return (
        <>
          Click here to{' '}
          <span
            onClick={(e) => {
              e.stopPropagation();
              history.push(notification?.link);
            }}
            className={styles.link}
          >
            {notification?.action}
          </span>
        </>
      );
    } else {
      return (
        <>
          Click here to <RedirectToWhatsapp message={notification?.action} />
        </>
      );
    }
  };

  useEffect(() => {
    setUnreadNotificationCount(notifications.filter((notification) => !notification.read).length);
  }, [notifications]);

  return (
    <>
      <div style={{ backgroundColor: theme.color.secondary }} className={styles.titleBarLayout}>
        <div className={`${styles.flexChildCenter} ${styles.appHeading}`}>
          <div className={styles.logoWrapper}>
            <Link to={ROUTES.DASHBOARD} style={{ textDecorationColor: 'transparent' }}>
              <span style={{ color: theme.color.tertiary }} className={styles.logoTextColor}>
                {theme.variable.name}
              </span>
              <span className={styles.logoText}>POS</span>
            </Link>
          </div>
          <div className={styles.toggleMenuButton}>
            <MenuIcon
              style={{ color: 'white', cursor: 'pointer' }}
              onClick={() => {
                setMenuOpen(!isMenuOpen);
              }}
            />
          </div>
        </div>
        <div className={styles.profileWrapper}>
          <div className={styles.flexChildCenter} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.profileNameLayout}>
              <div className={styles.profileTextMain}>
                Welcome, {user && user.first_name} {user && user.last_name},
              </div>

              <div className={styles.profileTextSub}>{date}</div>
            </div>
          </div>
          <div className={styles.flexChildCenter} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SubscriptionEndingSoonAlert shop={shop} notifications={notifications} />
          </div>
          {addShopTab && <AddShopDialog toggleAddTab={toggleAddShopDialog} updateShops={updateShops} />}
          <div className={styles.titleBarEnd}>
            <Tooltip title="Notifications">
              <Badge
                badgeContent={unreadNotificationCount}
                color="primary"
                className={styles.notification}
                ref={anchorRef}
                onClick={handleToggle}
              >
                <NotificationsIcon style={{ color: '#c1c4c7', fontSize: 28 }} />
              </Badge>
            </Tooltip>
            <Popper
              open={showNotification}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-end"
              transition
              disablePortal
              style={{ zIndex: 2 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-end' ? 'right top' : 'left bottom',
                  }}
                >
                  <Paper {...paperProps}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Grid container sx={{ p: 1 }}>
                        <Grid item xs={12} className={styles.header}>
                          <span>Notifications</span>
                        </Grid>
                        {notifications.length ? (
                          notifications.map((notification, index) => (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              className={styles.messageList}
                              onClick={(e) => {
                                setNotificationDetails(notification);
                              }}
                            >
                              <Grid className={styles.message}>
                                <p>
                                  {notification.title ?? notification.message}
                                  <Typography variant="caption" color="gray" pl={1}>
                                    {`${shortDateDiff(new Date(notification.created_at), new Date())} ago`}
                                  </Typography>
                                </p>
                                <span>
                                  {notification.message}{' '}
                                  {notification?.link && getNotificationRedirectionLink(notification)}
                                </span>
                              </Grid>
                              <Grid display="flex" justifyContent="center" p={1} style={{ width: 40 }}>
                                {!notification.read && (
                                  <Tooltip
                                    PopperProps={{
                                      style: { zIndex: 3300 },
                                    }}
                                    title="Mark as read"
                                  >
                                    <div
                                      className={styles.messageIndicator}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCloseNotification(notification);
                                      }}
                                    >
                                      <div></div>
                                    </div>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12} textAlign="center" pt={4} pb={4}>
                            <span className={styles.defaultText}>There is no notifications.</span>
                          </Grid>
                        )}
                      </Grid>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <div className={styles.actionButtons}>
              <div className={styles.profileSec} onClick={handleAccountView}>
                <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                  <Avatar style={{ backgroundColor: theme.color.header }}>
                    <PersonIcon style={{ color: '#fff', fontSize: '25px' }} />
                  </Avatar>
                </span>
                <AccountSwitchDialog
                  accounts={shops}
                  toggleAddShopDialog={toggleAddShopDialog}
                  anchorEI={anchorEI}
                  isOpen={isOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
