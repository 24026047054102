import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import ListDialog from '../../popups/ListDialog';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import { SUBSCRIPTION_TYPE } from '../../../const';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { ShopContext } from '../../../Context/ShopContext';
import AlertHelper from '../../../helpers/AlertHelper';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import StockReportService from '../../../services/StockReportService';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import CsvHelper from '../../../helpers/CsvHelper';
import { AlertMessagesContext } from 'react-alert-messages';
import { saveAs } from 'file-saver';
import { Paper, Pagination, Grid, TablePagination } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';

function StockReport() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [viewData, setViewData] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [isEnableSearchRefreshButton, setIsEnableSearchRefreshButton] = useState(false);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    fromDate,
    toDate,
    filterText,
  });

  const subscriptionType = shop?.subscription?.type;

  const CSV_COLUMNS = {
    'item.plu': {
      title: 'PLU',
      getValue: (item) => item?.item?.plu,
    },
    'item.name': {
      title: 'Item Name',
      getValue: (item) => item?.item?.name,
    },
    opening_stock: 'Opening Stock',
    inbound: 'Inbound',
    outbound: 'Outbound',
    closing_stock: 'Closing Stock',
    stock_sales_value: {
      title: 'Stock Value',
      getValue: (item) => item?.item?.price * item?.item?.stock,
    },
  };

  const loadStockReports = async ({
    _fromDate = fromDate,
    _toDate = toDate,
    _offset = offset,
    _limit = limit,
  } = {}) => {
    setLoadingIndicator(true);
    try {
      const data = {
        date_start: DateTimeHelpers.convertDateToIsoDMY(_fromDate),
        date_end: DateTimeHelpers.convertDateToIsoDMY(_toDate),
      };
      const resp = await StockReportService.getStockReport(data, {
        offset: _offset,
        limit: _limit,
        search: filterText,
      });
      const sortedData = sortByAsending(resp?.data);
      setStocks(sortedData);
      setCount(resp?.count);
      setIsEnableSearchRefreshButton(false);
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
    setLoadingIndicator(false);
  };

  const sortByAsending = (data) => {
    return [...data].sort((a, b) => a['item']['plu'] - b['item']['plu']);
  };

  const generateStockReport = () => {
    loadStockReports();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      fromDate,
      toDate,
    });
  };

  const isAnyChangeOnStockReportFilters = useCallback(() => {
    return fromDate !== prevFilterData.fromDate || toDate !== prevFilterData.toDate;
  }, [fromDate, toDate, prevFilterData.fromDate, prevFilterData.toDate]);

  // const handleDateChange = async (from, to) => {
  //   setLoadingIndicator(true);
  //   setFromDate(from);
  //   setToDate(to);
  //   loadStockReports({ _fromDate: from, _toDate: to });
  // };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(stocks, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `items-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const viewDetailsHandler = (data) => {
    if (!data || !data.transactions.length) {
      return setViewData(data);
    }

    const transactions = [];
    let balance = data.opening_stock;
    for (const transaction of data.transactions) {
      balance += transaction.quantity;
      transactions.push({ ...transaction, balance });
    }

    const upadatedData = {
      ...data,
      transactions: [
        {
          type: 'Opening Stock',
          time: '  ',
          description: '',
          quantity: ' ',
          balance: data.opening_stock,
        },
        ...transactions,
        {
          type: 'Closing Stock',
          time: '  ',
          description: '',
          quantity: ' ',
          balance: data.closing_stock,
        },
      ],
    };

    setViewData(upadatedData);
  };
  const headerData = [
    {
      label: 'PLU',
      id: 'item.plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'item.name',
      type: 'text',
    },
    {
      label: 'Opening Stock',
      id: 'opening_stock',
      type: 'floatQuantity',
    },
    {
      label: 'Inbound',
      id: 'inbound',
      type: 'floatAmount',
    },
    {
      label: 'Outbound',
      id: 'outbound',
      type: 'floatAmount',
    },
    {
      label: 'Closing Stock',
      id: 'closing_stock',
      type: 'floatQuantity',
    },
    {
      label: 'Stock Sales Value',
      id: 'stock_sales_value',
      type: 'callback',
      viewRender: (entry) => {
        return ShopsHelper.getAmountFormatted(shop, entry.closing_stock * entry.item.price);
      },
    },
    {
      label: 'Stock actions',
      id: 'btnViewDetail',
      type: 'button',
      title: 'View Details',
      clickHandler: viewDetailsHandler,
    },
  ];
  const transactionHeader = [
    {
      label: 'Type',
      id: 'type',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'Date',
      type: 'callback',
      viewRender: (item) => {
        return item.time.split(' ')[1];
      },
    },
    {
      label: 'Time',
      id: 'time',
      type: 'callback',
      viewRender: (item) => {
        return item.time.split(' ')[0];
      },
    },
    {
      label: 'Details',
      id: 'description',
      type: 'text',
    },
    {
      label: 'Inbound',
      id: 'inbound',
      type: 'callback',
      viewRender: (item) => (item.quantity && item.quantity > 0 ? item.quantity : '-'),
    },
    {
      label: 'Outbound',
      id: 'outbound',
      type: 'callback',
      viewRender: (item) => (item.quantity && item.quantity < 0 ? (-1 * item.quantity) : '-'),
    },
    {
      label: 'Balance',
      id: 'balance',
      type: 'floatAmount',
    },
  ];

  const handleChangePage = (event, page) => {
    const newPage = page - 1;
    setPage(newPage);
    let offset = newPage * limit;
    setOffset(offset);
    loadStockReports({ _offset: offset });
  };

  const handleChangeRowsPerPage = (event) => {
    const _limit = +event.target.value;
    setLimit(_limit);
    loadStockReports({ _limit });
  };

  useEffect(() => {
    generateStockReport();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnStockReportFilters());
  }, [isAnyChangeOnStockReportFilters, isShowGenerateButton]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Stock<span className={styles.menuTitle}>Management</span>
        </h2>

        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <div style={{ justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Export As</label>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={styles.actionBtn}
              style={{ backgroundColor: '#00a65a' }}
              onClick={toCsvBtnPressed}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              CSV
            </Button>
          </div>
        )}
      </div>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get stock report options upgrade subscription to premium'}
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Stock Report</h2>
              </div>
              <div className={styles.filerInputSec}>
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                      setIsEnableSearchRefreshButton(true);
                    }}
                    className={styles.searchInput}
                    placeholder="search items"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={() => {
                      setOffset(0);
                      setPage(0);
                      loadStockReports({ _offset: 0 });
                    }}
                    disabled={!isEnableSearchRefreshButton}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      max={new Date()}
                      className={styles.dateBox}
                      value={fromDate}
                      onChange={(date) => {
                        setFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      max={new Date()}
                      value={toDate}
                      onChange={(date) => {
                        setToDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Generate Report</label>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={generateStockReport}
                    disabled={isShowGenerateButton === false}
                  >
                    <SyncIcon className={styles.actionBtnIcon} />
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {errorMsg && (
            <div className={styles.marginTop}>
              <Error title={errorMsg} />
            </div>
          )}
          {isShowGenerateButton === null ? (
            <Info
              title={'Set filters and click generate button'}
              content={
                'Stock report are generated based on the filters. Please set filters and click generate button to get Stock report.'
              }
            />
          ) : stocks && stocks.length ? (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <div className={styles.tableContainer}>
                <Grayout open={isShowGenerateButton} />
                <DataTable columns={headerData} rows={stocks} rowKey="item.id" />
              </div>
              <Grid container>
                <Grid item xs={6} display="flex" justifyContent="start">
                  <TablePagination
                    rowsPerPageOptions={[20, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      '& .MuiTablePagination-actions': {
                        display: 'none',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="end" pt={1}>
                  <Pagination
                    count={Math.ceil(count / limit)}
                    page={page + 1}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={1}
                  />
                </Grid>
              </Grid>
            </Paper>
          ) : (
            !loadingIndicator && (
              <Info
                title={'You have no stock to list'}
                content={"You haven't any stock to list with given properties"}
              />
            )
          )}
          {viewData && (
            <ListDialog
              toggleItemsTab={viewDetailsHandler}
              tableHeader={transactionHeader}
              rowData={viewData.transactions}
              title={viewData ? `Stock details of ${viewData.item.name}` : 'stock details'}
              emptyDataContent={'No transaction found for this item'}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(StockReport);
