import BackendService from './BackendService';

async function getItems(params) {
  const response = await getItemsResponse(params);
  return response?.data;
}
async function getItemsResponse(params) {
  return BackendService.get('/items/', {}, params);
}
async function getItemNames(data = {}) {
  return BackendService.getData('/items/name_list/', data);
}
async function updateItem(itemId, data = {}) {
  return BackendService.put(`/item/${itemId}/`, data);
}
async function addItem(data = {}) {
  return BackendService.postData('/items/', data);
}
async function getItemCategories(data = {}) {
  return BackendService.getData('/categories/', data);
}
async function addItemCategory(data = {}) {
  return BackendService.postData('/categories/', data);
}
async function uploadCategoryImage(data = {}, id) {
  return BackendService.postData(`/category/${id}/image/`, data);
}
async function uploadItemImage(data = {}, id) {
  return BackendService.postData(`/item/${id}/image/`, data);
}
async function editItemCategory(data = {}, id) {
  return BackendService.put(`/category/${id}/`, data);
}
async function updatePlu() {
  return BackendService.postData('/items/update_plu_text/', '');
}
async function updateItems(data) {
  return BackendService.postData('/items/batch/', data);
}
async function getItemAdvanced(id) {
  return BackendService.getData(`/items/${id}/advanced/`);
}
async function updateItemAdvanced(data = {}, id) {
  return BackendService.put(`/items/${id}/advanced/`, data);
}
async function getHighestPlu() {
  return BackendService.getData('/items/highest-plu/')
}
const ItemService = {
  getItems,
  getItemsResponse,
  getItemNames,
  updateItem,
  addItem,
  getItemCategories,
  addItemCategory,
  editItemCategory,
  uploadCategoryImage,
  uploadItemImage,
  updatePlu,
  updateItems,
  getItemAdvanced,
  updateItemAdvanced,
  getHighestPlu,
};

export default ItemService;
