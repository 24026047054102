import React from 'react';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { useState, useContext, useCallback } from 'react';
import { TextField, MenuItem, Button, CardMedia, FormControlLabel, Switch } from '@material-ui/core';
import { Grid } from '@mui/material';
import {
  TAX_PREFERENCES,
  TAX_REGIONS,
  SHOP_SUB_CATEGORIES,
  SHOP_CATEGORIES,
  CONFIGS,
  SHOP_SETTINGS,
} from '../../../const';
import { AlertMessagesContext } from 'react-alert-messages';
import ShopSettingsService from '../../../services/ShopSettingsService';
import { useEffect } from 'react';
import FeatureNotAvailableDialog from '../../popups/FeatureNotAvailableDialog';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import TerminalService from '../../../services/TerminalServices';
import SupportService from '../../../services/SupportService';
import { ThemeContext } from '../../../Context/ThemeContext';

function ShopSettings() {
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [data, setData] = useState({});
  const [featureNotAvailable, setFeatureNotAvailable] = useState(null);
  const [imageData, setImageData] = useState('');
  const [externalTokenKey, setExternalTokenKey] = useState('');
  const [updateTerminalSettingsDialog, setUpdateTerminalSettingsDialog] = useState(false);
  const [shopCategory, setShopCategory] = useState({});
  const [loadingMessage, setLoadingMessage] = useState('');
  const [supportEnabled, setSupportEnabled] = useState(false);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { theme } = useContext(ThemeContext);

  const closeDialogHandler = () => {
    setFeatureNotAvailable(null);
  };

  const uploadImage = (image) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        const logo = {
          image: reader.result,
        };
        await ShopSettingsService.uploadShopLogo(logo);
        resolve();
      };
    });
  };

  const removeShopLogo = () => {
    setImageData('');
    setData({ ...data, shop_logo: null });
  };

  const getShopSettings = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const resp = await ShopSettingsService.getShopSettings();
      setData(resp);
      setShopCategory({
        category: resp?.category,
        sub_category: resp?.sub_category,
      });
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [postAlertMessage]);

  const handleTerminalSettingsUpdate = async () => {
    setLoadingIndicator(true);
    try {
      const terminals = await TerminalService.getTerminals();
      await Promise.all(
        terminals.map(async (terminal) => {
          setLoadingMessage(`Updating setting of ${terminal.name}`);
          await TerminalService.updateTerminals(terminal.id, SHOP_SETTINGS[data?.sub_category]);
        })
      );
    } catch (error) {
      postAlertMessage({ text: 'Attempt fail', type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const updateShopSettings = async () => {
    setLoadingIndicator(true);
    try {
      const res = await ShopSettingsService.updateShopSettings(data);
      if (imageData.size < CONFIGS.LOGO_MAX_SIZE) {
        await uploadImage(imageData);
      }
      setData(res);
      postAlertMessage({
        text: 'Updated shop settings successfully',
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: 'Attempt fail', type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const isChangeShopCategory = useCallback(() => {
    return data?.category !== shopCategory?.category || data?.sub_category !== shopCategory?.sub_category;
  }, [data?.category, data?.sub_category, shopCategory?.category, shopCategory?.sub_category]);

  const loadExternalTokenKey = useCallback(async () => {
    setLoadingIndicator(true);
    const data = await ShopSettingsService.getExternalTokenKey();
    const externalTokenKey = data.ext_access_token;
    setExternalTokenKey(externalTokenKey);
    setLoadingIndicator(false);
  }, [setLoadingIndicator, setExternalTokenKey]);

  const resetExtTknBtnPressed = async () => {
    setLoadingIndicator(true);
    try {
      const data = await ShopSettingsService.resetExternalTokenKey();
      const externalTokenKey = data.ext_access_token;
      setExternalTokenKey(externalTokenKey);
      postAlertMessage({ text: 'Succesfully updated!', type: 'success' });
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const copyExternalToken = async () => {
    const copyText = document.getElementById('extTkn');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    postAlertMessage({ text: 'Copied external token', type: 'success' });
  };

  const handleChangeSupportMode = async (event) => {
    const { checked } = event.target;
    const data = await SupportService.changeSupport({
      flavor: theme.variable.name,
      enabled: checked,
    });
    setSupportEnabled(data);
  };

  const handleChangeMode = (event) => {
    const { name, checked } = event.target;
    const updatedData = {
      ...data,
      [name]: checked,
    };

    setData(updatedData);
  };

  const checkSupport = useCallback(async () => {
    const data = await SupportService.checkSupport(theme.variable.name);
    setSupportEnabled(data);
  }, [theme.variable.name]);

  const loadAllData = useCallback(async () => {
    loadExternalTokenKey();
    checkSupport();
    getShopSettings();
  }, [loadExternalTokenKey, checkSupport, getShopSettings]);

  useEffect(() => {
    loadAllData();
  }, [loadAllData]);

  return (
    <div className={styles.contentLayout}>
      <Loader isOpen={loadingIndicator} message={loadingMessage} />
      <div className={styles.settingsGrid}>
        <Grid container rowGap={1.5}>
          <Grid item container xs={12} columnSpacing={1} rowSpacing={1.5} className={styles.sessionContainer}>
            <Grid item xs={12} className={styles.subHeader}>
              <span>Shop Config</span>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Currency Name</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="barcodePrinterFormat"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({
                      ...data,
                      currency_name: event.target.value,
                    });
                  }}
                  value={data?.currency_name}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Currency Fraction Name</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="barcodePrinterFormat"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({
                      ...data,
                      currency_fraction_name: event.target.value,
                    });
                  }}
                  value={data?.currency_fraction_name}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Currency Symbol</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({
                      ...data,
                      currency_symbol: event.target.value,
                    });
                  }}
                  value={data?.currency_symbol}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Tax Preference</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  name="printerType"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({ ...data, tax_preference: event.target.value });
                  }}
                  value={data?.tax_preference || 'Excluding Tax'}
                >
                  {TAX_PREFERENCES.map((tax_preference) => {
                    let [key, value] = Object.entries(tax_preference)[0];
                    return (
                      <MenuItem key={key} value={value}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Tax Region</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  name="printerType"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({ ...data, tax_region: event.target.value });
                  }}
                  value={data?.tax_region || 'India'}
                >
                  {TAX_REGIONS.map((tax_region) => {
                    let [key, value] = Object.entries(tax_region)[0];
                    return (
                      <MenuItem key={key} value={value}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Stock Availability Check For Sale</span>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={data?.stock_availability_check_for_sale || false}
                      onChange={handleChangeMode}
                      name={'stock_availability_check_for_sale'}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6} lg={3}>
                <span className={styles.fieldTitle}>Shop Logo</span>
              </Grid>
              <Grid item xs={6} lg={9}>
                <div style={{ fontSize: 16, paddingBottom: 5 }}>150px * 150px size recommended</div>
                <CardMedia
                  image={imageData ? URL.createObjectURL(imageData) : data?.shop_logo}
                  className={styles.categoryImg}
                />
                {imageData && imageData.size > CONFIGS.LOGO_MAX_SIZE && (
                  <div className={styles.imageSizeWarning}>
                    *Image size exceeds maximum limit. Upload file size less than 200KB
                  </div>
                )}
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  id="upload"
                  onChange={(e) => {
                    setImageData(e.target.files[0]);
                  }}
                />
                <Button variant="contained" size="small" className={styles.uploadBtn}>
                  <label htmlFor="upload">{imageData || data?.shop_logo ? 'change' : 'select'}</label>
                </Button>
                {(imageData || data?.shop_logo) && (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ marginLeft: 15 }}
                    onClick={removeShopLogo}
                  >
                    delete
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles.subHeader}>
              <span>Loyalty Config</span>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Amount Spent For One Loyalty Point</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  name="barcodePrinterFormat"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({
                      ...data,
                      amount_spent_for_loyalty_point: event.target.value,
                    });
                  }}
                  value={data?.amount_spent_for_loyalty_point}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Loyalty Point Conversion Rate</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  name="barcodePrinterFormat"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({
                      ...data,
                      loyalty_point_conversion_rate: event.target.value,
                    });
                  }}
                  value={data?.loyalty_point_conversion_rate}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles.BillHeader} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                className={styles.button}
                disabled={loadingIndicator}
                onClick={updateShopSettings}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} columnSpacing={1} rowSpacing={1.5} className={styles.sessionContainer}>
            <Grid item xs={12} className={styles.subHeader}>
              <span>Shop Type</span>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Category</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  name="barcodePrinterFormat"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({
                      ...data,
                      category: event.target.value,
                    });
                  }}
                  value={data?.category}
                >
                  {SHOP_CATEGORIES.map((category) => {
                    let [key, value] = Object.entries(category)[0];
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Subcategory</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  name="barcodePrinterFormat"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setData({
                      ...data,
                      sub_category: event.target.value,
                    });
                  }}
                  value={data?.sub_category}
                >
                  {SHOP_SUB_CATEGORIES[data?.category || 'other'].map((sub_category) => {
                    let [key, value] = Object.entries(sub_category)[0];
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles.BillHeader} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                className={styles.button}
                disabled={!isChangeShopCategory}
                onClick={() => setUpdateTerminalSettingsDialog(true)}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.BillHeader}>
            <Grid item xs={6} lg={3}>
              <span className={styles.fieldTitle}>Enable Support</span>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={supportEnabled} onChange={handleChangeSupportMode} color="primary" />}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6} lg={3}>
              <span className={styles.fieldTitle}>External Token Key</span>
            </Grid>
            <Grid item xs={6} lg={9}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="extTkn"
                    name="externalTokenKey"
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    value={externalTokenKey}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ width: '100%' }}
                    onClick={copyExternalToken}
                    disabled={loadingIndicator}
                  >
                    Copy
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: '#00a65a', width: '100%' }}
                    onClick={resetExtTknBtnPressed}
                    disabled={loadingIndicator}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {featureNotAvailable && (
          <FeatureNotAvailableDialog closeHandler={closeDialogHandler} feature={featureNotAvailable} />
        )}
        {updateTerminalSettingsDialog && (
          <ConfirmPopup
            data={updateTerminalSettingsDialog}
            handleClose={() => setUpdateTerminalSettingsDialog(false)}
            handleOkay={handleTerminalSettingsUpdate}
            alertTitle="Change terminal settings"
            alertContent="Terminal settings updated for specific shop type and subtype. Enjoy optimized experience."
          />
        )}
      </div>
    </div>
  );
}

export default ShopSettings;
