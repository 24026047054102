import BackendService from './BackendService';

async function getSalesPersons() {
  return BackendService.getData('/sales-persons/');
}
async function createSalesPersons(data) {
  return BackendService.postData('/sales-persons/', data);
}
async function updateSalesPersons(id, data = {}) {
  return BackendService.put(`/sales-persons/${id}/`, data);
}
async function deleteSalesPersons(id) {
  return BackendService.destroy(`/sales-persons/${id}/`, '');
}

const SalesPersonService = {
  getSalesPersons,
  createSalesPersons,
  updateSalesPersons,
  deleteSalesPersons,
};

export default SalesPersonService;
