import React, { useState, useEffect, useContext } from 'react';
import {
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material';
import styles from './styles.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterList from '../FilterList/FilterList';
import { getFilteredColumns } from '../FilteredColumns';
import { ShopContext } from '../../../Context/ShopContext';
import ShopsHelper from '../../../helpers/ShopsHelper';

export default function DataTable(props) {
  const { shop } = useContext(ShopContext);
  const rows = props.rows || [];
  const rowKey = props.rowKey || 'id';
  const columns = props.columns || [];
  const filterId = props.columnFilterId ?? null;
  const optionalColumns = props.optionalColumns || null;
  const [filteredColumns, setFilteredColumns] = useState(props.columns || []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openTableFilter = Boolean(anchorEl);
  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const getValueForCell = (data, key) => {
    if (key.includes('.')) {
      let value = data;
      key.split('.').forEach((segment) => {
        if (value) {
          value = value[segment];
        } else {
          return undefined;
        }
      });
      return value;
    } else if (key.includes('-')) {
      return key
        .split('-')
        .map((k) => data[k])
        .join('-');
    } else {
      return data[key];
    }
  };

  const getRowStyle = (row) => {
    if (props.deactiveRow && row?.is_active === false) {
      return { backgroundColor: 'rgba(245, 39, 39, 0.2)' };
    }
    return props.rowStyle?.(row) ?? {};
  };

  const getRowBlockMessage = (row) => {
    return props.rowBlockMessage?.(row) ?? null;
  };

  const handleTableFilter = (col, value) => {
    let filterData;
    const prevfilterData = localStorage.getItem(filterId);
    if (prevfilterData) {
      filterData = JSON.parse(prevfilterData);
      filterData[col.id] = value;
    } else {
      filterData = {
        [col.id]: value,
      };
    }
    localStorage.setItem(filterId, JSON.stringify(filterData));
    readFilteredColumns();
  };

  const readFilteredColumns = () => {
    if (!filterId) return;
    const filteredColumns = getFilteredColumns(columns, optionalColumns, filterId);
    setFilteredColumns(filteredColumns);
  };

  useEffect(() => {
    readFilteredColumns();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <TableContainer style={{ marginTop: '4px' }} component={Paper}>
        <Table aria-label="simple table" size="medium">
          <TableHead>
            <TableRow>
              {filteredColumns.map((column) => (
                <TableCell
                  key={column.id}
                  className={`${styles.columnCell} ${column.cssClassHeader ?? ''}`}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
              {props?.columnFilterId && (
                <>
                  <TableCell align={'right'} className={styles.tableFilter}>
                    <Tooltip title="filter columns">
                      <IconButton onClick={handleClickFilter} size="small">
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={getValueForCell(row, rowKey)}
                style={getRowStyle(row)}
                onClick={() => props.rowClickListener?.(row)}
                className={styles.tableRow}
              >
                {filteredColumns.map((column) => {
                  const rawValue = getValueForCell(row, column.id);
                  let value = '-dff';
                  try {
                    switch (column.type) {
                      case 'text':
                        value = String(rawValue ?? '-');
                        break;
                      case 'floatQuantity':
                        value = isNaN(rawValue) ? rawValue : rawValue.toFixed(3);
                        break;
                      case 'floatAmount':
                        value =
                          !rawValue || isNaN(rawValue)
                            ? String(rawValue ?? '-')
                            : ShopsHelper.getAmountFormatted(shop, rawValue);
                        break;
                      case 'callback':
                        value = column.viewRender(row);
                        break;
                      case 'button':
                        value = (
                          <Button
                            onClick={(e) => {
                              column.clickHandler(row);
                              e.stopPropagation();
                            }}
                            variant="contained"
                            size="small"
                            color={column?.color ?? 'secondary'}
                          >
                            {column.title || 'Button'}
                          </Button>
                        );
                        break;
                      case 'image':
                        value = <CardMedia size="small" image={row[column.id]} className={styles.image} />;
                        break;
                      default:
                        value = `invalid-type '${column.type}'`;
                    }
                  } catch (error) {
                    value = error.message;
                    console.error(error);
                  }
                  return (
                    <TableCell
                      key={column.id}
                      style={column.type === 'button' ? { maxWidth: '10px' } : {}}
                      align={column.align}
                      className={column.cssClass ?? ''}
                    >
                      {value}
                    </TableCell>
                  );
                })}
                {getRowBlockMessage(row) && (
                  <Grid className={styles.coverParentFull}>
                    <span>{getRowBlockMessage(row)}</span>
                  </Grid>
                )}
              </TableRow>
            ))}
            {props.summary && (
              <TableRow>
                {filteredColumns.map((column) => {
                  const value = props.summary[column.id];
                  return (
                    <TableCell key={column.id}>
                      <b>{value ?? ''}</b>
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            {props.children}
          </TableBody>
        </Table>
      </TableContainer>
      {filterId && (
        <FilterList
          isOpen={openTableFilter}
          anchorEl={anchorEl}
          columns={columns}
          optionalColumns={optionalColumns}
          filteredColumns={filteredColumns}
          handleClose={handleCloseFilter}
          handleTableFilter={handleTableFilter}
        />
      )}
    </>
  );
}
