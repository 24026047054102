import React, { useState, useEffect, useCallback, useContext } from 'react';
import styles from './styles.module.css';
import AlertHelper from '../../../helpers/AlertHelper';
import Loader from '../../utils/Loading';
import { useParams } from 'react-router-dom';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import DataTable from '../../utils/DataTable';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { Grid, Button } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SyncIcon from '@mui/icons-material/Sync';
import { Paper, TablePagination, Typography } from '@mui/material';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AddPaymentToVendorPopup from '../../popups/AddPaymentToVendorPopup';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CsvHelper from '../../../helpers/CsvHelper';
import { AlertMessagesContext } from 'react-alert-messages';
import { saveAs } from 'file-saver';
import { ShopContext } from '../../../Context/ShopContext';
import VendorServices from '../../../services/VendorServices';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import PurchaseService from '../../../services/PurchaseService';
import VendorPurchaseDialog from '../../popups/VendorPurchaseDialog';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';

function getDefaultStartTime() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function VendorDetails() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop } = useContext(ShopContext);

  const [loadingIndicator, setLoadingIndicator] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [vendorJournals, setVendorJournals] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [isEnableSearchRefreshButton, setIsEnableSearchRefreshButton] = useState(true);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [fromDate, setFromDate] = useState(getDefaultStartTime());
  const [toDate, setToDate] = useState(new Date());
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(false);
  const [prevFilterData, setPrevFilterData] = useState({
    fromDate,
    toDate,
  });
  const [paymentTab, setPaymentTab] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);

  const { vendorId } = useParams();

  const CSV_COLUMNS = {
    id: 'Id',
    purchase_id: 'Purchase Id',
    date: {
      title: 'Date',
      getValue: (item) => {
        const date = new Date(item.time);
        return DateTimeHelpers.convertDateToIsoDMY(date);
      },
    },
    time: {
      title: 'Time',
      getValue: (item) => {
        const date = item.time.split('T')[1].split('.')[0];
        return DateTimeHelpers.formatServerTimeSegment(date);
      },
    },
    description: 'Description',
    debit: 'Debit',
    credit: 'Credit',
    balance: 'Balance',
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(vendorJournals, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `bills-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to csv successfully',
      type: 'success',
    });
  };

  const getBalance = (data) => {
    const balance = ShopsHelper.getAmountFormatted(shop, data?.balance);
    let balanceStyle;
    if (balance < 0) balanceStyle = { color: 'red' };
    else balanceStyle = { color: 'green' };
    return (
      <Typography variant="body2" style={balanceStyle} gutterBottom>
        {balance}
      </Typography>
    );
  };

  const headerData = [
    {
      label: 'Date',
      id: 'Date',
      type: 'callback',
      viewRender: (item) => {
        const date = new Date(item.time);
        return DateTimeHelpers.convertDateToIsoDMY(date);
      },
    },
    {
      label: 'Time',
      id: 'time',
      type: 'callback',
      viewRender: (item) => {
        const date = item.time.split('T')[1].split('.')[0];
        return DateTimeHelpers.formatServerTimeSegment(date);
      },
    },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
    },
    {
      label: 'Credit',
      id: 'credit',
      type: 'text',
    },
    {
      label: 'Debit',
      id: 'debit',
      type: 'text',
    },
    {
      label: 'Balance',
      id: 'balance',
      type: 'callback',
      viewRender: (data) => getBalance(data),
    },
  ];

  const getVendorJournals = async ({
    _fromDate = fromDate,
    _toDate = toDate,
    _offset = offset,
    _limit = limit,
  } = {}) => {
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(_fromDate.getTime() - _fromDate.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(_toDate.getTime() - _toDate.getTimezoneOffset() * 60000);

      const params = {
        start_time: timezoneFreeStartTime,
        end_time: timezoneFreeEndTime,
        offset: _offset,
        limit: _limit,
        search: filterText,
      };

      const res = await VendorServices.getVendorJournals(vendorId, params);
      setVendorJournals(res?.data);
      setCount(res?.count);
      setIsEnableSearchRefreshButton(false);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    setLoadingIndicator(false);
  };

  const getVendorDetails = async () => {
    try {
      const res = await VendorServices.getVendor(vendorId);
      setVendor(res);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
  };

  const addPaymentToVendor = async (data) => {
    setLoadingIndicator(true);
    try {
      await VendorServices.addPaymentToVendor(vendorId, data);
      togglePaymentTab();
      setPage(0);
      setOffset(0);
      getVendorDetails();
      getVendorJournals({ _offset: 0 });
      AlertHelper.setShortMessage(setSuccessMsg, 'Payment added successfully');
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    setLoadingIndicator(false);
  };

  const togglePaymentTab = () => setPaymentTab((prevValue) => !prevValue);

  const togglePurchaseView = async (row) => {
    if (row === null || row?.purchase_id === null) return setSelectedPurchase(null);
    setLoadingIndicator(true);
    try {
      const res = await PurchaseService.getPurchase(row?.purchase_id);
      (res?.items || []).forEach((item) => (item.total = item.quantity * item.rate + item.vat));
      setSelectedPurchase(res);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    setLoadingIndicator(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const _offset = newPage * limit;
    setOffset(_offset);
    getVendorJournals({ _offset });
  };

  const handleChangeRowsPerPage = (event) => {
    const _limit = +event.target.value;
    setLimit(_limit);
    getVendorJournals({ _limit });
  };

  const generateVendorJournals = () => {
    getVendorJournals();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      fromDate,
      toDate,
    });
  };

  const handleSearch = () => {
    setPage(0);
    setOffset(0);
    getVendorJournals({ _offset: 0 });
  };

  const isAnyChangeOnvendorDetailsFilters = useCallback(() => {
    return fromDate !== prevFilterData.fromDate || toDate !== prevFilterData.toDate;
  }, [fromDate, toDate, prevFilterData.fromDate, prevFilterData.toDate]);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnvendorDetailsFilters());
  }, [isAnyChangeOnvendorDetailsFilters, isShowGenerateButton]);

  useEffect(() => {
    getVendorDetails();
    getVendorJournals();
    //eslint-disable-next-line
  }, [vendorId]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Transaction<span className={styles.menuTitle}>Details & History</span>
        </h2>
        {shop && shop?.vendor_support_validity !== null && (
          <div style={{ justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Export As</label>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={styles.actionBtn}
              style={{ backgroundColor: '#00a65a' }}
              onClick={toCsvBtnPressed}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              CSV
            </Button>
          </div>
        )}
      </div>
      {shop && shop.customer_support_validity == null && (
        <RestrictionInfo
          title={'Vendor Support Feature not available '}
          content={'To get Vendor support options, contact support team.'}
        />
      )}

      {shop && shop.vendor_support_validity !== null && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Transactions Details</h2>
              </div>
              <div className={styles.filerInputSec}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: '#ff851b' }}
                  className={styles.actionBtn}
                  onClick={togglePaymentTab}
                >
                  <RequestQuoteOutlinedIcon className={styles.actionBtnIcon} />
                  Add Payment
                </Button>
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                      setIsEnableSearchRefreshButton(true);
                    }}
                    className={styles.searchInput}
                    placeholder="search items"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={handleSearch}
                    disabled={!isEnableSearchRefreshButton}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      max={new Date()}
                      className={styles.dateBox}
                      value={fromDate}
                      onChange={(date) => {
                        setFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      max={new Date()}
                      value={toDate}
                      onChange={setToDate}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Generate Report</label>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={generateVendorJournals}
                    disabled={isShowGenerateButton === false}
                  >
                    <SyncIcon className={styles.actionBtnIcon} />
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.changeable} style={{ paddingBottom: '15px' }}>
            <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Name</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{vendor?.name || '-'}</h3>
              </Grid>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Code</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{vendor?.code || 0}</h3>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Mobile</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{vendor?.mobile || '-'}</h3>
              </Grid>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Balance</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{vendor?.balance || 0}</h3>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Address</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{vendor?.address || '-'}</h3>
              </Grid>
            </Grid>
          </div>
          {errorMsg && (
            <div className={styles.marginTop}>
              <Error title={errorMsg} />
            </div>
          )}
          {successMsg && (
            <div className={styles.marginTop}>
              <Success title={successMsg} />
            </div>
          )}

          {/* TRANSACTOINS SECTION */}
          <Grid className={styles.changeable}>
            <div className={styles.headTitle}>
              <h2 className={styles.subTitle}>Transaction History</h2>
            </div>
            {vendorJournals && vendorJournals.length ? (
              <>
                <Paper className={styles.tableWrapper}>
                  <div className={styles.tableContainer}>
                    <Grayout open={isShowGenerateButton} />
                    <DataTable columns={headerData} rows={vendorJournals} rowClickListener={togglePurchaseView} />
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[20, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </>
            ) : (
              !loadingIndicator && (
                <Info
                  title={'No transactions to list'}
                  content={
                    'You have no transactions to list with current filter configuration. Please clear the filters or make some purchase'
                  }
                />
              )
            )}
          </Grid>
        </>
      )}
      {selectedPurchase && <VendorPurchaseDialog togglePurchaseTab={togglePurchaseView} rowData={selectedPurchase} />}
      {paymentTab && (
        <AddPaymentToVendorPopup
          togglePaymentTab={togglePaymentTab}
          addPaymentToVendor={addPaymentToVendor}
          currentBalance={vendor?.balance || 0}
        />
      )}
    </div>
  );
}

export default withConsoleBase(VendorDetails);
