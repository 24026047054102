import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../utils/DataTable';
import DateTimeHelpers from '../../helpers/DateTimeHelpers';

export default function ExternalSyncFailedSaleDialog({
  open,
  log,
  onClose,
  onRetry,
}) {
  const onClickRetry = async () => {
    onClose();
    await onRetry(log.sale_id);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Sale Sync Error Details
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>Sale ID: {log?.sale_id} </Box>
        <DataTable
          columns={[
            {
              id: 'date',
              label: 'Date',
              type: 'callback',
              viewRender: (data) => DateTimeHelpers.getTime(data.created_at),
            },
            {
              id: 'time',
              label: 'Time',
              type: 'callback',
              viewRender: (data) => DateTimeHelpers.getTime(data.created_at),
            },
            { id: 'message', label: 'Error Message', type: 'text' },
          ]}
          rows={log?.logs || []}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" color="primary" onClick={onClickRetry}>
          Retry
        </Button>
      </DialogActions>
    </Dialog>
  );
}
