import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, LinearProgress } from '@mui/material';
import Lottie from 'lottie-react';
import SuccessAnimation from './SuccessAnimation.json';
import FailedAnimation from './FailedAnimation.json';
import PaymentService from '../../../services/PaymentService';
import { ROUTES } from '../../../const';

const MAX_API_CALLS = 4;

function PaymentStatus() {
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get('payment_id');
  const subscription_type = queryParams.get('subscription_type');

  const [paymentStatus, setPaymentStatus] = useState('PENDING');
  const [apiCallCount, setApiCallCount] = useState(0);

  useEffect(() => {
    let timeout;
    if (paymentStatus === 'SUCCESS') {
      timeout = setTimeout(() => {
        history.push(
          `${subscription_type === 'new' ? ROUTES.ONBOARDING : ROUTES.ADD_ONS}`
        );
      }, 3000);
    } else if (paymentStatus === 'FAILED') {
      timeout = setTimeout(() => {
        history.push(ROUTES.ADD_ONS);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [paymentStatus, history, subscription_type]);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const resp = await PaymentService.paymentStatus(paymentId);
      setPaymentStatus(resp?.status);
      setApiCallCount((count) => count + 1);
    };

    if (apiCallCount < MAX_API_CALLS) {
      checkPaymentStatus();
      const interval = setInterval(checkPaymentStatus, 5000);

      return () => {
        clearInterval(interval);
      };
    } else {
      history.push(ROUTES.ADD_ONS);
    }
  }, [paymentId, history, apiCallCount]);

  const animationData =
    paymentStatus === 'FAILED' ? FailedAnimation : SuccessAnimation;

  return (
    <Grid
      className={styles.container}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {paymentStatus === 'PENDING' ? (
        <Grid style={{ maxWidth: 500 }}>
          <p style={{ paddingBottom: 20 }}>Payment processing....</p>
          <LinearProgress />
        </Grid>
      ) : (
        <Grid style={{ maxHeightt: 500, maxWidth: 500 }}>
          <Lottie animationData={animationData} />
        </Grid>
      )}
    </Grid>
  );
}

export default PaymentStatus;
