import BackendService from './BackendService';

async function getItemisedBillReport(data = {}) {
  return BackendService.postData('/reports/sale-items/', data);
}
async function getReturnedBillItemReport(data = {}) {
  return BackendService.postData('/reports/returned-sale-items/', data);
}
async function getItemisedBillReportWithProfit(data = {}) {
  return BackendService.postData('/reports/sale-items/profit/', data);
}
async function getHSNReport(data = {}) {
  return BackendService.postData('/reports/hsn-summary/', data);
}
async function getItemisedBillExcel(data = {}) {
  return BackendService.post(
    '/reports/items/',
    data,
    undefined,
    undefined,
    'arraybuffer'
  );
}
async function getItemisedBillCsv(data = {}) {
  return BackendService.post(
    '/reports/items/',
    data,
    undefined,
    undefined,
    'arraybuffer'
  );
}

const ItemisedBillReportService = {
  getItemisedBillReport,
  getItemisedBillExcel,
  getItemisedBillCsv,
  getItemisedBillReportWithProfit,
  getReturnedBillItemReport,
  getHSNReport,
};

export default ItemisedBillReportService;
