import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Grid, Box } from '@material-ui/core';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { styled } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { plans, SANOFT_WEB_URLS } from '../../../const';
import AuthService from '../../../services/AuthService';
import Loader from '../../utils/Loading';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useContext } from 'react';
import { ThemeContext } from '../../../Context/ThemeContext';
import { DEFAULT_COUNTRY_CODE } from '../../../const';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  border: 'none',
  padding: '8px',
  fontSize: '12px',
  fontWeight: '600',
  transition: '0.5s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      transform: 'translateY(0px)',
    },
  },
}));

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  padding: '5px',
  backgroundColor: 'white',
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: 0,
  },
}));

export default function Plans() {
  const [planValidity, setPlanValidity] = useState('monthly');
  const [countryCode, setCountryCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { theme } = useContext(ThemeContext);

  const handleChangeValidity = (event, newValidity) => {
    if (newValidity === null) {
      return;
    }
    setPlanValidity(newValidity);
  };

  const loadCountryCode = async () => {
    setIsLoading(true);
    let countryCode;
    try {
      const response = await AuthService.findCountryCode();
      countryCode = response?.country_code || DEFAULT_COUNTRY_CODE;
    } catch (error) {
      countryCode = DEFAULT_COUNTRY_CODE;
    }
    setCountryCode(countryCode);
    setIsLoading(false);
  };

  const handleWhatsAppRedirect = () => {
    const url = `https://api.whatsapp.com/send?phone=${
      theme.variable.phone
    }&text=${encodeURIComponent('Need help on Sanoft Android POS')}`;
    window.open(url, '_blank');
  };

  const handleRedirectToWeb = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    loadCountryCode();
  }, []);

  return (
    <>
      <Loader isOpen={isLoading} backdropColor={'#ffffff'} />
      <Grid
        className={styles.wrapper}
        style={{ display: countryCode ? 'flex' : 'none' }}
      >
        <Grid className={styles.heading}>
          <h2>Sanoft Android POS Pricing Plans</h2>
          <h3>Choose your plan for Sanoft Android POS</h3>
        </Grid>
        <Grid>
          <CustomToggleButtonGroup
            color="primary"
            value={planValidity}
            exclusive
            onChange={handleChangeValidity}
            aria-label="Platform"
          >
            <CustomToggleButton value="monthly">MONTHLY</CustomToggleButton>
            <CustomToggleButton value="yearly">YEARLY</CustomToggleButton>
          </CustomToggleButtonGroup>
        </Grid>
        <Grid className={styles.planTypes}>
          <Box
            container
            className={`${styles.plansOuterBoxFrameWithoutAd} ${styles.standardPlanContainer}`}
          >
            <Grid
              style={{ boxShadow: '3' }}
              item
              xs={12}
              sm={12}
              className={styles.plansWrapperContainer}
            >
              <Grid>
                <Grid className={styles.planInfo}>
                  <Grid className={styles.plansTitleContainer}>
                    <h3>STANDARD</h3>
                  </Grid>
                  <PlanPrice
                    plan={plans['standard']}
                    countryCode={countryCode}
                    planValidity={planValidity}
                  />
                </Grid>
                <Grid className={styles.plansBodyContainer}>
                  <Grid className={styles.plansBodyContainerHeading}>
                    <span>What's included</span>
                  </Grid>
                  <ul className={styles.plansFeatures}>
                    <li className={styles.checkmark}>2000 product support</li>
                    <li className={styles.checkmark}>3000 sales per month</li>
                    <li className={styles.checkmark}>Category management</li>
                    <li className={styles.checkmark}>
                      Sales sync in five minutes
                    </li>
                    <li className={styles.checkmark}>One counter support</li>
                    <li className={styles.cross}>
                      Profit and loss calculation
                    </li>
                    <li className={styles.cross}>
                      Additional expenses support
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box
            container
            className={`${styles.plansOuterBoxFrameWithoutAd} ${styles.premiumPlanContainer}`}
          >
            <Grid
              style={{ boxShadow: '3' }}
              item
              xs={12}
              sm={12}
              className={styles.plansWrapperContainer}
            >
              <Grid>
                <Grid className={styles.planInfo}>
                  <Grid className={styles.plansTitleContainer}>
                    <h3 className={styles.titleText}>PREMIUM</h3>
                  </Grid>
                  <PlanPrice
                    plan={plans['premium']}
                    countryCode={countryCode}
                    planValidity={planValidity}
                  />
                </Grid>
                <Grid className={styles.plansBodyContainer}>
                  <Grid className={styles.plansBodyContainerHeading}>
                    <span>What's included</span>
                  </Grid>
                  <ul className={styles.plansFeatures}>
                    <li className={styles.checkmark}>3000 product support</li>
                    <li className={styles.checkmark}>6000 sales per month</li>
                    <li className={styles.checkmark}>Category management</li>
                    <li className={styles.checkmark}>Real time sales sync</li>
                    <li className={styles.checkmark}>Two counters support</li>
                    <li className={styles.checkmark}>
                      Profit and loss calculate
                    </li>
                    <li className={styles.checkmark}>
                      Additional expenses support
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <WhatsAppIcon
            className={styles.contactIconContainer}
            onClick={handleWhatsAppRedirect}
          />
        </Grid>
        <hr className={styles.divider} />
        <span>
          &copy; 2024{' '}
          <a
            style={{ color: '#333', textDecoration: 'none' }}
            href="https://www.sanoft.com"
            target="_blank"
            rel="noreferrer"
          >
            Sanoft
          </a>
          . All rights reserved.
        </span>
        <Grid className={styles.footerDiv}>
          <span
            onClick={() => {
              handleRedirectToWeb(SANOFT_WEB_URLS.PRIVECY_POLICY);
            }}
          >
            Privacy Policy
          </span>
          <span
            onClick={() => {
              handleRedirectToWeb(SANOFT_WEB_URLS.TERMS);
            }}
          >
            Terms & Conditions
          </span>
          <span
            onClick={() => {
              handleRedirectToWeb(SANOFT_WEB_URLS.REFUND_POLICY);
            }}
          >
            Refund Policy
          </span>
          <span
            onClick={() => {
              handleRedirectToWeb(SANOFT_WEB_URLS.CONTACT_US);
            }}
          >
            Contact us
          </span>
        </Grid>
      </Grid>
    </>
  );
}

const PlanPrice = ({ countryCode, planValidity, plan }) => {
  return (
    <>
      <Grid className={styles.price}>
        {countryCode === 'IN' ? (
          <CurrencyRupeeIcon className={styles.planeIcon} />
        ) : (
          <AttachMoneyIcon className={styles.planeIcon} />
        )}
        <span>
          {countryCode === 'IN'
            ? planValidity === 'monthly'
              ? plan['monthly']['IN']['price']
              : plan['yearly']['IN']['price']
            : planValidity === 'monthly'
            ? plan['monthly']['US']['price']
            : plan['yearly']['US']['price']}
        </span>
      </Grid>

      <Grid className={styles.validity}>
        <span>{`per ${planValidity === 'monthly' ? 'month' : 'year'}`}</span>
      </Grid>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="/signup"
        data-cb-type="checkout"
        data-cb-item-0={plan['monthly']['IN']['plan_id']}
        data-cb-item-0-quantity="1"
        className={styles.link}
        style={{
          display: `${
            planValidity === 'monthly' && countryCode === 'IN'
              ? `inline`
              : 'none'
          }`,
        }}
      >
        <Grid className={styles.plansButtonContainer}>START FREE TRIAL</Grid>
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="/signup"
        data-cb-type="checkout"
        data-cb-item-0={plan['yearly']['IN']['plan_id']}
        data-cb-item-0-quantity="1"
        className={styles.link}
        style={{
          display: `${
            planValidity === 'yearly' && countryCode === 'IN'
              ? `inline`
              : 'none'
          }`,
        }}
      >
        <Grid className={styles.plansButtonContainer}>START FREE TRIAL</Grid>
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="/signup"
        data-cb-type="checkout"
        data-cb-item-0={plan['monthly']['US']['plan_id']}
        data-cb-item-0-quantity="1"
        className={styles.link}
        style={{
          display: `${
            planValidity === 'monthly' && countryCode !== 'IN'
              ? `inline`
              : 'none'
          }`,
        }}
      >
        <Grid className={styles.plansButtonContainer}>START FREE TRIAL</Grid>
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="/signup"
        data-cb-type="checkout"
        data-cb-item-0={plan['yearly']['US']['plan_id']}
        data-cb-item-0-quantity="1"
        className={styles.link}
        style={{
          display: `${
            planValidity === 'yearly' && countryCode !== 'IN'
              ? `inline`
              : 'none'
          }`,
        }}
      >
        <Grid className={styles.plansButtonContainer}>START FREE TRIAL</Grid>
      </a>
    </>
  );
};
