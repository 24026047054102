import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import styles from './styles.module.css';
import Info from '../utils/Alert/Info';
import { Grid } from '@material-ui/core';
import DateTimeHelpers from '../../helpers/DateTimeHelpers';

function CustomerSaleDialog(props) {
  const { toggleItemsTab, rowData } = props;
  const items = rowData?.items || [];
  const columnHeader = [
    {
      id: 'item.name',
      label: 'Name',
      type: 'text',
    },
    {
      id: 'quantity',
      label: 'Quantity',
      type: 'floatQuantity',
    },
    {
      id: 'rate',
      label: 'Price',
      type: 'floatAmount',
    },
    {
      id: 'tax',
      label: 'Tax',
      type: 'text',
    },
    {
      id: 'payable',
      label: 'Total',
      type: 'floatAmount',
    },
  ];
  const getDate = (time) => {
    const date = new Date(time);
    return DateTimeHelpers.convertDateToIsoDMY(date);
  };
  const getTime = (time) => {
    const date = time.split('T')[1].split('.')[0];
    return DateTimeHelpers.formatServerTimeSegment(date);
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Sale Details</h2>
          <CloseIcon
            onClick={() => {
              toggleItemsTab(null);
            }}
          />
        </div>
        <div className={styles.changeable} style={{ margin: '10px' }}>
          <Grid container style={{ marginTop: '5px' }} spacing={1}>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Bill No</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.bill_no}</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Tax</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.tax || 0}</h5>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '5px' }} spacing={1}>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Date</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{getDate(rowData?.time)}</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Additional Charge</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.cess || 0}</h5>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '5px' }} spacing={1}>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Time</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{getTime(rowData?.time)}</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Discount</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.discount || 0}</h5>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '5px' }} spacing={1}>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Id</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.id}</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Payable</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.payable || '-'}</h5>
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          spacing={1}
          className={styles.subSection}
          style={{ padding: '5px 15px' }}
        >
          <Grid item xs={12} className={styles.subHeader}>
            <h2 className={styles.editTitle}>Payment Details</h2>
          </Grid>
          {(rowData.sale_payments || []).map((payment) => (
            <>
              <Grid item xs={6} md={3} className={styles.subSectionHeader}>
                <h6 className={styles.textHead}>{payment.mode}</h6>
                <h6 className={styles.textHead}>:</h6>
              </Grid>
              <Grid item xs={6} md={3}>
                <h6 className={styles.textValues}>{payment.amount}</h6>
              </Grid>
            </>
          ))}
        </Grid>
        <div className={styles.tableContent2}>
          {items && items.length ? (
            <DataTable columns={columnHeader} rows={items} />
          ) : (
            <Info
              title={'Sale Details not found'}
              content={'This Sale have no items to list up'}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerSaleDialog;
