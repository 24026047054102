import BackendService from './BackendService';

async function getNotifications(params = { offset: 0, limit: 5 }) {
  return BackendService.getData('/notifications/', {}, params);
}

async function markAsReadNotification(id) {
  return BackendService.postData(`/notifications/${id}/read/`, '');
}

const NotificationsService = {
  getNotifications,
  markAsReadNotification,
};

export default NotificationsService;
