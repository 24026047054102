import React, { useContext, useEffect } from 'react';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Grid } from '@mui/material';
import { OnlinePurchaseContext } from '../../../Context/OnlinePurchaseContext';
import { ROUTES, STORAGE_KEYS } from '../../../const';
import PaymentService from '../../../services/PaymentService';
import { AlertMessagesContext } from 'react-alert-messages';

function PaymentProcessing() {
  const history = useHistory();
  const { purchaseData } = useContext(OnlinePurchaseContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const handleBuyButton = async () => {
    try {
      if (!localStorage.getItem(STORAGE_KEYS.TOKEN)) {
        history.push(ROUTES.SIGNUP);
        return;
      }
      const resp = await PaymentService.initiatePayment(purchaseData);
      if (resp) {
        window.location.href = resp?.payment_url;
      }
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
  };

  useEffect(() => {
    handleBuyButton();
    //eslint-disable-next-line
  }, []);

  return (
    <Grid container className={styles.container}>
      <Loader isOpen={true} />
    </Grid>
  );
}

export default PaymentProcessing;
