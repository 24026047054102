import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import SalesReportsService from '../../../services/SalesReportsService';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@mui/icons-material/Sync';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { saveAs } from 'file-saver';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import AlertHelper from '../../../helpers/AlertHelper';
import Error from '../../utils/Alert/Error';
import { ShopContext } from '../../../Context/ShopContext';
import { AlertMessagesContext } from 'react-alert-messages';
import CsvHelper from '../../../helpers/CsvHelper';
import { filterEndDate } from '../../utils/FilterEndDate';
import PrintIcon from '@mui/icons-material/Print';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import DateLimitDialog from '../../popups/DateLimitDialog';

function OrderTypeSummaryReport() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(getDefaultStartTime());
  const [filterToDate, setFilterToDate] = useState(filterEndDate());
  const [reportData, setReportData] = useState([]);
  const [filteredReportData, setFilteredReportData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });
  const [errorMsg, setErrorMsg] = useState();
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [dateLimitPopup, setDateLimitPopup] = useState(false);

  const CSV_COLUMNS = {
    order_type: 'Order Type',
    sales_count: 'Sales',
    tax_amount: 'Tax Amount',
    cess_amount: 'Additional Charges',
    total: 'Total Price',
  };

  function getDefaultStartTime() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  useEffect(() => {
    generateItemisedBillReports();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isShowGenerateButton, isAnyChangeOnReportsFilters]);

  const generateItemisedBillReports = () => {
    loadOrderTypeReports();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
    });
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(reportData, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `order-type-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const loadOrderTypeReports = async ({ startTime = filterFromDate, endTime = filterToDate } = {}) => {
    if (DateTimeHelpers.reportDateLimit(startTime, endTime)) {
      setDateLimitPopup(true);
      return;
    }
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(endTime.getTime() - endTime.getTimezoneOffset() * 60000);
      const data = {
        filter_start_time: timezoneFreeStartTime,
        filter_end_time: timezoneFreeEndTime,
      };
      const resp = await SalesReportsService.getSaleOrderTypeReport(data);
      setReportData(resp);
      setFilteredReportData(resp);
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
    setLoadingIndicator(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredReportData(reportData.filter((item) => item?.order_type.toLowerCase().includes(value.toLowerCase())));
  };

  const headerData = [
    {
      label: 'Order Type',
      id: 'order_type',
      type: 'text',
    },
    {
      label: 'Sales',
      id: 'sales_count',
      type: 'text',
    },
    {
      label: 'Tax',
      id: 'tax_amount',
      type: 'floatAmount',
    },
    {
      label: 'Additional Tax',
      id: 'cess_amount',
      type: 'floatAmount',
    },
    {
      label: 'Total Price',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item?.total)}`;
      },
    },
  ];

  const _getReportSummary = (items) => {
    let totalAmount = 0;
    let totalTax = 0;
    let totalAdditionalTax = 0;
    items.forEach((item) => {
      totalAmount += item?.total;
      totalTax += item.tax_amount;
      totalAdditionalTax += item.cess_amount;
    });
    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
      totalAdditionalTax: ShopsHelper.getAmountFormatted(shop, totalAdditionalTax),
    };
  };

  const summary = _getReportSummary(filteredReportData);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          {' '}
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Order Type Report for{'  '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search by type"
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.dateDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
                <KeyboardTimePicker
                  disableFuture
                  id="time-picker-to"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.dateDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />

                <KeyboardTimePicker
                  disableFuture
                  id="time-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Generate Report</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generateItemisedBillReports}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}

      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click generate button'}
          content={
            'Reports are generated based on the filters. Please set filters and click generate button to the generate report'
          }
        />
      ) : filteredReportData && filteredReportData.length ? (
        <div className={styles.tableContainer}>
          <Grayout open={isShowGenerateButton} />
          <DataTable
            columns={headerData}
            rows={filteredReportData}
            rowKey="bill_no-plu"
            summary={{
              order_type: 'Total',
              total: `${shop?.currency || ''} ${summary?.totalAmount}`,
              tax_amount: `${shop?.currency || ''} ${summary.totalTax}`,
              cess_amount: `${shop?.currency || ''} ${summary.totalAdditionalTax}`,
            }}
          />
        </div>
      ) : (
        !loadingIndicator && (
          <Info
            severity="warning"
            title={'Oh no, there are no item sales matching to your filter'}
            content={'There are no item sales matching to your filter. Please verify your inputs to generate report.'}
          />
        )
      )}

      {dateLimitPopup && (
        <DateLimitDialog
          handleClose={() => {
            setDateLimitPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default withConsoleBase(OrderTypeSummaryReport);
