import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { AlertMessagesContext } from 'react-alert-messages';
import styles from './styles.module.css';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ItemService from '../../../services/ItemService';
import PurchaseService from '../../../services/PurchaseService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { Link, useHistory, useParams } from 'react-router-dom';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ROUTES } from '../../../const';
import EditIcon from '@mui/icons-material/Edit';
import VendorServices from '../../../services/VendorServices';
import Loader from '../../utils/Loading';
import CustomMenuItem from '../../utils/CustomComponents/CustomMenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewVendorDialog from '../../popups/NewVendorDialog';
import { featureIsExpired } from '../../utils/FeatureValidityExpire';
import { ShopContext } from '../../../Context/ShopContext';

function AddPurchase() {
  const { shop } = useContext(ShopContext);

  const { id } = useParams();
  const history = useHistory();
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const [editData, setEditData] = useState({});
  const [vendorId, setVendorId] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [item, setItem] = useState(null);
  const [billDate, setBillDate] = useState(new Date());
  const [itemPlu, setItemPlu] = useState('');
  const [itemName, setItemName] = useState('');
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [gst, setGst] = useState(0);
  const [totalAmount, setTotalAmount] = useState('');
  const [purchasedItems, setPurchasedItems] = useState([]);
  const [removedItems, setRemovedItems] = useState('');
  const [vendors, setVendors] = useState([]);
  const [paidAmount, setPaidAmount] = useState();
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowAddVendonDialog, setShowAddVendonDialog] = useState(false);
  const [vendorNameOld, setVendorNameOld] = useState('');

  const featureValidity = shop?.customer_support_validity;

  const onCreateVendor = () => {
    getVendors();
    setShowAddVendonDialog(false);
  };

  const openCreateVendorDialog = () => {
    setShowAddVendonDialog(true);
  };
  const closeCreateVendorDialog = () => {
    setShowAddVendonDialog(false);
  };

  const getItems = async () => {
    const items = await ItemService.getItems();
    setItems(items);
  };

  const getVendors = async () => {
    const vedors = await VendorServices.getVendors();
    setVendors(vedors);
  };
  const fetchInitialData = async () => {
    setLoadingIndicator(true);
    await Promise.all([getItems(), getVendors()]);
    setLoadingIndicator(false);
  };

  useEffect(() => {
    fetchInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    const getPurchaseData = async () => {
      const purchase = await PurchaseService.getPurchase(id);
      setEditData(purchase);
      setInvoiceNumber(purchase.invoice_no);
      setBillDate(DateTimeHelpers.convertDateToMDY(purchase.bill_date));
      setPurchaseDate(DateTimeHelpers.convertDateToMDY(purchase.date));
      setPurchasedItems(purchase.items);
      setVendorNameOld(purchase.vendor_name_old);
      setPaidAmount(purchase.paid_amount);
      setVendorId(purchase.vendor?.id);
    };
    getPurchaseData();
  }, [id]);

  useEffect(() => {
    setTotalAmount(Number(cost) * Number(quantity) + Number(gst));
  }, [cost, quantity, gst]);

  const resetItemFields = () => {
    setItemName('');
    setItemPlu('');
    setCost('');
    setQuantity('');
    setGst('');
    setTotalAmount('');
    setItem('');
  };

  const addItem = () => {
    let data = {
      plu: item.plu,
      item: item,
      rate: Number(cost),
      quantity: Number(quantity),
      vat: Number(gst),
    };
    if (editData && !(editData?.items || []).find((itm) => itm.item === item && itm.rate === cost)) {
      data = {
        ...data,
        purchase: editData.id,
        new_item: true,
      };
    } else {
      const updatingItem = (editData?.items || []).find((itm) => itm.item === item && itm.rate === cost);
      data = {
        ...updatingItem,
        ...data,
      };
    }
    setPurchasedItems([...purchasedItems, data]);
    resetItemFields();
  };

  const handleAddPurchase = async () => {
    setLoadingIndicator(true);
    try {
      const data = {
        vendor_id: vendorId,
        invoice_no: invoiceNumber,
        bill_date: DateTimeHelpers.convertDateToDMY(billDate),
        date: DateTimeHelpers.convertDateToDMY(purchaseDate),
        items: purchasedItems.map((pi) => ({ ...pi, item_id: pi.item.id })),
        paid_amount: Number(paidAmount),
        vendor_name_old: vendorNameOld,
      };
      await PurchaseService.addPurchase(data);
      postAlertMessage({
        text: 'Purchase Added successfully',
        type: 'success',
      });
      history.push(ROUTES.PURCHASE_HISTORY);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const dltItem = (item) => {
    if (item.hasOwnProperty('id')) {
      setRemovedItems((old) => [...old, item.id]);
    }
    setPurchasedItems(purchasedItems.filter((itm) => itm.rate !== item.rate && itm.plu !== item.plu));
  };

  const handleEditItem = (purchaseItem) => {
    setItem(purchaseItem.item);
    setItemName(purchaseItem.item?.name);
    setItemPlu(purchaseItem?.plu);
    setQuantity(purchaseItem?.quantity);
    setCost(purchaseItem?.rate);
    setGst(purchaseItem?.vat);
    setPurchasedItems(purchasedItems.filter((itm) => itm.rate !== purchaseItem.rate && itm.plu !== purchaseItem.plu));
  };

  const handleEditPurchase = async () => {
    setLoadingIndicator(true);
    try {
      const _items = purchasedItems.map((pi) => ({
        ...pi,
        item_id: pi.item.id,
      }));
      const olditems = _items.filter((purchaseItem) => !purchaseItem.new_item);
      const newItems = _items.filter((purchaseItem) => purchaseItem.new_item);
      const data = {
        ...editData,
        vendor_id: vendorId,
        invoice_no: invoiceNumber,
        bill_date: DateTimeHelpers.convertDateToDMY(billDate),
        date: DateTimeHelpers.convertDateToDMY(purchaseDate),
        items: olditems,
        new_items: newItems,
        remove_items: removedItems,
        vendor_name_old: vendorNameOld,
        paid_amount: Number(paidAmount),
      };
      await PurchaseService.updatePurchase(data, id);
      postAlertMessage({
        text: 'Purchase updated successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
    history.push(ROUTES.PURCHASE_HISTORY);
  };

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Purchase<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>New Purchase</h2>
          </div>
        </div>
        <Grid container justify="flex-end" alignItems="center">
          <Grid item xs={2} sm={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Vendor</h3>
          </Grid>
          {shop && featureIsExpired(featureValidity) ? (
            <Grid item xs={4} sm={4}>
              <TextField
                labelId="demo-select-small-label"
                fullWidth
                id="demo-select-small"
                value={vendorNameOld || ''}
                label="Vendor"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setVendorNameOld(e.target.value);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={4} sm={4}>
              <TextField
                select
                labelId="demo-select-small-label"
                fullWidth
                id="demo-select-small"
                value={vendorId || 0}
                label="Vendor"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setVendorId(Number(e.target.value));
                }}
              >
                <MenuItem value={0}>
                  <em>No Vendor Selected</em>
                </MenuItem>
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id} value={`${vendor.id}`}>
                    {vendor.name}
                  </MenuItem>
                ))}
                <CustomMenuItem onClick={openCreateVendorDialog}>
                  <AddCircleIcon fontSize="large" style={{ marginLeft: 5 }} />{' '}
                  <p className={styles.normalText}>Create New Vendor</p>
                </CustomMenuItem>
              </TextField>
            </Grid>
          )}
          <Grid item xs={3} sm={3} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Purchase Date</h3>
          </Grid>
          <Grid item xs={3} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                error={false}
                id="date-purchase"
                value={purchaseDate}
                onChange={(date) => {
                  setPurchaseDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" alignItems="center">
          <Grid item xs={2} sm={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Invoice Number</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              label="Invoice Number"
              variant="outlined"
              size="small"
              fullWidth
              value={invoiceNumber}
              onChange={(e) => {
                setInvoiceNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3} sm={3} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Bill Date</h3>
          </Grid>
          <Grid item xs={3} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-bill"
                value={billDate}
                onChange={(date) => {
                  setBillDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'Change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item xs={2} sm={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Paid Amount</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              label="Paid Amount"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={paidAmount || ''}
              onChange={(e) => {
                setPaidAmount(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </div>

      <TableContainer style={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.columnCell}>Item PLU</TableCell>
              <TableCell className={styles.nameColumn}>Item Name</TableCell>
              <TableCell className={styles.columnCell}>Cost</TableCell>
              <TableCell className={styles.columnCell}>Quantity</TableCell>
              <TableCell className={styles.columnCell}>Total Tax</TableCell>
              <TableCell className={styles.columnCell}>Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchasedItems &&
              purchasedItems.map((purchasedItem) => (
                <TableRow key={purchasedItem.id}>
                  <TableCell>{purchasedItem.plu}</TableCell>
                  <TableCell>{purchasedItem.item.name}</TableCell>
                  <TableCell>{purchasedItem.rate}</TableCell>
                  <TableCell>{purchasedItem.quantity}</TableCell>
                  <TableCell>{purchasedItem.vat}</TableCell>
                  <TableCell>
                    {Number(purchasedItem.rate) * Number(purchasedItem.quantity) + Number(purchasedItem.vat)}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => dltItem(purchasedItem)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => handleEditItem(purchasedItem)}>
                      <EditIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            <TableRow key="input row">
              <TableCell>
                <Autocomplete
                  value={item?.plu}
                  onChange={(event, newItem) => {
                    setItem(newItem);
                    setItemName(newItem.name);
                    setCost(newItem?.cost);
                  }}
                  disableClearable
                  inputValue={itemPlu}
                  onInputChange={(e, value) => {
                    setItemPlu(value);
                  }}
                  getOptionLabel={(option) => `${option.plu}`}
                  options={items}
                  renderInput={(params) => (
                    <TextField {...params} label="PLU" variant="outlined" size="small" fullWidth />
                  )}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  value={item?.name}
                  onChange={(event, newItem) => {
                    setItem(newItem);
                    setItemPlu(String(newItem.plu));
                    setCost(newItem?.cost);
                  }}
                  disableClearable
                  inputValue={itemName}
                  onInputChange={(e, value) => {
                    setItemName(value);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  options={items}
                  renderInput={(params) => (
                    <TextField {...params} label="Name" variant="outlined" size="small" fullWidth />
                  )}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Cost"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                  type="number"
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Tax"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={gst}
                  type="number"
                  onChange={(e) => {
                    setGst(e.target.value);
                  }}
                />
              </TableCell>
              <TableCell>{totalAmount}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  disabled={itemPlu === '' || itemName === '' || cost === ''}
                  onClick={addItem}
                >
                  <LibraryAddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className={styles.submitSection} justify="flex-end">
        <Link to={ROUTES.PURCHASE_HISTORY} className={styles.link}>
          <Button variant="contained" color="secondary" className={styles.closeBtn}>
            Cancel
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#00a65a' }}
          disabled={invoiceNumber === '' || billDate === '' || purchaseDate === '' || purchasedItems.length === 0}
          onClick={editData.id ? handleEditPurchase : handleAddPurchase}
        >
          {editData.id ? 'Update Purchase' : 'Save Purchase'}
        </Button>
      </Grid>
      {isShowAddVendonDialog && <NewVendorDialog onClose={closeCreateVendorDialog} onSuccess={onCreateVendor} />}
    </div>
  );
}

export default withConsoleBase(AddPurchase);
