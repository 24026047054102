import { Grid } from '@material-ui/core';
import styles from './styles.module.css';
import BillFormat from './BillFormat';
import ShopSettings from './ShopSettings';
// import ShopUsersList from './ShopUsersList';
// import ShortKey from './ShortKey';
import ShopProfile from './ShopProfile';
import React, { useState } from 'react';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import PaymentModes from './PaymentModes';
import Units from './Units';
import QuickbookSettings from './QuickbookSettings';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../const';

const tabStyleActive = {
  backgroundColor: '#FC9A02',
  color: '#fff',
};

const tabStyle = {
  color: '#000',
};

const NAVIGATION_TAB = {
  INVOICE_FORMAT: 'invoice-format',
  SHORT_KEY: 'short-key',
  SETTINGS: 'settings',
  USERS: 'users',
  PROFILE: 'profile',
  PAYMENT_MODES: 'payment-modes',
  UNITS: 'units',
  POS_SETTINGS: 'pos-settings',
  QUICKBOOK: 'quickbook',
};

function SettingsPage(props) {
  const { tab } = useParams();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(tab ?? NAVIGATION_TAB.PROFILE);

  const handleTabSelection = (tab) => {
    history.push(ROUTES.SETTINGS_TAB.replace(':tab', tab));
    setSelectedTab(tab);
  };

  return (
    <div className={styles.contentsArea}>
      <div className={styles.headingDiv}>
        <span className={styles.pageHead}>Settings</span>
      </div>
      <div className={styles.changeable}>
        <Grid className={styles.settingsTab} container>
          <div
            className={styles.navItem}
            style={selectedTab === NAVIGATION_TAB.PROFILE ? tabStyleActive : tabStyle}
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.PROFILE);
            }}
          >
            <span className={styles.title}>Profile</span>
          </div>
          <div
            className={styles.navItem}
            style={selectedTab === NAVIGATION_TAB.INVOICE_FORMAT ? tabStyleActive : tabStyle}
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.INVOICE_FORMAT);
            }}
          >
            <span className={styles.title}>Invoice format</span>
          </div>
          <div
            className={styles.navItem}
            style={selectedTab === NAVIGATION_TAB.PAYMENT_MODES ? tabStyleActive : tabStyle}
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.PAYMENT_MODES);
            }}
          >
            <span className={styles.title}>Payment Modes</span>
          </div>
          <div
            className={styles.navItem}
            style={selectedTab === NAVIGATION_TAB.UNITS ? tabStyleActive : tabStyle}
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.UNITS);
            }}
          >
            <span className={styles.title}>Units</span>
          </div>
          {/* <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.SHORT_KEY
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.SHORT_KEY);
            }}
          >
            <span className={styles.title}>Short Key</span>
          </div> */}
          <div
            className={styles.navItem}
            style={selectedTab === NAVIGATION_TAB.SETTINGS ? tabStyleActive : tabStyle}
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.SETTINGS);
            }}
          >
            <span className={styles.title}>Settings</span>
          </div>
          <div
            className={styles.navItem}
            style={selectedTab === NAVIGATION_TAB.QUICKBOOK ? tabStyleActive : tabStyle}
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.QUICKBOOK);
            }}
          >
            <span className={styles.title}>Quickbook</span>
          </div>
          {/* <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.USERS ? tabStyleActive : tabStyle
            }
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.USERS);
            }}
          >
            <span className={styles.title}>Users</span>
          </div> */}
        </Grid>

        {selectedTab === NAVIGATION_TAB.INVOICE_FORMAT && <BillFormat />}
        {/* {selectedTab === NAVIGATION_TAB.SHORT_KEY && <ShortKey />} */}
        {selectedTab === NAVIGATION_TAB.SETTINGS && <ShopSettings />}
        {selectedTab === NAVIGATION_TAB.PROFILE && <ShopProfile />}
        {/* {selectedTab === NAVIGATION_TAB.USERS && <ShopUsersList />} */}
        {selectedTab === NAVIGATION_TAB.PAYMENT_MODES && <PaymentModes />}
        {selectedTab === NAVIGATION_TAB.UNITS && <Units />}
        {/* {selectedTab === NAVIGATION_TAB.POS_SETTINGS && <PosSettings />} */}
        {selectedTab === NAVIGATION_TAB.QUICKBOOK && <QuickbookSettings />}
      </div>
    </div>
  );
}

export default withConsoleBase(SettingsPage);
