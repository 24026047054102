const DEFAULT_CACHE_TIME = 1000 * 60 * 20; // 20 minutes

function store(cache, key, data) {
  cache[key] = { time: Date.now(), data };
}

function get(cache, key) {
  const cacheEntry = cache[key];
  if (!cacheEntry) {
    return undefined;
  }

  if (cacheEntry.time + DEFAULT_CACHE_TIME < Date.now()) {
    delete cache[key];
    return undefined;
  }

  return cacheEntry.data;
}

function exists(cache, key) {
  return Boolean(get(cache, key));
}

function clear(cache, key) {
  if (key) {
    delete cache[key];
  }
}

const CacheHelper = { store, get, exists, clear };

export default CacheHelper;
