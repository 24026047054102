import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { Stack, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomFormSwitch from '../../utils/CustomComponents/CustomFormSwitch';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { ROUTES, STORAGE_KEYS } from '../../../const';
import { PREMIUM_FEATURES, ADD_ONS_DETAILS, SANOFT_WEB_URLS } from './RegisterConfig';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FreePlanImg from '../../../images/Free-plan-img.png';
import keyRegisterImg from '../../../images/key_register.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { OnlinePurchaseContext } from '../../../Context/OnlinePurchaseContext';
import { ThemeContext } from '../../../Context/ThemeContext';
import Loader from '../../utils/Loading';
import KeySignupPopup from '../../popups/KeySignupPopup';
import AddEcsTerminalMacIdPopup from '../../popups/AddEcsTerminalMacIdPopup';
import { THEME_SANOFT, REGISTRATION_TYPE, REGISTRATION_CHANNEL } from '../../../const';

function RegisterPlan() {
  const {
    purchaseData,
    setPurchaseData,
    loadCountryCode,
    loadSubscriptionsAndAddons,
    isLoading: isLoadingInPurchaseContext,
    subscriptionsAndAddons,
    countryConfig,
  } = useContext(OnlinePurchaseContext);
  const { theme } = useContext(ThemeContext);
  const [yearly, setYearly] = useState(true);
  const [showKeySignupPopup, setShowKeySignupPopup] = useState(false);
  const [addEcsTerminalMacIdPopup, setAddEcsTerminalMacIdPopup] = useState(false);
  const [macIdUpdated, setMacIdUpdated] = useState(false);

  const history = useHistory();

  const handleChangeValidity = (event) => {
    setYearly(event.target.checked);
  };

  const redireatWeb = (url) => {
    window.location.href = url;
  };

  const handlePurchaseSubscription = (subscription = null, type = null) => {
    setPurchaseData({ ...purchaseData, subscription, yearly, type });
    if (type === REGISTRATION_TYPE.TRIAL) {
      history.push(
        `${ROUTES.SIGNUP}?registration_type=${REGISTRATION_TYPE.TRIAL}&registration_channel=${REGISTRATION_CHANNEL.ONLINE}`
      );
    } else if (type === 'register_with_key') {
      history.push(
        `${ROUTES.SIGNUP}?registration_type=${REGISTRATION_TYPE.NEW}&registration_channel=${REGISTRATION_CHANNEL.KEY}`
      );
    } else {
      history.push(ROUTES.ADD_ONS);
    }
  };

  const sortedSubscriptionData = (subscriptionsAndAddons?.subscriptions || []).sort((a, b) => {
    return a?.order - b?.order;
  });

  const handleKeyRegister = (data) => {
    setShowKeySignupPopup(false);
    if ((data?.add_ons || []).find((addon) => addon?.key === 'ecs_terminal') && !macIdUpdated) {
      setAddEcsTerminalMacIdPopup(true);
      return;
    }
    handlePurchaseSubscription(null, 'register_with_key');
  };

  const handleSetMacId = (macId = null) => {
    localStorage.setItem(STORAGE_KEYS.MAC_ID, macId);
    setAddEcsTerminalMacIdPopup(false);
    setMacIdUpdated(true);
    handleKeyRegister();
  };

  const getSubscriptionStyle = (plan) => {
    return [
      styles.planItemContainer,
      plan === 'standard'
        ? styles.planContainerLeft
        : plan === 'premium'
        ? styles.planContainerHeilight
        : styles.planContainerRight,
    ].join(' ');
  };

  useEffect(() => {
    if (theme?.name !== THEME_SANOFT) return;
    loadCountryCode();
    //eslint-disable-next-line
  }, [loadCountryCode]);

  useEffect(() => {
    if (theme?.name !== THEME_SANOFT) return;
    if (countryConfig?.code) {
      loadSubscriptionsAndAddons(countryConfig?.code);
    }
    //eslint-disable-next-line
  }, [countryConfig]);

  return (
    <Grid container className={styles.container}>
      <Loader isOpen={isLoadingInPurchaseContext} />
      <Grid item xs={12} className={styles.headContainer}>
        <h1 className={styles.pageHeading}>
          The Perfect Balance <br /> of Features and Affordability
        </h1>
        <ul className={styles.list}>
          <li>
            <CheckCircleIcon style={{ paddingRight: 7 }} /> Simple and intuitive UI
          </li>
          <li>
            <CheckCircleIcon style={{ paddingRight: 7 }} /> Compliance-driven
          </li>
          <li>
            <CheckCircleIcon style={{ paddingRight: 7 }} /> Reliable online support
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} className={styles.planContainer}>
        <Grid container className={styles.planWrapper}>
          {theme?.name === THEME_SANOFT && (
            <>
              <Grid item xs={12} className={styles.filterContainer}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <span className={styles.text}>Monthly</span>
                  <CustomFormSwitch checked={yearly} onChange={handleChangeValidity} />
                  <span className={styles.text}>Yearly</span>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Grid container rowGap={3} className={styles.planListContainer}>
                  {sortedSubscriptionData.map((plan) => {
                    return (
                      <Grid item xs={4} className={getSubscriptionStyle(plan?.key)}>
                        <h2>{plan.name.toUpperCase()}</h2>
                        <p className={styles.note}>{plan?.description}</p>
                        <Grid className={styles.planPriceContainer}>
                          <span className={styles.planPriceOff}>
                            {countryConfig?.currency || '₹'}
                            {yearly ? plan?.yearly_mrp : plan?.monthly_mrp}
                          </span>
                          <span className={styles.planPriceOn}>
                            {countryConfig?.currency || '₹'}
                            {yearly ? plan?.yearly_price : plan?.monthly_price}
                          </span>
                        </Grid>
                        <span className={styles.priceNote}>{yearly ? 'Per Year' : 'Per Month'}</span>
                        <hr className={styles.line} />
                        <button
                          className={styles.btn}
                          style={{ marginTop: 30 }}
                          onClick={() =>
                            handlePurchaseSubscription(
                              yearly ? { key: plan?.key, validity: 365 } : { key: plan?.key, validity: 28 }
                            )
                          }
                        >
                          Signup now
                        </button>
                        <span className={styles.priceNote}>Register with {plan.name.toUpperCase()} plan</span>
                        <hr className={styles.line} />
                        <Grid className={styles.featuresContainer}>
                          <ul className={styles.listContainer}>
                            {(plan?.features?.addons?.free || []).map((feature) => (
                              <li className={styles.featureName}>
                                <CheckRoundedIcon className={styles.iconSmall} /> {feature}
                              </li>
                            ))}
                            {(plan?.features?.addons?.allowed || []).map((feature) => (
                              <li className={styles.featureName}>
                                <AddRoundedIcon className={styles.iconSmall} /> {feature}
                              </li>
                            ))}
                            {(plan?.features?.addons?.not_allowed || []).map((feature) => (
                              <li className={styles.featureName}>
                                <CloseRoundedIcon className={styles.iconSmall} /> {feature}
                              </li>
                            ))}
                            <Link to={ROUTES.PRICING_COMPARISON} className={styles.link}>
                              <li className={styles.featureName} style={{ fontSize: 16 }}>
                                and a lot more
                              </li>
                            </Link>
                          </ul>
                        </Grid>
                        <Grid className={styles.planFooter}>
                          {(plan?.features?.support || []).map((sup) => (
                            <li className={styles.featureName}>{sup}</li>
                          ))}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} className={styles.boxContainer}>
            <img src={keyRegisterImg} alt="img" className={styles.freeImg} />
            <Grid className={styles.trailFeatureContainer}>
              <h2 className={styles.header2}>Sign up with key</h2>
              <p>This feature is only for dealers</p>
              <Grid display="flex" flexDirection="column" alignItems="flex-start">
                <span className={styles.description}>
                  Use a special key provided to create and manage new shops. This key includes specific subscription
                  types and add-on options, enabling a streamlined registration and setup process.
                </span>
                <span className={styles.subText} style={{ marginBottom: 12 }}>
                  Verify your key. If it is valid, it will display the subscriptions and add-ons available. These
                  options are specified and not changeable.
                </span>
                <button
                  className={styles.btn}
                  onClick={() => {
                    setShowKeySignupPopup(true);
                  }}
                >
                  Sign up with key
                </button>
              </Grid>
            </Grid>
          </Grid>
          {theme?.name === THEME_SANOFT && (
            <Grid item xs={12} className={styles.boxContainer}>
              <img src={FreePlanImg} alt="free trial" className={styles.freeImg} />
              <Grid className={styles.trailFeatureContainer}>
                <h2 className={styles.header2}>Free Plan</h2>
                <p>Experience our PREMIUM plan with all features for free for 14 days.</p>
                <Grid>
                  <ul className={styles.trialFeatureList}>
                    {PREMIUM_FEATURES.map((feature) => (
                      <li className={styles.featureName}>
                        <CheckRoundedIcon className={styles.iconSmall} /> {feature}
                      </li>
                    ))}
                    <Link to={ROUTES.PRICING_COMPARISON} className={styles.link}>
                      <li className={styles.featureName} style={{ fontSize: 16 }}>
                        and a lot more
                      </li>
                    </Link>
                  </ul>
                  <button className={styles.btn} onClick={() => handlePurchaseSubscription(null, REGISTRATION_TYPE.TRIAL)}>
                    Sign up for free
                  </button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {theme?.name === THEME_SANOFT && (
        <>
          <Grid item xs={12} className={styles.addonsContainer}>
            <Grid container className={styles.addonsDetails}>
              <Grid item xs={12}>
                <h2>Our Add-On</h2>
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={2} rowSpacing={{ xs: 1, md: 3 }}>
                  {(subscriptionsAndAddons?.add_ons || [])
                    .filter((addon) => !addon?.config?.variants)
                    .map((addon) => {
                      const addonDetails = ADD_ONS_DETAILS[addon?.key];

                      if (!addonDetails) {
                        return null;
                      }

                      const IconComponent = addonDetails?.icon;

                      return (
                        <Grid item xs={6} md={4} lg={3} className={styles.addonWrapper}>
                          <Grid className={styles.addonCard}>
                            <Grid className={styles.cardWrapper}>
                              <Grid className={styles.addonHeadContainer}>
                                {IconComponent && <IconComponent className={styles.addonHeaderIcon} />}
                                <span>{addonDetails?.name}</span>
                              </Grid>
                              <span className={styles.addonPrice}>
                                {countryConfig?.currency || '₹'}
                                {yearly ? addon?.yearly_price : addon?.monthly_price}
                              </span>
                              <span className={styles.addonLimit}>{yearly ? 'Per Year' : 'Per Month'}</span>
                              <span className={styles.addonMsg}>{addonDetails?.message}</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item xs={12} className={styles.addonDeclaimer}>
                <p className={styles.addonDeclaimer}>*Some addons are not supported in the STANDARD plan.</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.contactBG}>
            <Grid container rowSpacing={2} className={styles.contactWrapper}>
              <Grid item xs={12} md={6} className={styles.contactInfo}>
                <span>Contact Us</span>
                <p>If you have any questions or need any assistance after business hours please get in touch</p>
                <Grid container spacing={1} marginTop={4} marginBottom={3}>
                  <Grid item xs={4}>
                    <h5>EMAIL</h5>
                  </Grid>
                  <Grid item xs={8}>
                    <h5>support@sanoft.com</h5>
                  </Grid>
                  <Grid item xs={4}>
                    <h5>PHONE</h5>
                  </Grid>
                  <Grid item xs={8}>
                    <h5>+919567453182 | +919746693296</h5>
                  </Grid>
                </Grid>
                <h5>OFFICE ADDRESS:</h5>
                <h6>2nd floor, KKM City Centre, Areacode - Vazhakkad Rd, Jn, Areekode, Kerala 673639</h6>
                <Grid className={styles.socialMediaWrapper}>
                  <FacebookIcon className={styles.icon} /> <InstagramIcon className={styles.icon} />{' '}
                  <YouTubeIcon className={styles.icon} /> <LinkedInIcon className={styles.icon} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={styles.sentMessage}>
                <form action="https://formspree.io/f/xbjwzzey" method="POST">
                  <label for="contact-us-name">YOUR NAME</label>
                  <br />
                  <input type="text" id="contact-us-name" name="Name" placeholder="Enter your name" />
                  <br />
                  <label for="contact-us-email">YOUR EMAIL</label>
                  <br />
                  <input type="text" id="contact-us-email" name="Email" placeholder="Enter your email address" />
                  <br />
                  <label for="contact-us-message">YOUR MESSAGE</label>
                  <br />
                  <input type="text" id="contact-us-message" name="Message" placeholder="Enter your message here..." />
                  <br />
                  <button type="submit" class="contact-us-submit-button" name="button-sent-message">
                    SEND MESSAGE
                  </button>
                </form>
              </Grid>
              <Grid item xs={12} className={styles.footer}>
                <span>© 2024 Sanoft Technologies. All Rights Reserved</span>
                <Grid display="flex">
                  <p onClick={() => redireatWeb(SANOFT_WEB_URLS.PRIVECY_POLICY)}>Privecy Policy</p>
                  <p onClick={() => redireatWeb(SANOFT_WEB_URLS.TERMS)}>Terms & Condetions</p>
                  <p onClick={() => redireatWeb(SANOFT_WEB_URLS.REFUND_POLICY)}>Refund Policy</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {showKeySignupPopup && (
        <KeySignupPopup handleClose={() => setShowKeySignupPopup(false)} handleKeyRegister={handleKeyRegister} />
      )}
      {addEcsTerminalMacIdPopup && (
        <AddEcsTerminalMacIdPopup
          handleClose={() => {
            setAddEcsTerminalMacIdPopup(false);
          }}
          purchaseData={purchaseData}
          handleSetMacId={handleSetMacId}
        />
      )}
    </Grid>
  );
}

export default RegisterPlan;
