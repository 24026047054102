import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import Info from '../utils/Alert/Info';
import React, { useContext } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import CsvHelper from '../../helpers/CsvHelper';
import { saveAs } from 'file-saver';
import { AlertMessagesContext } from 'react-alert-messages';

export default function ListDialog(props) {
  const { toggleItemsTab, rowData, tableHeader, title, emptyDataContent } =
    props;
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const CSV_COLUMNS = {
    type: 'Type',
    date: {
      title: 'Date',
      getValue: (item) => {
        return item.time.split(' ')[1];
      },
    },
    time: {
      title: 'Time',
      getValue: (item) => {
        return item.time.split(' ')[0];
      },
    },
    description: 'Details',
    inbound: 'Inbound',
    outbound: 'Outbound',
    balance: 'Balance',
  };

  const getUpdatedData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        inbound: item.quantity > 0 ? item.quantity : null,
        outbound: item.quantity < 0 ? Math.abs(item.quantity) : null,
      };
    });
  };

  const toCsvBtnPressed = async () => {
    if (!rowData.length) return;
    const csv = CsvHelper.getString(getUpdatedData(rowData), CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `items-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>{title}</h2>
          <div>
            <GetAppIcon
              className={styles.icon}
              style={{ marginRight: 15 }}
              onClick={toCsvBtnPressed}
            />
            <CloseIcon
              onClick={() => {
                toggleItemsTab('');
              }}
            />
          </div>
        </div>
        <div className={styles.tableContent}>
          {rowData.length && rowData ? (
            <DataTable columns={tableHeader} rows={rowData} />
          ) : (
            <Info title={title} content={emptyDataContent} />
          )}
        </div>
      </div>
    </div>
  );
}
