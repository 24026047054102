import { Grid } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { ADD_ONS_DETAILS } from '../pages/Register/RegisterConfig';

export default function NewCustomerDialog(props) {
  const { handleClose, countryConfig, addonData, purchaseAddonHandler, purchaseData } = props;
  const addonDetails = ADD_ONS_DETAILS[addonData?.key];

  const handleSelectSalesPack = (addon) => {
    purchaseAddonHandler(addon);
    handleClose();
  };

  const getAddonContainerSelectStyle = (pack) => {
    const addon = purchaseData.add_ons.find((adn) => adn?.key === addonDetails.key && adn?.count === pack.count);
    return addon ? styles.selectedPackContainer : '';
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper} style={{ padding: 10 }}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Select a {addonDetails.name.toLowerCase()} pack</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <p className={styles.subText}>{addonDetails.note}</p>
            </Grid>
            {Object.values(addonData?.config?.variants || {}).map((pack) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={styles.packWrapper}
                onClick={() => {
                  handleSelectSalesPack([{ key: addonDetails.key, count: pack.count }]);
                }}
              >
                <Grid className={`${styles.packCotainer} ${getAddonContainerSelectStyle(pack)}`}>
                  <img src={addonDetails?.image} alt="" />
                  <Grid className={styles.headContainer}>
                    <h3>{pack?.count}</h3> <span className={styles.subText}>sales</span>
                  </Grid>
                  <p>
                    {pack?.count} {addonDetails.name.toLowerCase()} add to your shop.
                  </p>
                  <h3>
                    {countryConfig?.currency || '₹'}
                    {pack?.price}
                  </h3>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
