import React, { createContext, useState } from 'react';
import AuthService from '../services/AuthService';
import SubscriptionService from '../services/SubscriptionService';
import { DEFAULT_COUNTRY_CODE, COUNTRY_CONFIGRATION } from '../const';

export const OnlinePurchaseContext = createContext();

export default function OnlinePurchaseProvider(props) {
  const [isLoading, setLoading] = useState(false);
  const [countryConfig, setCountryConfig] = useState({});
  const [subscriptionsAndAddons, setSubscriptionsAndAddons] = useState(null);
  const [purchaseData, setPurchaseData] = useState({
    subscription: null,
    add_ons: [],
    amount: 0,
  });

  const loadCountryCode = async () => {
    if (Object.keys(countryConfig).length !== 0) return countryConfig;
    setLoading(true);
    let countryCode;
    try {
      const response = await AuthService.findCountryCode();
      countryCode = response?.country_code || DEFAULT_COUNTRY_CODE;
    } catch (error) {
      countryCode = DEFAULT_COUNTRY_CODE;
    }
    let countryData;
    if (COUNTRY_CONFIGRATION.hasOwnProperty(countryCode)) {
      countryData = COUNTRY_CONFIGRATION[countryCode];
    } else {
      countryData = COUNTRY_CONFIGRATION[DEFAULT_COUNTRY_CODE];
    }
    setCountryConfig(countryData);
    setLoading(false);
    return countryData;
  };

  const loadSubscriptionsAndAddons = async () => {
    if (subscriptionsAndAddons) return;
    setLoading(true);
    const resp = await SubscriptionService.getSubscriptionsAndAdons(countryConfig?.code);
    setSubscriptionsAndAddons(resp);
    setPurchaseData({ ...purchaseData, country: countryConfig?.code ?? null });
    setLoading(false);
  };

  return (
    <OnlinePurchaseContext.Provider
      value={{
        isLoading,
        countryConfig,
        purchaseData,
        subscriptionsAndAddons,
        setPurchaseData,
        loadCountryCode,
        loadSubscriptionsAndAddons,
      }}
    >
      {props.children}
    </OnlinePurchaseContext.Provider>
  );
}
