import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import styles from './styles.module.css';
import Info from '../utils/Alert/Info';
import { Grid } from '@material-ui/core';

function CustomerSaleDialog(props) {
  const { togglePurchaseTab, rowData } = props;
  const items = rowData?.items || [];
  const columnHeader = [
    {
      id: 'plu',
      label: 'Plu',
      type: 'text',
    },
    {
      id: 'item.name',
      label: 'Name',
      type: 'text',
    },
    {
      id: 'quantity',
      label: 'Quantity',
      type: 'floatQuantity',
    },
    {
      id: 'rate',
      label: 'Price',
      type: 'floatAmount',
    },
    {
      id: 'tax',
      label: 'Tax',
      type: 'text',
    },
    {
      id: 'total',
      lable: 'Total',
      type: 'floatAmount'
    }
  ];

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Sale Details</h2>
          <CloseIcon
            onClick={() => {
              togglePurchaseTab(null);
            }}
          />
        </div>
        <div className={styles.changeable} style={{ margin: '10px' }}>
          <Grid container style={{ marginTop: '5px' }} spacing={1}>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Bill Date</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.bill_date}</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Invoice Number</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.invoice_no || 0}</h5>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '5px' }} spacing={1}>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Invoice Date</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.date}</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textHead}>Total</h5>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h5 className={styles.textValues}>{rowData?.total_amount || 0}</h5>
            </Grid>
          </Grid>
        </div>
        <div className={styles.tableContent1}>
          {items && items.length ? (
            <DataTable columns={columnHeader} rows={rowData?.items} />
          ) : (
            <Info
              title={'Sale Details not found'}
              content={'This Sale have no items to list up'}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerSaleDialog;
