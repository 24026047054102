import BackendService from './BackendService';

async function getTerminal(id) {
  return BackendService.getData(`/terminals/${id}/`);
}
async function getTerminals() {
  return BackendService.getData('/terminals/');
}
async function addTerminals(data = {}) {
  return BackendService.postData('/terminals/', data);
}
async function updateTerminals(id, data = {}) {
  return BackendService.patch(`/terminals/${id}/`, data);
}
async function disconnectTerminal(id) {
  return BackendService.destroy(`/terminals/${id}/disconnect/`);
}

const TerminalService = {
  getTerminal,
  getTerminals,
  addTerminals,
  updateTerminals,
  disconnectTerminal,
};

export default TerminalService;
