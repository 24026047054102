import BackendService from './BackendService';

async function getExpenses(params = {}) {
  return BackendService.getData('/expenses/', {}, params);
}
async function createExpense(data) {
  return BackendService.postData('/expenses/', data);
}
async function updateExpense(id, data = {}) {
  return BackendService.put(`/expenses/${id}/`, data);
}
async function deleteExpense(id) {
  return BackendService.destroy(`/expenses/${id}/`, '');
}

const ExpenseService = {
  getExpenses,
  createExpense,
  updateExpense,
  deleteExpense,
};

export default ExpenseService;
