import React from 'react';
import { Stepper, StepLabel, Step } from '@mui/material';
import { styled } from '@mui/system';

const CustomStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontSize: '0px',
  },
  '& .MuiStepConnector-line': {
    borderTop: `1px solid #0160c9`,
    margin: '8px auto',
    width: '80%',
  },
  '& .MuiStepIcon-root': {
    fontSize: '2.5rem',
  },
}));

function CustomSteppers({ steps, activeStep }) {
  return (
    <CustomStepper activeStep={activeStep} alternativeLabel>
      {steps.map((_, index) => (
        <Step key={index}>
          <StepLabel>{''}</StepLabel>
        </Step>
      ))}
    </CustomStepper>
  );
}

export default CustomSteppers;
