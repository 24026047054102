import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
export default function Success(props) {
  const { title } = props;
  return (
    <Alert severity="success">
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
    </Alert>
  );
}
