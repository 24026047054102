import React, { useContext } from 'react';
import NavBar from '../NavBar';
import TitleBar from '../TitleBar';
import styles from './styles.module.css';
import Loader from '../Loading';
import { STORAGE_KEYS } from '../../../const';
import { selectedTabContext } from '../../../Context/SelectedTabContext';
import { ThemeContext } from '../../../Context/ThemeContext';

function withConsoleBase(ConsoleComponent) {
  const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
  return () => {
    const { isMenuOpen } = useContext(selectedTabContext);
    const { theme } = useContext(ThemeContext);
    return (
      <>
        {shopId !== '-1' ? (
          <>
            <TitleBar />
            <div className={styles.ContentsWrapper}>
              <div
                style={{ backgroundColor: theme.color.primary }}
                className={
                  isMenuOpen ? styles.NavbarWrapperOpen : styles.NavbarWrapper
                }
              >
                <NavBar />
              </div>
              <div className={styles.ContentsArea}>
                <div className={styles.ContentsView}>
                  <ConsoleComponent />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader isOpen={true} />
        )}
      </>
    );
  };
}

export default withConsoleBase;
