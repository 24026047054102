import { Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from './styles.module.css';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../const';

function PaymentCallback() {
  const history = useHistory();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const paymentStatus = params.get('success');
  const used = params.get('used');
  useEffect(() => {
    setTimeout(() => {
      history.push(ROUTES.SETTINGS);
    }, 5500);
    // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.container}>
      {paymentStatus === 'True' ? (
        <Paper elevation={3} className={styles.paymentCard}>
          <div className={styles.centerText}>
            <CheckCircleOutlineIcon
              style={{ fontSize: '120px', color: 'green' }}
            />
          </div>
          <div>
            <h1 className={styles.centerText}>Payment completed</h1>
            <p className={styles.centerText}>
              Your payment has been successful,{' '}
              {used === 'True'
                ? 'subscription is applied to your shop'
                : 'subscription will be applied in future'}
            </p>
          </div>
        </Paper>
      ) : (
        <Paper elevation={3} className={styles.paymentCard}>
          <div className={styles.centerText}>
            <HighlightOffIcon style={{ fontSize: '120px', color: 'red' }} />
          </div>
          <div>
            <h1 className={styles.centerText}>Payment failed</h1>
            <p className={styles.centerText}>
              Your payment has been failed, please check is there any problem
              with your payment method.
            </p>
          </div>
        </Paper>
      )}
    </div>
  );
}

export default PaymentCallback;
