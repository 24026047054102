import BackendService from './BackendService';
import CacheHelper from '../helpers/CacheHelper';

const cache = {};

async function getShops() {
  const cachedShops = CacheHelper.get(cache, 'shops');
  if (cachedShops) {
    return cachedShops;
  }

  const shops = await BackendService.getData('/shops/');
  CacheHelper.store(cache, 'shops', shops);
  return shops;
}

const ShopService = {
  getShops,
};

export default ShopService;
