import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { STORAGE_KEYS } from '../../const';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const paramsToken = queryParams.get('token');
  if (paramsToken) {
    localStorage.setItem(STORAGE_KEYS.TOKEN, paramsToken);
  }
  const token = paramsToken || localStorage.getItem(STORAGE_KEYS.TOKEN);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        token ? <RouteComponent {...routeProps} /> : <Redirect to={'/login'} />
      }
    />
  );
};

export default PrivateRoute;
