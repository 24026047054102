import React from 'react';
import styles from './styles.module.css';
import { DialogActions, Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function DateLimitDialog({ handleClose }) {
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper} style={{ maxWidth: 600 }}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Date Range Limit Exceeded</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow}>
            <Grid item xs={12}>
              <span>
                Reports can only be generated for up to 3 months. Please select
                a range within 3 months.
              </span>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ backgroundColor: '#00a65a' }}
              onClick={handleClose}
            >
              Ok
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}

export default DateLimitDialog;
