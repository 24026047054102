import BackendService from './BackendService';

async function getSalesByDate(data = {}) {
  return BackendService.postData('/reports/by_date/', data);
}

async function getSalesSummary(params = {}) {
  return BackendService.getData('/reports/summary/', {}, params);
}
async function getSaleOrderTypeReport(data = {}) {
  return BackendService.postData('/reports/order-type-summary/', data);
}

const SalesReportsService = {
  getSalesByDate,
  getSalesSummary,
  getSaleOrderTypeReport,
};

export default SalesReportsService;
