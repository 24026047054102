import React, { useContext, useEffect, useState, useRef } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions, Button, Grid, Divider } from '@mui/material';
import { ShopContext } from '../../Context/ShopContext';
import { CardMedia } from '@material-ui/core';
import TerminalService from '../../services/TerminalServices';
import BillFormatService from '../../services/BillFormatService';
import { QRCodeCanvas } from 'qrcode.react';
import Loader from '../utils/Loading';
import html2canvas from 'html2canvas';

function GenerateCustomerQrDialog({ onClose, customer }) {
  const { shop } = useContext(ShopContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [invoiceFormat, setInvoiceFormat] = useState({});
  const qrContainerRef = useRef();

  const getShopAddress = async () => {
    setLoadingIndicator(true);
    const terminals = await TerminalService.getTerminals();

    if (terminals.length > 0) {
      const firstTerminalId = terminals[0].id;

      const invoiceFormat = await BillFormatService.getBillFormat(firstTerminalId);
      setInvoiceFormat(invoiceFormat);
    }
    setLoadingIndicator(false);
  };

  const downloadAsImage = async () => {
    const qrContainer = qrContainerRef.current;

    const canvas = await html2canvas(qrContainer, { allowTaint: true, useCORS: true, logging: true });

    const imgData = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = imgData;
    link.download = `${customer.name}_QR_Code.png`;
    link.click();
  };

  useEffect(() => {
    getShopAddress();
  }, []);

  return (
    <>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.popUp}>
        <div className={styles.contentWrapper}>
          <div className={styles.headSec}>
            <h2 className={styles.editTitle}>Customer QR Code</h2>
            <CloseIcon onClick={onClose} />
          </div>
          <div className={styles.inputSec}>
            <Grid container display={'flex'} justifyContent={'center'} pb={2}>
              <Grid item container xs={12} rowSpacing={3} className={styles.qrContainer} ref={qrContainerRef}>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  {shop?.shop_logo ? (
                    <CardMedia image={shop?.shop_logo} className={styles.logo} />
                  ) : (
                    <span className={styles.logoHead}>{shop.shop_name}</span>
                  )}
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <span className={styles.textSub}>
                    {invoiceFormat.address_1 ? `${invoiceFormat.address_1}, ` : ''}{' '}
                    {invoiceFormat.address_2 ? `${invoiceFormat.address_2}, ` : ''}
                    {invoiceFormat.address_3 ? `${invoiceFormat.address_3}, ` : ''}{' '}
                    {invoiceFormat.address_4 ? `${invoiceFormat.address_4}, ` : ''}{' '}
                    {invoiceFormat.tel_no ? `${invoiceFormat.tel_no} ` : ''}
                  </span>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  {
                    <QRCodeCanvas
                      value={JSON.stringify({ id: customer.id, name: customer.name, mobile: customer.mobile })}
                      size={230}
                      level="H"
                    />
                  }
                </Grid>
                <Grid item xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <span className={styles.textSub}>Customer: {customer.name}</span>
                  {customer.trn_number && <span className={styles.textSub}>GSTIN: {customer.trn_number}</span>}
                  <span className={styles.textSub}>{customer.location}</span>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <Divider
                    sx={{
                      height: 1,
                      backgroundColor: '#cdcdcd',
                      width: '100%',
                    }}
                  />
                </Grid>
                <Grid item xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'} pb={2}>
                  <span className={styles.textSub}>Powered by SANOFT</span>
                  <span className={styles.textSub}>www.sanoft.com</span>
                </Grid>
              </Grid>
            </Grid>
            <DialogActions>
              <Button variant="contained" color="secondary" size="small" onClick={onClose} className={styles.closeBtn}>
                close
              </Button>
              <Button variant="contained" size="small" color="primary" onClick={downloadAsImage}>
                Download QR
              </Button>
            </DialogActions>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenerateCustomerQrDialog;
