import React, { useContext } from 'react';
import { ThemeContext } from '../../Context/ThemeContext';

function RedirectToWhatsapp({message}) {
  const { theme } = useContext(ThemeContext);

  const msg = `Need help on ${theme?.variable?.name} Android POS. ${message}`;

  const redirectWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?phone=${theme?.variable?.phone}&text=${encodeURIComponent(msg)}`;
    window.open(url, '_blank');
  };
  return (
    <span style={{ color: '#343f98', cursor: 'pointer' }} onClick={redirectWhatsApp}>
      Contact support team.
    </span>
  );
}

export default RedirectToWhatsapp;
